/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 HERMES SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.hermes.tparrows {
	cursor:pointer;
	background:#000;
	background:rgba(0,0,0,0.5);
	width:30px;
	height:110px;
	position:absolute;
	display:block;
	z-index:100;
}

.hermes.tparrows:before {
	font-family: "revicons";
	font-size:15px;
	color:#fff;
	display:block;
	line-height: 110px;
	text-align: center;
    transform:translateX(0px);
    -webkit-transform:translateX(0px);
    transition:all 0.3s;
    -webkit-transition:all 0.3s;
}
.hermes.tparrows.tp-leftarrow:before {
	content: "\e824";
}
.hermes.tparrows.tp-rightarrow:before {
	content: "\e825";
}
.hermes.tparrows.tp-leftarrow:hover:before {
    transform:translateX(-20px);
    -webkit-transform:translateX(-20px);
     opacity:0;
}
.hermes.tparrows.tp-rightarrow:hover:before {
    transform:translateX(20px);
    -webkit-transform:translateX(20px);
     opacity:0;
}

.hermes .tp-arr-allwrapper {
    overflow:hidden;
    position:absolute;
	width:180px;
    height:140px;
    top:0px;
    left:0px;
    visibility:hidden;
      -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  -webkit-perspective: 1000px;
  perspective: 1000px;
    }
.hermes.tp-rightarrow .tp-arr-allwrapper {
   right:0px;left:auto;
      }
.hermes.tparrows:hover .tp-arr-allwrapper {
   visibility:visible;
          }
.hermes .tp-arr-imgholder {
  width:180px;position:absolute;
  left:0px;top:0px;height:110px;
  transform:translateX(-180px);
  -webkit-transform:translateX(-180px);
  transition:all 0.3s;
  transition-delay:0.3s;
}
.hermes.tp-rightarrow .tp-arr-imgholder{
    transform:translateX(180px);
  -webkit-transform:translateX(180px);
      }
  
.hermes.tparrows:hover .tp-arr-imgholder {
   transform:translateX(0px);
   -webkit-transform:translateX(0px);            
}
.hermes .tp-arr-titleholder {
  top:110px;
  width:180px;
  text-align:left; 
  display:block;
  padding:0px 10px;
  line-height:30px; background:#000;
  background:rgba(0,0,0,0.75);color:#fff;
  font-weight:600; position:absolute;
  font-size:12px;
  white-space:nowrap;
  letter-spacing:1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  box-sizing:border-box;

}
.hermes.tparrows:hover .tp-arr-titleholder {
    -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}


/* BULLETS */
.hermes.tp-bullets {
}

.hermes .tp-bullet {
    overflow:hidden;
    border-radius:50%;
    width:16px;
    height:16px;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 2px #FFF;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    position:absolute;
}

.hermes .tp-bullet:hover {
	  background-color: rgba(0, 0, 0, 0.2);
}
.hermes .tp-bullet:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 0 1px #FFF;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.hermes .tp-bullet.selected:after {
  height:100%;
}


/* TABS */
.hermes .tp-tab { 
  opacity:1;  
  padding-right:10px;
  box-sizing:border-box;
 }
.hermes .tp-tab-image 
{ 
  width:100%;
  height:60%;
  position:relative;
}
.hermes .tp-tab-content 
{
    background:rgb(54,54,54); 
    position:absolute;
    padding:20px 20px 20px 30px;
    box-sizing:border-box;
    color:#fff;
  display:block;
  width:100%;
  min-height:40%;
  bottom:0px;
  left:-10px;
  }
.hermes .tp-tab-date
  {
  display:block;
  color:#888;
  font-weight:600;
  font-size:12px;
  margin-bottom:10px;
  }
.hermes .tp-tab-title 
{
    display:block;	
    color:#fff;
    font-size:16px;
    font-weight:800;
    text-transform:uppercase;
   line-height:19px;
}

.hermes .tp-tab.selected .tp-tab-title:after {
    width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 30px 0 30px 10px;
	border-color: transparent transparent transparent rgb(54,54,54);
	content:" ";
    position:absolute;
    right:-9px;
    bottom:50%;
    margin-bottom:-30px;
}
.hermes .tp-tab-mask {
     padding-right:10px !important;
          }

/* MEDIA QUERIES */
@media only screen and (max-width: 960px) {
  .hermes .tp-tab .tp-tab-title {font-size:14px;line-height:16px;}
  .hermes .tp-tab-date { font-size:11px; line-height:13px;margin-bottom:10px;}
  .hermes .tp-tab-content { padding:15px 15px 15px 25px;}
}
@media only screen and (max-width: 768px) {
  .hermes .tp-tab .tp-tab-title {font-size:12px;line-height:14px;}
  .hermes .tp-tab-date {font-size:10px; line-height:12px;margin-bottom:5px;}
  .hermes .tp-tab-content {padding:10px 10px 10px 20px;}
}

