@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?30757853');
  src: url('../fonts/fontello.eot?30757853#iefix') format('embedded-opentype'),
    url('../fonts/fontello.woff2?30757853') format('woff2'),
    url('../fonts/fontello.woff?30757853') format('woff'),
    url('../fonts/fontello.ttf?30757853') format('truetype'),
    url('../fonts/fontello.svg?30757853#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?30757853#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-up-open:before {
  content: '\e800';
}

/* '' */
.icon-music:before {
  content: '\e801';
}

/* '' */
.icon-search:before {
  content: '\e802';
}

/* '' */
.icon-mail:before {
  content: '\e803';
}

/* '' */
.icon-heart:before {
  content: '\e804';
}

/* '' */
.icon-heart-empty:before {
  content: '\e805';
}

/* '' */
.icon-star:before {
  content: '\e806';
}

/* '' */
.icon-star-empty:before {
  content: '\e807';
}

/* '' */
.icon-star-half:before {
  content: '\e808';
}

/* '' */
.icon-user:before {
  content: '\e809';
}

/* '' */
.icon-users:before {
  content: '\e80a';
}

/* '' */
.icon-video:before {
  content: '\e80b';
}

/* '' */
.icon-videocam:before {
  content: '\e80c';
}

/* '' */
.icon-picture:before {
  content: '\e80d';
}

/* '' */
.icon-camera:before {
  content: '\e80e';
}

/* '' */
.icon-camera-alt:before {
  content: '\e80f';
}

/* '' */
.icon-th-large:before {
  content: '\e810';
}

/* '' */
.icon-th:before {
  content: '\e811';
}

/* '' */
.icon-th-list:before {
  content: '\e812';
}

/* '' */
.icon-ok:before {
  content: '\e813';
}

/* '' */
.icon-ok-circled:before {
  content: '\e814';
}

/* '' */
.icon-ok-circled2:before {
  content: '\e815';
}

/* '' */
.icon-cancel:before {
  content: '\e816';
}

/* '' */
.icon-cancel-circled:before {
  content: '\e817';
}

/* '' */
.icon-cancel-circled2:before {
  content: '\e818';
}

/* '' */
.icon-plus:before {
  content: '\e819';
}

/* '' */
.icon-plus-circled:before {
  content: '\e81a';
}

/* '' */
.icon-minus:before {
  content: '\e81b';
}

/* '' */
.icon-minus-circled:before {
  content: '\e81c';
}

/* '' */
.icon-help-circled:before {
  content: '\e81d';
}

/* '' */
.icon-info-circled:before {
  content: '\e81e';
}

/* '' */
.icon-home:before {
  content: '\e81f';
}

/* '' */
.icon-link:before {
  content: '\e820';
}

/* '' */
.icon-attach:before {
  content: '\e821';
}

/* '' */
.icon-lock:before {
  content: '\e822';
}

/* '' */
.icon-lock-open:before {
  content: '\e823';
}

/* '' */
.icon-pin:before {
  content: '\e824';
}

/* '' */
.icon-eye:before {
  content: '\e825';
}

/* '' */
.icon-eye-off:before {
  content: '\e826';
}

/* '' */
.icon-tag:before {
  content: '\e827';
}

/* '' */
.icon-tags:before {
  content: '\e828';
}

/* '' */
.icon-bookmark:before {
  content: '\e829';
}

/* '' */
.icon-flag:before {
  content: '\e82a';
}

/* '' */
.icon-thumbs-up:before {
  content: '\e82b';
}

/* '' */
.icon-thumbs-down:before {
  content: '\e82c';
}

/* '' */
.icon-download:before {
  content: '\e82d';
}

/* '' */
.icon-upload:before {
  content: '\e82e';
}

/* '' */
.icon-forward:before {
  content: '\e82f';
}

/* '' */
.icon-export:before {
  content: '\e830';
}

/* '' */
.icon-pencil:before {
  content: '\e831';
}

/* '' */
.icon-edit:before {
  content: '\e832';
}

/* '' */
.icon-print:before {
  content: '\e833';
}

/* '' */
.icon-retweet:before {
  content: '\e834';
}

/* '' */
.icon-comment:before {
  content: '\e835';
}

/* '' */
.icon-chat:before {
  content: '\e836';
}

/* '' */
.icon-bell:before {
  content: '\e837';
}

/* '' */
.icon-attention:before {
  content: '\e838';
}

/* '' */
.icon-attention-circled:before {
  content: '\e839';
}

/* '' */
.icon-location:before {
  content: '\e83a';
}

/* '' */
.icon-trash-empty:before {
  content: '\e83b';
}

/* '' */
.icon-doc:before {
  content: '\e83c';
}

/* '' */
.icon-folder:before {
  content: '\e83d';
}

/* '' */
.icon-folder-open:before {
  content: '\e83e';
}

/* '' */
.icon-phone:before {
  content: '\e83f';
}

/* '' */
.icon-cog:before {
  content: '\e840';
}

/* '' */
.icon-cog-alt:before {
  content: '\e841';
}

/* '' */
.icon-wrench:before {
  content: '\e842';
}

/* '' */
.icon-basket:before {
  content: '\e843';
}

/* '' */
.icon-calendar:before {
  content: '\e844';
}

/* '' */
.icon-login:before {
  content: '\e845';
}

/* '' */
.icon-logout:before {
  content: '\e846';
}

/* '' */
.icon-volume-off:before {
  content: '\e847';
}

/* '' */
.icon-volume-down:before {
  content: '\e848';
}

/* '' */
.icon-volume-up:before {
  content: '\e849';
}

/* '' */
.icon-headphones:before {
  content: '\e84a';
}

/* '' */
.icon-clock:before {
  content: '\e84b';
}

/* '' */
.icon-block:before {
  content: '\e84c';
}

/* '' */
.icon-resize-full:before {
  content: '\e84d';
}

/* '' */
.icon-resize-small:before {
  content: '\e84e';
}

/* '' */
.icon-resize-vertical:before {
  content: '\e84f';
}

/* '' */
.icon-resize-horizontal:before {
  content: '\e850';
}

/* '' */
.icon-zoom-in:before {
  content: '\e851';
}

/* '' */
.icon-zoom-out:before {
  content: '\e852';
}

/* '' */
.icon-down-circled2:before {
  content: '\e853';
}

/* '' */
.icon-up-circled2:before {
  content: '\e854';
}

/* '' */
.icon-down-dir:before {
  content: '\e855';
}

/* '' */
.icon-up-dir:before {
  content: '\e856';
}

/* '' */
.icon-left-dir:before {
  content: '\e857';
}

/* '' */
.icon-right-dir:before {
  content: '\e858';
}

/* '' */
.icon-down-open:before {
  content: '\e859';
}

/* '' */
.icon-left-open:before {
  content: '\e85a';
}

/* '' */
.icon-down-big:before {
  content: '\e85b';
}

/* '' */
.icon-left-big:before {
  content: '\e85c';
}

/* '' */
.icon-right-big:before {
  content: '\e85d';
}

/* '' */
.icon-up-big:before {
  content: '\e85e';
}

/* '' */
.icon-right-hand:before {
  content: '\e85f';
}

/* '' */
.icon-left-hand:before {
  content: '\e860';
}

/* '' */
.icon-up-hand:before {
  content: '\e861';
}

/* '' */
.icon-down-hand:before {
  content: '\e862';
}

/* '' */
.icon-cw:before {
  content: '\e863';
}

/* '' */
.icon-ccw:before {
  content: '\e864';
}

/* '' */
.icon-arrows-cw:before {
  content: '\e865';
}

/* '' */
.icon-shuffle:before {
  content: '\e866';
}

/* '' */
.icon-play:before {
  content: '\e867';
}

/* '' */
.icon-play-circled2:before {
  content: '\e868';
}

/* '' */
.icon-stop:before {
  content: '\e869';
}

/* '' */
.icon-pause:before {
  content: '\e86a';
}

/* '' */
.icon-to-end:before {
  content: '\e86b';
}

/* '' */
.icon-to-end-alt:before {
  content: '\e86c';
}

/* '' */
.icon-to-start:before {
  content: '\e86d';
}

/* '' */
.icon-to-start-alt:before {
  content: '\e86e';
}

/* '' */
.icon-fast-fw:before {
  content: '\e86f';
}

/* '' */
.icon-fast-bw:before {
  content: '\e870';
}

/* '' */
.icon-eject:before {
  content: '\e871';
}

/* '' */
.icon-target:before {
  content: '\e872';
}

/* '' */
.icon-signal:before {
  content: '\e873';
}

/* '' */
.icon-award:before {
  content: '\e874';
}

/* '' */
.icon-inbox:before {
  content: '\e875';
}

/* '' */
.icon-globe:before {
  content: '\e876';
}

/* '' */
.icon-cloud:before {
  content: '\e877';
}

/* '' */
.icon-flash:before {
  content: '\e878';
}

/* '' */
.icon-umbrella:before {
  content: '\e879';
}

/* '' */
.icon-flight:before {
  content: '\e87a';
}

/* '' */
.icon-leaf:before {
  content: '\e87b';
}

/* '' */
.icon-font:before {
  content: '\e87c';
}

/* '' */
.icon-bold:before {
  content: '\e87d';
}

/* '' */
.icon-italic:before {
  content: '\e87e';
}

/* '' */
.icon-text-height:before {
  content: '\e87f';
}

/* '' */
.icon-text-width:before {
  content: '\e880';
}

/* '' */
.icon-align-left:before {
  content: '\e881';
}

/* '' */
.icon-align-center:before {
  content: '\e882';
}

/* '' */
.icon-align-right:before {
  content: '\e883';
}

/* '' */
.icon-align-justify:before {
  content: '\e884';
}

/* '' */
.icon-list:before {
  content: '\e885';
}

/* '' */
.icon-indent-left:before {
  content: '\e886';
}

/* '' */
.icon-indent-right:before {
  content: '\e887';
}

/* '' */
.icon-scissors:before {
  content: '\e888';
}

/* '' */
.icon-briefcase:before {
  content: '\e889';
}

/* '' */
.icon-off:before {
  content: '\e88a';
}

/* '' */
.icon-road:before {
  content: '\e88b';
}

/* '' */
.icon-list-alt:before {
  content: '\e88c';
}

/* '' */
.icon-qrcode:before {
  content: '\e88d';
}

/* '' */
.icon-barcode:before {
  content: '\e88e';
}

/* '' */
.icon-book:before {
  content: '\e88f';
}

/* '' */
.icon-adjust:before {
  content: '\e890';
}

/* '' */
.icon-tint:before {
  content: '\e891';
}

/* '' */
.icon-check:before {
  content: '\e892';
}

/* '' */
.icon-asterisk:before {
  content: '\e893';
}

/* '' */
.icon-gift:before {
  content: '\e894';
}

/* '' */
.icon-fire:before {
  content: '\e895';
}

/* '' */
.icon-magnet:before {
  content: '\e896';
}

/* '' */
.icon-chart-bar:before {
  content: '\e897';
}

/* '' */
.icon-credit-card:before {
  content: '\e898';
}

/* '' */
.icon-floppy:before {
  content: '\e899';
}

/* '' */
.icon-megaphone:before {
  content: '\e89a';
}

/* '' */
.icon-key:before {
  content: '\e89b';
}

/* '' */
.icon-truck:before {
  content: '\e89c';
}

/* '' */
.icon-hammer:before {
  content: '\e89d';
}

/* '' */
.icon-lemon:before {
  content: '\e89e';
}

/* '' */
.icon-note:before {
  content: '\e89f';
}

/* '' */
.icon-note-beamed:before {
  content: '\e8a0';
}

/* '' */
.icon-music-1:before {
  content: '\e8a1';
}

/* '' */
.icon-search-1:before {
  content: '\e8a2';
}

/* '' */
.icon-flashlight:before {
  content: '\e8a3';
}

/* '' */
.icon-mail-1:before {
  content: '\e8a4';
}

/* '' */
.icon-heart-1:before {
  content: '\e8a5';
}

/* '' */
.icon-heart-empty-1:before {
  content: '\e8a6';
}

/* '' */
.icon-star-1:before {
  content: '\e8a7';
}

/* '' */
.icon-star-empty-1:before {
  content: '\e8a8';
}

/* '' */
.icon-user-1:before {
  content: '\e8a9';
}

/* '' */
.icon-users-1:before {
  content: '\e8aa';
}

/* '' */
.icon-user-add:before {
  content: '\e8ab';
}

/* '' */
.icon-video-1:before {
  content: '\e8ac';
}

/* '' */
.icon-picture-1:before {
  content: '\e8ad';
}

/* '' */
.icon-camera-1:before {
  content: '\e8ae';
}

/* '' */
.icon-layout:before {
  content: '\e8af';
}

/* '' */
.icon-menu-1:before {
  content: '\e8b0';
}

/* '' */
.icon-check-1:before {
  content: '\e8b1';
}

/* '' */
.icon-cancel-1:before {
  content: '\e8b2';
}

/* '' */
.icon-cancel-circled-1:before {
  content: '\e8b3';
}

/* '' */
.icon-cancel-squared:before {
  content: '\e8b4';
}

/* '' */
.icon-plus-1:before {
  content: '\e8b5';
}

/* '' */
.icon-plus-circled-1:before {
  content: '\e8b6';
}

/* '' */
.icon-plus-squared-1:before {
  content: '\e8b7';
}

/* '' */
.icon-minus-1:before {
  content: '\e8b8';
}

/* '' */
.icon-minus-circled-1:before {
  content: '\e8b9';
}

/* '' */
.icon-minus-squared-1:before {
  content: '\e8ba';
}

/* '' */
.icon-help-1:before {
  content: '\e8bb';
}

/* '' */
.icon-help-circled-1:before {
  content: '\e8bc';
}

/* '' */
.icon-info-1:before {
  content: '\e8bd';
}

/* '' */
.icon-info-circled-1:before {
  content: '\e8be';
}

/* '' */
.icon-back:before {
  content: '\e8bf';
}

/* '' */
.icon-home-1:before {
  content: '\e8c0';
}

/* '' */
.icon-link-1:before {
  content: '\e8c1';
}

/* '' */
.icon-attach-1:before {
  content: '\e8c2';
}

/* '' */
.icon-lock-1:before {
  content: '\e8c3';
}

/* '' */
.icon-lock-open-1:before {
  content: '\e8c4';
}

/* '' */
.icon-eye-1:before {
  content: '\e8c5';
}

/* '' */
.icon-tag-1:before {
  content: '\e8c6';
}

/* '' */
.icon-bookmark-1:before {
  content: '\e8c7';
}

/* '' */
.icon-bookmarks:before {
  content: '\e8c8';
}

/* '' */
.icon-flag-1:before {
  content: '\e8c9';
}

/* '' */
.icon-thumbs-up-1:before {
  content: '\e8ca';
}

/* '' */
.icon-thumbs-down-1:before {
  content: '\e8cb';
}

/* '' */
.icon-download-1:before {
  content: '\e8cc';
}

/* '' */
.icon-upload-1:before {
  content: '\e8cd';
}

/* '' */
.icon-upload-cloud-1:before {
  content: '\e8ce';
}

/* '' */
.icon-reply-1:before {
  content: '\e8cf';
}

/* '' */
.icon-reply-all-1:before {
  content: '\e8d0';
}

/* '' */
.icon-forward-1:before {
  content: '\e8d1';
}

/* '' */
.icon-quote:before {
  content: '\e8d2';
}

/* '' */
.icon-code-1:before {
  content: '\e8d3';
}

/* '' */
.icon-export-1:before {
  content: '\e8d4';
}

/* '' */
.icon-pencil-1:before {
  content: '\e8d5';
}

/* '' */
.icon-feather:before {
  content: '\e8d6';
}

/* '' */
.icon-print-1:before {
  content: '\e8d7';
}

/* '' */
.icon-retweet-1:before {
  content: '\e8d8';
}

/* '' */
.icon-keyboard-1:before {
  content: '\e8d9';
}

/* '' */
.icon-comment-1:before {
  content: '\e8da';
}

/* '' */
.icon-chat-1:before {
  content: '\e8db';
}

/* '' */
.icon-bell-1:before {
  content: '\e8dc';
}

/* '' */
.icon-attention-1:before {
  content: '\e8dd';
}

/* '' */
.icon-alert:before {
  content: '\e8de';
}

/* '' */
.icon-vcard:before {
  content: '\e8df';
}

/* '' */
.icon-address:before {
  content: '\e8e0';
}

/* '' */
.icon-location-1:before {
  content: '\e8e1';
}

/* '' */
.icon-map-1:before {
  content: '\e8e2';
}

/* '' */
.icon-direction-1:before {
  content: '\e8e3';
}

/* '' */
.icon-compass-1:before {
  content: '\e8e4';
}

/* '' */
.icon-cup:before {
  content: '\e8e5';
}

/* '' */
.icon-trash-1:before {
  content: '\e8e6';
}

/* '' */
.icon-doc-1:before {
  content: '\e8e7';
}

/* '' */
.icon-docs-1:before {
  content: '\e8e8';
}

/* '' */
.icon-doc-landscape:before {
  content: '\e8e9';
}

/* '' */
.icon-doc-text-1:before {
  content: '\e8ea';
}

/* '' */
.icon-doc-text-inv-1:before {
  content: '\e8eb';
}

/* '' */
.icon-newspaper-1:before {
  content: '\e8ec';
}

/* '' */
.icon-book-open:before {
  content: '\e8ed';
}

/* '' */
.icon-book-1:before {
  content: '\e8ee';
}

/* '' */
.icon-folder-1:before {
  content: '\e8ef';
}

/* '' */
.icon-archive:before {
  content: '\e8f0';
}

/* '' */
.icon-box-1:before {
  content: '\e8f1';
}

/* '' */
.icon-rss-1:before {
  content: '\e8f2';
}

/* '' */
.icon-phone-1:before {
  content: '\e8f3';
}

/* '' */
.icon-cog-1:before {
  content: '\e8f4';
}

/* '' */
.icon-tools:before {
  content: '\e8f5';
}

/* '' */
.icon-share-1:before {
  content: '\e8f6';
}

/* '' */
.icon-shareable:before {
  content: '\e8f7';
}

/* '' */
.icon-basket-1:before {
  content: '\e8f8';
}

/* '' */
.icon-bag:before {
  content: '\e8f9';
}

/* '' */
.icon-calendar-1:before {
  content: '\e8fa';
}

/* '' */
.icon-login-1:before {
  content: '\e8fb';
}

/* '' */
.icon-logout-1:before {
  content: '\e8fc';
}

/* '' */
.icon-mic-1:before {
  content: '\e8fd';
}

/* '' */
.icon-mute-1:before {
  content: '\e8fe';
}

/* '' */
.icon-sound:before {
  content: '\e8ff';
}

/* '' */
.icon-volume:before {
  content: '\e900';
}

/* '' */
.icon-clock-1:before {
  content: '\e901';
}

/* '' */
.icon-hourglass-4:before {
  content: '\e902';
}

/* '' */
.icon-lamp:before {
  content: '\e903';
}

/* '' */
.icon-light-down:before {
  content: '\e904';
}

/* '' */
.icon-light-up:before {
  content: '\e905';
}

/* '' */
.icon-adjust-1:before {
  content: '\e906';
}

/* '' */
.icon-block-1:before {
  content: '\e907';
}

/* '' */
.icon-resize-full-1:before {
  content: '\e908';
}

/* '' */
.icon-resize-small-1:before {
  content: '\e909';
}

/* '' */
.icon-popup:before {
  content: '\e90a';
}

/* '' */
.icon-publish:before {
  content: '\e90b';
}

/* '' */
.icon-window:before {
  content: '\e90c';
}

/* '' */
.icon-arrow-combo:before {
  content: '\e90d';
}

/* '' */
.icon-down-circled-1:before {
  content: '\e90e';
}

/* '' */
.icon-left-circled-1:before {
  content: '\e90f';
}

/* '' */
.icon-right-circled-1:before {
  content: '\e910';
}

/* '' */
.icon-up-circled-1:before {
  content: '\e911';
}

/* '' */
.icon-down-open-1:before {
  content: '\e912';
}

/* '' */
.icon-left-open-1:before {
  content: '\e913';
}

/* '' */
.icon-right-open:before {
  content: '\e914';
}

/* '' */
.icon-up-open-1:before {
  content: '\e915';
}

/* '' */
.icon-down-open-mini:before {
  content: '\e916';
}

/* '' */
.icon-left-open-mini:before {
  content: '\e917';
}

/* '' */
.icon-right-open-mini:before {
  content: '\e918';
}

/* '' */
.icon-up-open-mini:before {
  content: '\e919';
}

/* '' */
.icon-down-open-big:before {
  content: '\e91a';
}

/* '' */
.icon-left-open-big:before {
  content: '\e91b';
}

/* '' */
.icon-right-open-big:before {
  content: '\e91c';
}

/* '' */
.icon-up-open-big:before {
  content: '\e91d';
}

/* '' */
.icon-down-1:before {
  content: '\e91e';
}

/* '' */
.icon-left-1:before {
  content: '\e91f';
}

/* '' */
.icon-right-1:before {
  content: '\e920';
}

/* '' */
.icon-up-1:before {
  content: '\e921';
}

/* '' */
.icon-down-dir-1:before {
  content: '\e922';
}

/* '' */
.icon-left-dir-1:before {
  content: '\e923';
}

/* '' */
.icon-right-dir-1:before {
  content: '\e924';
}

/* '' */
.icon-up-dir-1:before {
  content: '\e925';
}

/* '' */
.icon-down-bold:before {
  content: '\e926';
}

/* '' */
.icon-left-bold:before {
  content: '\e927';
}

/* '' */
.icon-right-bold:before {
  content: '\e928';
}

/* '' */
.icon-up-bold:before {
  content: '\e929';
}

/* '' */
.icon-down-thin:before {
  content: '\e92a';
}

/* '' */
.icon-left-thin:before {
  content: '\e92b';
}

/* '' */
.icon-right-thin:before {
  content: '\e92c';
}

/* '' */
.icon-up-thin:before {
  content: '\e92d';
}

/* '' */
.icon-ccw-1:before {
  content: '\e92e';
}

/* '' */
.icon-cw-1:before {
  content: '\e92f';
}

/* '' */
.icon-arrows-ccw:before {
  content: '\e930';
}

/* '' */
.icon-level-down-1:before {
  content: '\e931';
}

/* '' */
.icon-level-up-1:before {
  content: '\e932';
}

/* '' */
.icon-shuffle-1:before {
  content: '\e933';
}

/* '' */
.icon-loop:before {
  content: '\e934';
}

/* '' */
.icon-switch:before {
  content: '\e935';
}

/* '' */
.icon-play-1:before {
  content: '\e936';
}

/* '' */
.icon-stop-1:before {
  content: '\e937';
}

/* '' */
.icon-pause-1:before {
  content: '\e938';
}

/* '' */
.icon-record:before {
  content: '\e939';
}

/* '' */
.icon-to-end-1:before {
  content: '\e93a';
}

/* '' */
.icon-to-start-1:before {
  content: '\e93b';
}

/* '' */
.icon-fast-forward:before {
  content: '\e93c';
}

/* '' */
.icon-fast-backward:before {
  content: '\e93d';
}

/* '' */
.icon-progress-0:before {
  content: '\e93e';
}

/* '' */
.icon-progress-1:before {
  content: '\e93f';
}

/* '' */
.icon-progress-2:before {
  content: '\e940';
}

/* '' */
.icon-progress-3:before {
  content: '\e941';
}

/* '' */
.icon-target-1:before {
  content: '\e942';
}

/* '' */
.icon-palette:before {
  content: '\e943';
}

/* '' */
.icon-list-1:before {
  content: '\e944';
}

/* '' */
.icon-list-add:before {
  content: '\e945';
}

/* '' */
.icon-signal-1:before {
  content: '\e946';
}

/* '' */
.icon-trophy:before {
  content: '\e947';
}

/* '' */
.icon-battery:before {
  content: '\e948';
}

/* '' */
.icon-back-in-time:before {
  content: '\e949';
}

/* '' */
.icon-monitor:before {
  content: '\e94a';
}

/* '' */
.icon-mobile-1:before {
  content: '\e94b';
}

/* '' */
.icon-network:before {
  content: '\e94c';
}

/* '' */
.icon-cd:before {
  content: '\e94d';
}

/* '' */
.icon-inbox-1:before {
  content: '\e94e';
}

/* '' */
.icon-install:before {
  content: '\e94f';
}

/* '' */
.icon-globe-1:before {
  content: '\e950';
}

/* '' */
.icon-cloud-1:before {
  content: '\e951';
}

/* '' */
.icon-cloud-thunder:before {
  content: '\e952';
}

/* '' */
.icon-flash-1:before {
  content: '\e953';
}

/* '' */
.icon-moon-1:before {
  content: '\e954';
}

/* '' */
.icon-flight-1:before {
  content: '\e955';
}

/* '' */
.icon-paper-plane-1:before {
  content: '\e956';
}

/* '' */
.icon-leaf-1:before {
  content: '\e957';
}

/* '' */
.icon-lifebuoy-1:before {
  content: '\e958';
}

/* '' */
.icon-mouse:before {
  content: '\e959';
}

/* '' */
.icon-briefcase-1:before {
  content: '\e95a';
}

/* '' */
.icon-suitcase-1:before {
  content: '\e95b';
}

/* '' */
.icon-dot:before {
  content: '\e95c';
}

/* '' */
.icon-dot-2:before {
  content: '\e95d';
}

/* '' */
.icon-dot-3:before {
  content: '\e95e';
}

/* '' */
.icon-brush-1:before {
  content: '\e95f';
}

/* '' */
.icon-magnet-1:before {
  content: '\e960';
}

/* '' */
.icon-infinity:before {
  content: '\e961';
}

/* '' */
.icon-erase:before {
  content: '\e962';
}

/* '' */
.icon-chart-pie-1:before {
  content: '\e963';
}

/* '' */
.icon-chart-line-1:before {
  content: '\e964';
}

/* '' */
.icon-chart-bar-1:before {
  content: '\e965';
}

/* '' */
.icon-chart-area-1:before {
  content: '\e966';
}

/* '' */
.icon-tape:before {
  content: '\e967';
}

/* '' */
.icon-graduation-cap-1:before {
  content: '\e968';
}

/* '' */
.icon-language-1:before {
  content: '\e969';
}

/* '' */
.icon-ticket-1:before {
  content: '\e96a';
}

/* '' */
.icon-water:before {
  content: '\e96b';
}

/* '' */
.icon-droplet:before {
  content: '\e96c';
}

/* '' */
.icon-air:before {
  content: '\e96d';
}

/* '' */
.icon-credit-card-1:before {
  content: '\e96e';
}

/* '' */
.icon-floppy-1:before {
  content: '\e96f';
}

/* '' */
.icon-clipboard:before {
  content: '\e970';
}

/* '' */
.icon-megaphone-1:before {
  content: '\e971';
}

/* '' */
.icon-database-1:before {
  content: '\e972';
}

/* '' */
.icon-drive:before {
  content: '\e973';
}

/* '' */
.icon-bucket:before {
  content: '\e974';
}

/* '' */
.icon-thermometer-1:before {
  content: '\e975';
}

/* '' */
.icon-key-1:before {
  content: '\e976';
}

/* '' */
.icon-flow-cascade:before {
  content: '\e977';
}

/* '' */
.icon-flow-branch:before {
  content: '\e978';
}

/* '' */
.icon-flow-tree:before {
  content: '\e979';
}

/* '' */
.icon-flow-line:before {
  content: '\e97a';
}

/* '' */
.icon-flow-parallel:before {
  content: '\e97b';
}

/* '' */
.icon-rocket-1:before {
  content: '\e97c';
}

/* '' */
.icon-gauge-1:before {
  content: '\e97d';
}

/* '' */
.icon-traffic-cone:before {
  content: '\e97e';
}

/* '' */
.icon-cc-1:before {
  content: '\e97f';
}

/* '' */
.icon-cc-by:before {
  content: '\e980';
}

/* '' */
.icon-cc-nc:before {
  content: '\e981';
}

/* '' */
.icon-cc-nc-eu:before {
  content: '\e982';
}

/* '' */
.icon-cc-nc-jp:before {
  content: '\e983';
}

/* '' */
.icon-cc-sa:before {
  content: '\e984';
}

/* '' */
.icon-cc-nd:before {
  content: '\e985';
}

/* '' */
.icon-cc-pd:before {
  content: '\e986';
}

/* '' */
.icon-cc-zero:before {
  content: '\e987';
}

/* '' */
.icon-cc-share:before {
  content: '\e988';
}

/* '' */
.icon-cc-remix:before {
  content: '\e989';
}

/* '' */
.icon-github-1:before {
  content: '\e98a';
}

/* '' */
.icon-flickr-circled:before {
  content: '\e98b';
}

/* '' */
.icon-facebook-1:before {
  content: '\e98c';
}

/* '' */
.icon-music-outline:before {
  content: '\e98d';
}

/* '' */
.icon-music-2:before {
  content: '\e98e';
}

/* '' */
.icon-search-outline:before {
  content: '\e98f';
}

/* '' */
.icon-search-2:before {
  content: '\e990';
}

/* '' */
.icon-mail-2:before {
  content: '\e991';
}

/* '' */
.icon-heart-2:before {
  content: '\e992';
}

/* '' */
.icon-heart-filled:before {
  content: '\e993';
}

/* '' */
.icon-star-2:before {
  content: '\e994';
}

/* '' */
.icon-star-filled:before {
  content: '\e995';
}

/* '' */
.icon-user-outline:before {
  content: '\e996';
}

/* '' */
.icon-user-2:before {
  content: '\e997';
}

/* '' */
.icon-users-outline:before {
  content: '\e998';
}

/* '' */
.icon-users-2:before {
  content: '\e999';
}

/* '' */
.icon-user-add-outline:before {
  content: '\e99a';
}

/* '' */
.icon-user-add-1:before {
  content: '\e99b';
}

/* '' */
.icon-user-delete-outline:before {
  content: '\e99c';
}

/* '' */
.icon-user-delete:before {
  content: '\e99d';
}

/* '' */
.icon-video-2:before {
  content: '\e99e';
}

/* '' */
.icon-videocam-outline:before {
  content: '\e99f';
}

/* '' */
.icon-videocam-1:before {
  content: '\e9a0';
}

/* '' */
.icon-picture-outline:before {
  content: '\e9a1';
}

/* '' */
.icon-picture-2:before {
  content: '\e9a2';
}

/* '' */
.icon-camera-outline:before {
  content: '\e9a3';
}

/* '' */
.icon-camera-2:before {
  content: '\e9a4';
}

/* '' */
.icon-th-outline:before {
  content: '\e9a5';
}

/* '' */
.icon-th-1:before {
  content: '\e9a6';
}

/* '' */
.icon-th-large-outline:before {
  content: '\e9a7';
}

/* '' */
.icon-th-large-1:before {
  content: '\e9a8';
}

/* '' */
.icon-th-list-outline:before {
  content: '\e9a9';
}

/* '' */
.icon-th-list-1:before {
  content: '\e9aa';
}

/* '' */
.icon-ok-outline:before {
  content: '\e9ab';
}

/* '' */
.icon-ok-1:before {
  content: '\e9ac';
}

/* '' */
.icon-cancel-outline:before {
  content: '\e9ad';
}

/* '' */
.icon-cancel-2:before {
  content: '\e9ae';
}

/* '' */
.icon-cancel-alt:before {
  content: '\e9af';
}

/* '' */
.icon-cancel-alt-filled:before {
  content: '\e9b0';
}

/* '' */
.icon-cancel-circled-outline:before {
  content: '\e9b1';
}

/* '' */
.icon-cancel-circled-2:before {
  content: '\e9b2';
}

/* '' */
.icon-plus-outline:before {
  content: '\e9b3';
}

/* '' */
.icon-plus-2:before {
  content: '\e9b4';
}

/* '' */
.icon-minus-outline:before {
  content: '\e9b5';
}

/* '' */
.icon-minus-2:before {
  content: '\e9b6';
}

/* '' */
.icon-divide-outline:before {
  content: '\e9b7';
}

/* '' */
.icon-divide:before {
  content: '\e9b8';
}

/* '' */
.icon-eq-outline:before {
  content: '\e9b9';
}

/* '' */
.icon-eq:before {
  content: '\e9ba';
}

/* '' */
.icon-info-outline:before {
  content: '\e9bb';
}

/* '' */
.icon-info-2:before {
  content: '\e9bc';
}

/* '' */
.icon-home-outline:before {
  content: '\e9bd';
}

/* '' */
.icon-home-2:before {
  content: '\e9be';
}

/* '' */
.icon-link-outline:before {
  content: '\e9bf';
}

/* '' */
.icon-link-2:before {
  content: '\e9c0';
}

/* '' */
.icon-attach-outline:before {
  content: '\e9c1';
}

/* '' */
.icon-attach-2:before {
  content: '\e9c2';
}

/* '' */
.icon-lock-2:before {
  content: '\e9c3';
}

/* '' */
.icon-lock-filled:before {
  content: '\e9c4';
}

/* '' */
.icon-lock-open-2:before {
  content: '\e9c5';
}

/* '' */
.icon-lock-open-filled:before {
  content: '\e9c6';
}

/* '' */
.icon-pin-outline:before {
  content: '\e9c7';
}

/* '' */
.icon-pin-1:before {
  content: '\e9c8';
}

/* '' */
.icon-eye-outline:before {
  content: '\e9c9';
}

/* '' */
.icon-eye-2:before {
  content: '\e9ca';
}

/* '' */
.icon-tag-2:before {
  content: '\e9cb';
}

/* '' */
.icon-tags-1:before {
  content: '\e9cc';
}

/* '' */
.icon-bookmark-2:before {
  content: '\e9cd';
}

/* '' */
.icon-flag-2:before {
  content: '\e9ce';
}

/* '' */
.icon-flag-filled:before {
  content: '\e9cf';
}

/* '' */
.icon-thumbs-up-2:before {
  content: '\e9d0';
}

/* '' */
.icon-thumbs-down-2:before {
  content: '\e9d1';
}

/* '' */
.icon-download-outline:before {
  content: '\e9d2';
}

/* '' */
.icon-download-2:before {
  content: '\e9d3';
}

/* '' */
.icon-upload-outline:before {
  content: '\e9d4';
}

/* '' */
.icon-upload-2:before {
  content: '\e9d5';
}

/* '' */
.icon-upload-cloud-outline:before {
  content: '\e9d6';
}

/* '' */
.icon-upload-cloud-2:before {
  content: '\e9d7';
}

/* '' */
.icon-reply-outline:before {
  content: '\e9d8';
}

/* '' */
.icon-reply-2:before {
  content: '\e9d9';
}

/* '' */
.icon-forward-outline:before {
  content: '\e9da';
}

/* '' */
.icon-forward-2:before {
  content: '\e9db';
}

/* '' */
.icon-code-outline:before {
  content: '\e9dc';
}

/* '' */
.icon-code-2:before {
  content: '\e9dd';
}

/* '' */
.icon-export-outline:before {
  content: '\e9de';
}

/* '' */
.icon-export-2:before {
  content: '\e9df';
}

/* '' */
.icon-pencil-2:before {
  content: '\e9e0';
}

/* '' */
.icon-pen:before {
  content: '\e9e1';
}

/* '' */
.icon-feather-1:before {
  content: '\e9e2';
}

/* '' */
.icon-edit-1:before {
  content: '\e9e3';
}

/* '' */
.icon-print-2:before {
  content: '\e9e4';
}

/* '' */
.icon-comment-2:before {
  content: '\e9e5';
}

/* '' */
.icon-chat-2:before {
  content: '\e9e6';
}

/* '' */
.icon-chat-alt:before {
  content: '\e9e7';
}

/* '' */
.icon-bell-2:before {
  content: '\e9e8';
}

/* '' */
.icon-attention-2:before {
  content: '\e9e9';
}

/* '' */
.icon-attention-filled:before {
  content: '\e9ea';
}

/* '' */
.icon-warning-empty:before {
  content: '\e9eb';
}

/* '' */
.icon-warning:before {
  content: '\e9ec';
}

/* '' */
.icon-contacts:before {
  content: '\e9ed';
}

/* '' */
.icon-vcard-1:before {
  content: '\e9ee';
}

/* '' */
.icon-address-1:before {
  content: '\e9ef';
}

/* '' */
.icon-location-outline:before {
  content: '\e9f0';
}

/* '' */
.icon-location-2:before {
  content: '\e9f1';
}

/* '' */
.icon-map-2:before {
  content: '\e9f2';
}

/* '' */
.icon-direction-outline:before {
  content: '\e9f3';
}

/* '' */
.icon-direction-2:before {
  content: '\e9f4';
}

/* '' */
.icon-compass-2:before {
  content: '\e9f5';
}

/* '' */
.icon-trash-2:before {
  content: '\e9f6';
}

/* '' */
.icon-doc-2:before {
  content: '\e9f7';
}

/* '' */
.icon-doc-text-2:before {
  content: '\e9f8';
}

/* '' */
.icon-doc-add:before {
  content: '\e9f9';
}

/* '' */
.icon-doc-remove:before {
  content: '\e9fa';
}

/* '' */
.icon-news:before {
  content: '\e9fb';
}

/* '' */
.icon-folder-2:before {
  content: '\e9fc';
}

/* '' */
.icon-folder-add:before {
  content: '\e9fd';
}

/* '' */
.icon-folder-delete:before {
  content: '\e9fe';
}

/* '' */
.icon-archive-1:before {
  content: '\e9ff';
}

/* '' */
.icon-box-2:before {
  content: '\ea00';
}

/* '' */
.icon-rss-outline:before {
  content: '\ea01';
}

/* '' */
.icon-rss-2:before {
  content: '\ea02';
}

/* '' */
.icon-phone-outline:before {
  content: '\ea03';
}

/* '' */
.icon-phone-2:before {
  content: '\ea04';
}

/* '' */
.icon-menu-outline:before {
  content: '\ea05';
}

/* '' */
.icon-menu-2:before {
  content: '\ea06';
}

/* '' */
.icon-cog-outline:before {
  content: '\ea07';
}

/* '' */
.icon-cog-2:before {
  content: '\ea08';
}

/* '' */
.icon-wrench-outline:before {
  content: '\ea09';
}

/* '' */
.icon-wrench-1:before {
  content: '\ea0a';
}

/* '' */
.icon-basket-2:before {
  content: '\ea0b';
}

/* '' */
.icon-calendar-outlilne:before {
  content: '\ea0c';
}

/* '' */
.icon-calendar-2:before {
  content: '\ea0d';
}

/* '' */
.icon-mic-outline:before {
  content: '\ea0e';
}

/* '' */
.icon-mic-2:before {
  content: '\ea0f';
}

/* '' */
.icon-volume-off-1:before {
  content: '\ea10';
}

/* '' */
.icon-volume-low:before {
  content: '\ea11';
}

/* '' */
.icon-volume-middle:before {
  content: '\ea12';
}

/* '' */
.icon-volume-high:before {
  content: '\ea13';
}

/* '' */
.icon-headphones-1:before {
  content: '\ea14';
}

/* '' */
.icon-clock-2:before {
  content: '\ea15';
}

/* '' */
.icon-wristwatch:before {
  content: '\ea16';
}

/* '' */
.icon-stopwatch:before {
  content: '\ea17';
}

/* '' */
.icon-lightbulb-1:before {
  content: '\ea18';
}

/* '' */
.icon-block-outline:before {
  content: '\ea19';
}

/* '' */
.icon-block-2:before {
  content: '\ea1a';
}

/* '' */
.icon-resize-full-outline:before {
  content: '\ea1b';
}

/* '' */
.icon-resize-full-2:before {
  content: '\ea1c';
}

/* '' */
.icon-resize-normal-outline:before {
  content: '\ea1d';
}

/* '' */
.icon-resize-normal:before {
  content: '\ea1e';
}

/* '' */
.icon-move-outline:before {
  content: '\ea1f';
}

/* '' */
.icon-move-1:before {
  content: '\ea20';
}

/* '' */
.icon-popup-1:before {
  content: '\ea21';
}

/* '' */
.icon-zoom-in-outline:before {
  content: '\ea22';
}

/* '' */
.icon-zoom-in-1:before {
  content: '\ea23';
}

/* '' */
.icon-zoom-out-outline:before {
  content: '\ea24';
}

/* '' */
.icon-zoom-out-1:before {
  content: '\ea25';
}

/* '' */
.icon-popup-2:before {
  content: '\ea26';
}

/* '' */
.icon-left-open-outline:before {
  content: '\ea27';
}

/* '' */
.icon-left-open-2:before {
  content: '\ea28';
}

/* '' */
.icon-right-open-outline:before {
  content: '\ea29';
}

/* '' */
.icon-right-open-1:before {
  content: '\ea2a';
}

/* '' */
.icon-down-2:before {
  content: '\ea2b';
}

/* '' */
.icon-left-2:before {
  content: '\ea2c';
}

/* '' */
.icon-right-2:before {
  content: '\ea2d';
}

/* '' */
.icon-up-2:before {
  content: '\ea2e';
}

/* '' */
.icon-down-outline:before {
  content: '\ea2f';
}

/* '' */
.icon-left-outline:before {
  content: '\ea30';
}

/* '' */
.icon-right-outline:before {
  content: '\ea31';
}

/* '' */
.icon-up-outline:before {
  content: '\ea32';
}

/* '' */
.icon-down-small:before {
  content: '\ea33';
}

/* '' */
.icon-left-small:before {
  content: '\ea34';
}

/* '' */
.icon-right-small:before {
  content: '\ea35';
}

/* '' */
.icon-up-small:before {
  content: '\ea36';
}

/* '' */
.icon-cw-outline:before {
  content: '\ea37';
}

/* '' */
.icon-cw-2:before {
  content: '\ea38';
}

/* '' */
.icon-arrows-cw-outline:before {
  content: '\ea39';
}

/* '' */
.icon-arrows-cw-1:before {
  content: '\ea3a';
}

/* '' */
.icon-loop-outline:before {
  content: '\ea3b';
}

/* '' */
.icon-loop-1:before {
  content: '\ea3c';
}

/* '' */
.icon-loop-alt-outline:before {
  content: '\ea3d';
}

/* '' */
.icon-loop-alt:before {
  content: '\ea3e';
}

/* '' */
.icon-shuffle-2:before {
  content: '\ea3f';
}

/* '' */
.icon-play-outline:before {
  content: '\ea40';
}

/* '' */
.icon-play-2:before {
  content: '\ea41';
}

/* '' */
.icon-stop-outline:before {
  content: '\ea42';
}

/* '' */
.icon-stop-2:before {
  content: '\ea43';
}

/* '' */
.icon-pause-outline:before {
  content: '\ea44';
}

/* '' */
.icon-pause-2:before {
  content: '\ea45';
}

/* '' */
.icon-fast-fw-outline:before {
  content: '\ea46';
}

/* '' */
.icon-fast-fw-1:before {
  content: '\ea47';
}

/* '' */
.icon-rewind-outline:before {
  content: '\ea48';
}

/* '' */
.icon-rewind:before {
  content: '\ea49';
}

/* '' */
.icon-record-outline:before {
  content: '\ea4a';
}

/* '' */
.icon-record-1:before {
  content: '\ea4b';
}

/* '' */
.icon-eject-outline:before {
  content: '\ea4c';
}

/* '' */
.icon-eject-1:before {
  content: '\ea4d';
}

/* '' */
.icon-eject-alt-outline:before {
  content: '\ea4e';
}

/* '' */
.icon-eject-alt:before {
  content: '\ea4f';
}

/* '' */
.icon-bat1:before {
  content: '\ea50';
}

/* '' */
.icon-bat2:before {
  content: '\ea51';
}

/* '' */
.icon-bat3:before {
  content: '\ea52';
}

/* '' */
.icon-bat4:before {
  content: '\ea53';
}

/* '' */
.icon-bat-charge:before {
  content: '\ea54';
}

/* '' */
.icon-plug-1:before {
  content: '\ea55';
}

/* '' */
.icon-target-outline:before {
  content: '\ea56';
}

/* '' */
.icon-target-2:before {
  content: '\ea57';
}

/* '' */
.icon-wifi-outline:before {
  content: '\ea58';
}

/* '' */
.icon-wifi-1:before {
  content: '\ea59';
}

/* '' */
.icon-desktop-1:before {
  content: '\ea5a';
}

/* '' */
.icon-laptop-1:before {
  content: '\ea5b';
}

/* '' */
.icon-tablet-1:before {
  content: '\ea5c';
}

/* '' */
.icon-mobile-2:before {
  content: '\ea5d';
}

/* '' */
.icon-contrast:before {
  content: '\ea5e';
}

/* '' */
.icon-globe-outline:before {
  content: '\ea5f';
}

/* '' */
.icon-globe-2:before {
  content: '\ea60';
}

/* '' */
.icon-globe-alt-outline:before {
  content: '\ea61';
}

/* '' */
.icon-globe-alt:before {
  content: '\ea62';
}

/* '' */
.icon-sun-1:before {
  content: '\ea63';
}

/* '' */
.icon-sun-filled:before {
  content: '\ea64';
}

/* '' */
.icon-cloud-2:before {
  content: '\ea65';
}

/* '' */
.icon-flash-outline:before {
  content: '\ea66';
}

/* '' */
.icon-flash-2:before {
  content: '\ea67';
}

/* '' */
.icon-moon-2:before {
  content: '\ea68';
}

/* '' */
.icon-waves-outline:before {
  content: '\ea69';
}

/* '' */
.icon-waves:before {
  content: '\ea6a';
}

/* '' */
.icon-rain:before {
  content: '\ea6b';
}

/* '' */
.icon-cloud-sun:before {
  content: '\ea6c';
}

/* '' */
.icon-drizzle:before {
  content: '\ea6d';
}

/* '' */
.icon-snow:before {
  content: '\ea6e';
}

/* '' */
.icon-cloud-flash:before {
  content: '\ea6f';
}

/* '' */
.icon-cloud-wind:before {
  content: '\ea70';
}

/* '' */
.icon-wind:before {
  content: '\ea71';
}

/* '' */
.icon-plane-outline:before {
  content: '\ea72';
}

/* '' */
.icon-plane:before {
  content: '\ea73';
}

/* '' */
.icon-leaf-2:before {
  content: '\ea74';
}

/* '' */
.icon-lifebuoy-2:before {
  content: '\ea75';
}

/* '' */
.icon-briefcase-2:before {
  content: '\ea76';
}

/* '' */
.icon-brush-2:before {
  content: '\ea77';
}

/* '' */
.icon-pipette:before {
  content: '\ea78';
}

/* '' */
.icon-power-outline:before {
  content: '\ea79';
}

/* '' */
.icon-power:before {
  content: '\ea7a';
}

/* '' */
.icon-check-outline:before {
  content: '\ea7b';
}

/* '' */
.icon-check-2:before {
  content: '\ea7c';
}

/* '' */
.icon-gift-1:before {
  content: '\ea7d';
}

/* '' */
.icon-temperatire:before {
  content: '\ea7e';
}

/* '' */
.icon-chart-outline:before {
  content: '\ea7f';
}

/* '' */
.icon-chart:before {
  content: '\ea80';
}

/* '' */
.icon-chart-alt-outline:before {
  content: '\ea81';
}

/* '' */
.icon-chart-alt:before {
  content: '\ea82';
}

/* '' */
.icon-chart-bar-outline:before {
  content: '\ea83';
}

/* '' */
.icon-chart-bar-2:before {
  content: '\ea84';
}

/* '' */
.icon-chart-pie-outline:before {
  content: '\ea85';
}

/* '' */
.icon-chart-pie-2:before {
  content: '\ea86';
}

/* '' */
.icon-ticket-2:before {
  content: '\ea87';
}

/* '' */
.icon-credit-card-2:before {
  content: '\ea88';
}

/* '' */
.icon-clipboard-1:before {
  content: '\ea89';
}

/* '' */
.icon-database-2:before {
  content: '\ea8a';
}

/* '' */
.icon-key-outline:before {
  content: '\ea8b';
}

/* '' */
.icon-key-2:before {
  content: '\ea8c';
}

/* '' */
.icon-flow-split:before {
  content: '\ea8d';
}

/* '' */
.icon-flow-merge:before {
  content: '\ea8e';
}

/* '' */
.icon-flow-parallel-1:before {
  content: '\ea8f';
}

/* '' */
.icon-flow-cross:before {
  content: '\ea90';
}

/* '' */
.icon-certificate-outline:before {
  content: '\ea91';
}

/* '' */
.icon-certificate-1:before {
  content: '\ea92';
}

/* '' */
.icon-scissors-outline:before {
  content: '\ea93';
}

/* '' */
.icon-scissors-1:before {
  content: '\ea94';
}

/* '' */
.icon-flask:before {
  content: '\ea95';
}

/* '' */
.icon-wine:before {
  content: '\ea96';
}

/* '' */
.icon-coffee-1:before {
  content: '\ea97';
}

/* '' */
.icon-beer-1:before {
  content: '\ea98';
}

/* '' */
.icon-anchor-outline:before {
  content: '\ea99';
}

/* '' */
.icon-anchor-1:before {
  content: '\ea9a';
}

/* '' */
.icon-puzzle-outline:before {
  content: '\ea9b';
}

/* '' */
.icon-puzzle-1:before {
  content: '\ea9c';
}

/* '' */
.icon-tree-1:before {
  content: '\ea9d';
}

/* '' */
.icon-calculator:before {
  content: '\ea9e';
}

/* '' */
.icon-infinity-outline:before {
  content: '\ea9f';
}

/* '' */
.icon-infinity-1:before {
  content: '\eaa0';
}

/* '' */
.icon-pi-outline:before {
  content: '\eaa1';
}

/* '' */
.icon-pi:before {
  content: '\eaa2';
}

/* '' */
.icon-at-1:before {
  content: '\eaa3';
}

/* '' */
.icon-at-circled:before {
  content: '\eaa4';
}

/* '' */
.icon-looped-square-outline:before {
  content: '\eaa5';
}

/* '' */
.icon-looped-square-interest:before {
  content: '\eaa6';
}

/* '' */
.icon-sort-alphabet-outline:before {
  content: '\eaa7';
}

/* '' */
.icon-sort-alphabet:before {
  content: '\eaa8';
}

/* '' */
.icon-sort-numeric-outline:before {
  content: '\eaa9';
}

/* '' */
.icon-sort-numeric:before {
  content: '\eaaa';
}

/* '' */
.icon-dribbble-circled-1:before {
  content: '\eaab';
}

/* '' */
.icon-dribbble-2:before {
  content: '\eaac';
}

/* '' */
.icon-facebook-circled-1:before {
  content: '\eaad';
}

/* '' */
.icon-facebook-2:before {
  content: '\eaae';
}

/* '' */
.icon-flickr-circled-1:before {
  content: '\eaaf';
}

/* '' */
.icon-flickr-2:before {
  content: '\eab0';
}

/* '' */
.icon-github-circled-2:before {
  content: '\eab1';
}

/* '' */
.icon-github-2:before {
  content: '\eab2';
}

/* '' */
.icon-lastfm-circled-1:before {
  content: '\eab3';
}

/* '' */
.icon-lastfm-2:before {
  content: '\eab4';
}

/* '' */
.icon-linkedin-circled-1:before {
  content: '\eab5';
}

/* '' */
.icon-linkedin-2:before {
  content: '\eab6';
}

/* '' */
.icon-pinterest-circled-2:before {
  content: '\eab7';
}

/* '' */
.icon-pinterest-2:before {
  content: '\eab8';
}

/* '' */
.icon-skype-outline:before {
  content: '\eab9';
}

/* '' */
.icon-skype-2:before {
  content: '\eaba';
}

/* '' */
.icon-tumbler-circled:before {
  content: '\eabb';
}

/* '' */
.icon-tumbler:before {
  content: '\eabc';
}

/* '' */
.icon-twitter-circled-1:before {
  content: '\eabd';
}

/* '' */
.icon-twitter-2:before {
  content: '\eabe';
}

/* '' */
.icon-vimeo-circled-1:before {
  content: '\eabf';
}

/* '' */
.icon-vimeo-2:before {
  content: '\eac0';
}

/* '' */
.icon-search-3:before {
  content: '\eac1';
}

/* '' */
.icon-mail-3:before {
  content: '\eac2';
}

/* '' */
.icon-heart-3:before {
  content: '\eac3';
}

/* '' */
.icon-heart-empty-2:before {
  content: '\eac4';
}

/* '' */
.icon-star-3:before {
  content: '\eac5';
}

/* '' */
.icon-user-3:before {
  content: '\eac6';
}

/* '' */
.icon-video-3:before {
  content: '\eac7';
}

/* '' */
.icon-picture-3:before {
  content: '\eac8';
}

/* '' */
.icon-camera-3:before {
  content: '\eac9';
}

/* '' */
.icon-ok-2:before {
  content: '\eaca';
}

/* '' */
.icon-ok-circle:before {
  content: '\eacb';
}

/* '' */
.icon-cancel-3:before {
  content: '\eacc';
}

/* '' */
.icon-cancel-circle:before {
  content: '\eacd';
}

/* '' */
.icon-plus-3:before {
  content: '\eace';
}

/* '' */
.icon-plus-circle:before {
  content: '\eacf';
}

/* '' */
.icon-minus-3:before {
  content: '\ead0';
}

/* '' */
.icon-minus-circle:before {
  content: '\ead1';
}

/* '' */
.icon-help-2:before {
  content: '\ead2';
}

/* '' */
.icon-info-3:before {
  content: '\ead3';
}

/* '' */
.icon-home-3:before {
  content: '\ead4';
}

/* '' */
.icon-link-3:before {
  content: '\ead5';
}

/* '' */
.icon-attach-3:before {
  content: '\ead6';
}

/* '' */
.icon-lock-3:before {
  content: '\ead7';
}

/* '' */
.icon-lock-empty:before {
  content: '\ead8';
}

/* '' */
.icon-lock-open-3:before {
  content: '\ead9';
}

/* '' */
.icon-lock-open-empty:before {
  content: '\eada';
}

/* '' */
.icon-pin-2:before {
  content: '\eadb';
}

/* '' */
.icon-eye-3:before {
  content: '\eadc';
}

/* '' */
.icon-tag-3:before {
  content: '\eadd';
}

/* '' */
.icon-tag-empty:before {
  content: '\eade';
}

/* '' */
.icon-download-3:before {
  content: '\eadf';
}

/* '' */
.icon-upload-3:before {
  content: '\eae0';
}

/* '' */
.icon-download-cloud-1:before {
  content: '\eae1';
}

/* '' */
.icon-upload-cloud-3:before {
  content: '\eae2';
}

/* '' */
.icon-quote-left-1:before {
  content: '\eae3';
}

/* '' */
.icon-quote-right-1:before {
  content: '\eae4';
}

/* '' */
.icon-quote-left-alt:before {
  content: '\eae5';
}

/* '' */
.icon-quote-right-alt:before {
  content: '\eae6';
}

/* '' */
.icon-pencil-3:before {
  content: '\eae7';
}

/* '' */
.icon-pencil-neg:before {
  content: '\eae8';
}

/* '' */
.icon-pencil-alt:before {
  content: '\eae9';
}

/* '' */
.icon-undo:before {
  content: '\eaea';
}

/* '' */
.icon-comment-3:before {
  content: '\eaeb';
}

/* '' */
.icon-comment-inv:before {
  content: '\eaec';
}

/* '' */
.icon-comment-alt:before {
  content: '\eaed';
}

/* '' */
.icon-comment-inv-alt:before {
  content: '\eaee';
}

/* '' */
.icon-comment-alt2:before {
  content: '\eaef';
}

/* '' */
.icon-comment-inv-alt2:before {
  content: '\eaf0';
}

/* '' */
.icon-chat-3:before {
  content: '\eaf1';
}

/* '' */
.icon-chat-inv:before {
  content: '\eaf2';
}

/* '' */
.icon-location-3:before {
  content: '\eaf3';
}

/* '' */
.icon-location-inv:before {
  content: '\eaf4';
}

/* '' */
.icon-location-alt:before {
  content: '\eaf5';
}

/* '' */
.icon-compass-3:before {
  content: '\eaf6';
}

/* '' */
.icon-trash-3:before {
  content: '\eaf7';
}

/* '' */
.icon-trash-empty-1:before {
  content: '\eaf8';
}

/* '' */
.icon-doc-3:before {
  content: '\eaf9';
}

/* '' */
.icon-doc-inv-1:before {
  content: '\eafa';
}

/* '' */
.icon-doc-alt:before {
  content: '\eafb';
}

/* '' */
.icon-doc-inv-alt:before {
  content: '\eafc';
}

/* '' */
.icon-article:before {
  content: '\eafd';
}

/* '' */
.icon-article-alt:before {
  content: '\eafe';
}

/* '' */
.icon-book-open-1:before {
  content: '\eaff';
}

/* '' */
.icon-folder-3:before {
  content: '\eb00';
}

/* '' */
.icon-folder-empty-1:before {
  content: '\eb01';
}

/* '' */
.icon-box-3:before {
  content: '\eb02';
}

/* '' */
.icon-rss-3:before {
  content: '\eb03';
}

/* '' */
.icon-rss-alt:before {
  content: '\eb04';
}

/* '' */
.icon-cog-3:before {
  content: '\eb05';
}

/* '' */
.icon-wrench-2:before {
  content: '\eb06';
}

/* '' */
.icon-share-2:before {
  content: '\eb07';
}

/* '' */
.icon-calendar-3:before {
  content: '\eb08';
}

/* '' */
.icon-calendar-inv:before {
  content: '\eb09';
}

/* '' */
.icon-calendar-alt:before {
  content: '\eb0a';
}

/* '' */
.icon-mic-3:before {
  content: '\eb0b';
}

/* '' */
.icon-volume-off-2:before {
  content: '\eb0c';
}

/* '' */
.icon-volume-up-1:before {
  content: '\eb0d';
}

/* '' */
.icon-headphones-2:before {
  content: '\eb0e';
}

/* '' */
.icon-clock-3:before {
  content: '\eb0f';
}

/* '' */
.icon-lamp-1:before {
  content: '\eb10';
}

/* '' */
.icon-block-3:before {
  content: '\eb11';
}

/* '' */
.icon-resize-full-3:before {
  content: '\eb12';
}

/* '' */
.icon-resize-full-alt-1:before {
  content: '\eb13';
}

/* '' */
.icon-resize-small-2:before {
  content: '\eb14';
}

/* '' */
.icon-resize-small-alt:before {
  content: '\eb15';
}

/* '' */
.icon-resize-vertical-1:before {
  content: '\eb16';
}

/* '' */
.icon-resize-horizontal-1:before {
  content: '\eb17';
}

/* '' */
.icon-move-2:before {
  content: '\eb18';
}

/* '' */
.icon-popup-3:before {
  content: '\eb19';
}

/* '' */
.icon-down-3:before {
  content: '\eb1a';
}

/* '' */
.icon-left-3:before {
  content: '\eb1b';
}

/* '' */
.icon-right-3:before {
  content: '\eb1c';
}

/* '' */
.icon-up-3:before {
  content: '\eb1d';
}

/* '' */
.icon-down-circle:before {
  content: '\eb1e';
}

/* '' */
.icon-left-circle:before {
  content: '\eb1f';
}

/* '' */
.icon-right-circle:before {
  content: '\eb20';
}

/* '' */
.icon-up-circle:before {
  content: '\eb21';
}

/* '' */
.icon-cw-3:before {
  content: '\eb22';
}

/* '' */
.icon-loop-2:before {
  content: '\eb23';
}

/* '' */
.icon-loop-alt-1:before {
  content: '\eb24';
}

/* '' */
.icon-exchange-1:before {
  content: '\eb25';
}

/* '' */
.icon-split:before {
  content: '\eb26';
}

/* '' */
.icon-arrow-curved:before {
  content: '\eb27';
}

/* '' */
.icon-play-3:before {
  content: '\eb28';
}

/* '' */
.icon-play-circle2:before {
  content: '\eb29';
}

/* '' */
.icon-stop-3:before {
  content: '\eb2a';
}

/* '' */
.icon-pause-3:before {
  content: '\eb2b';
}

/* '' */
.icon-to-start-2:before {
  content: '\eb2c';
}

/* '' */
.icon-to-end-2:before {
  content: '\eb2d';
}

/* '' */
.icon-eject-2:before {
  content: '\eb2e';
}

/* '' */
.icon-target-3:before {
  content: '\eb2f';
}

/* '' */
.icon-signal-2:before {
  content: '\eb30';
}

/* '' */
.icon-award-1:before {
  content: '\eb31';
}

/* '' */
.icon-award-empty:before {
  content: '\eb32';
}

/* '' */
.icon-list-2:before {
  content: '\eb33';
}

/* '' */
.icon-list-nested:before {
  content: '\eb34';
}

/* '' */
.icon-bat-empty:before {
  content: '\eb35';
}

/* '' */
.icon-bat-half:before {
  content: '\eb36';
}

/* '' */
.icon-bat-full:before {
  content: '\eb37';
}

/* '' */
.icon-bat-charge-1:before {
  content: '\eb38';
}

/* '' */
.icon-mobile-3:before {
  content: '\eb39';
}

/* '' */
.icon-cd-1:before {
  content: '\eb3a';
}

/* '' */
.icon-equalizer:before {
  content: '\eb3b';
}

/* '' */
.icon-cursor:before {
  content: '\eb3c';
}

/* '' */
.icon-aperture:before {
  content: '\eb3d';
}

/* '' */
.icon-aperture-alt:before {
  content: '\eb3e';
}

/* '' */
.icon-steering-wheel:before {
  content: '\eb3f';
}

/* '' */
.icon-book-2:before {
  content: '\eb40';
}

/* '' */
.icon-book-alt:before {
  content: '\eb41';
}

/* '' */
.icon-brush-3:before {
  content: '\eb42';
}

/* '' */
.icon-brush-alt:before {
  content: '\eb43';
}

/* '' */
.icon-eyedropper-1:before {
  content: '\eb44';
}

/* '' */
.icon-layers:before {
  content: '\eb45';
}

/* '' */
.icon-layers-alt:before {
  content: '\eb46';
}

/* '' */
.icon-sun-2:before {
  content: '\eb47';
}

/* '' */
.icon-sun-inv:before {
  content: '\eb48';
}

/* '' */
.icon-cloud-3:before {
  content: '\eb49';
}

/* '' */
.icon-rain-1:before {
  content: '\eb4a';
}

/* '' */
.icon-flash-3:before {
  content: '\eb4b';
}

/* '' */
.icon-moon-3:before {
  content: '\eb4c';
}

/* '' */
.icon-moon-inv:before {
  content: '\eb4d';
}

/* '' */
.icon-umbrella-1:before {
  content: '\eb4e';
}

/* '' */
.icon-chart-bar-3:before {
  content: '\eb4f';
}

/* '' */
.icon-chart-pie-3:before {
  content: '\eb50';
}

/* '' */
.icon-chart-pie-alt:before {
  content: '\eb51';
}

/* '' */
.icon-key-3:before {
  content: '\eb52';
}

/* '' */
.icon-key-inv:before {
  content: '\eb53';
}

/* '' */
.icon-hash:before {
  content: '\eb54';
}

/* '' */
.icon-at-2:before {
  content: '\eb55';
}

/* '' */
.icon-pilcrow:before {
  content: '\eb56';
}

/* '' */
.icon-dial:before {
  content: '\eb57';
}

/* '' */
.icon-search-4:before {
  content: '\eb58';
}

/* '' */
.icon-mail-4:before {
  content: '\eb59';
}

/* '' */
.icon-heart-4:before {
  content: '\eb5a';
}

/* '' */
.icon-star-4:before {
  content: '\eb5b';
}

/* '' */
.icon-user-4:before {
  content: '\eb5c';
}

/* '' */
.icon-user-woman:before {
  content: '\eb5d';
}

/* '' */
.icon-user-pair:before {
  content: '\eb5e';
}

/* '' */
.icon-video-alt:before {
  content: '\eb5f';
}

/* '' */
.icon-videocam-2:before {
  content: '\eb60';
}

/* '' */
.icon-videocam-alt:before {
  content: '\eb61';
}

/* '' */
.icon-camera-4:before {
  content: '\eb62';
}

/* '' */
.icon-th-2:before {
  content: '\eb63';
}

/* '' */
.icon-th-list-2:before {
  content: '\eb64';
}

/* '' */
.icon-ok-3:before {
  content: '\eb65';
}

/* '' */
.icon-cancel-4:before {
  content: '\eb66';
}

/* '' */
.icon-cancel-circle-1:before {
  content: '\eb67';
}

/* '' */
.icon-plus-4:before {
  content: '\eb68';
}

/* '' */
.icon-home-4:before {
  content: '\eb69';
}

/* '' */
.icon-lock-4:before {
  content: '\eb6a';
}

/* '' */
.icon-lock-open-4:before {
  content: '\eb6b';
}

/* '' */
.icon-eye-4:before {
  content: '\eb6c';
}

/* '' */
.icon-tag-4:before {
  content: '\eb6d';
}

/* '' */
.icon-thumbs-up-3:before {
  content: '\eb6e';
}

/* '' */
.icon-thumbs-down-3:before {
  content: '\eb6f';
}

/* '' */
.icon-download-4:before {
  content: '\eb70';
}

/* '' */
.icon-export-3:before {
  content: '\eb71';
}

/* '' */
.icon-pencil-4:before {
  content: '\eb72';
}

/* '' */
.icon-pencil-alt-1:before {
  content: '\eb73';
}

/* '' */
.icon-edit-2:before {
  content: '\eb74';
}

/* '' */
.icon-chat-4:before {
  content: '\eb75';
}

/* '' */
.icon-print-3:before {
  content: '\eb76';
}

/* '' */
.icon-bell-3:before {
  content: '\eb77';
}

/* '' */
.icon-attention-3:before {
  content: '\eb78';
}

/* '' */
.icon-info-4:before {
  content: '\eb79';
}

/* '' */
.icon-question:before {
  content: '\eb7a';
}

/* '' */
.icon-location-4:before {
  content: '\eb7b';
}

/* '' */
.icon-trash-4:before {
  content: '\eb7c';
}

/* '' */
.icon-doc-4:before {
  content: '\eb7d';
}

/* '' */
.icon-article-1:before {
  content: '\eb7e';
}

/* '' */
.icon-article-alt-1:before {
  content: '\eb7f';
}

/* '' */
.icon-rss-4:before {
  content: '\eb80';
}

/* '' */
.icon-wrench-3:before {
  content: '\eb81';
}

/* '' */
.icon-basket-3:before {
  content: '\eb82';
}

/* '' */
.icon-basket-alt:before {
  content: '\eb83';
}

/* '' */
.icon-calendar-4:before {
  content: '\eb84';
}

/* '' */
.icon-calendar-alt-1:before {
  content: '\eb85';
}

/* '' */
.icon-volume-off-3:before {
  content: '\eb86';
}

/* '' */
.icon-volume-down-1:before {
  content: '\eb87';
}

/* '' */
.icon-volume-up-2:before {
  content: '\eb88';
}

/* '' */
.icon-bullhorn:before {
  content: '\eb89';
}

/* '' */
.icon-clock-4:before {
  content: '\eb8a';
}

/* '' */
.icon-clock-alt:before {
  content: '\eb8b';
}

/* '' */
.icon-stop-4:before {
  content: '\eb8c';
}

/* '' */
.icon-resize-full-4:before {
  content: '\eb8d';
}

/* '' */
.icon-resize-small-3:before {
  content: '\eb8e';
}

/* '' */
.icon-zoom-in-2:before {
  content: '\eb8f';
}

/* '' */
.icon-zoom-out-2:before {
  content: '\eb90';
}

/* '' */
.icon-popup-4:before {
  content: '\eb91';
}

/* '' */
.icon-down-dir-2:before {
  content: '\eb92';
}

/* '' */
.icon-left-dir-2:before {
  content: '\eb93';
}

/* '' */
.icon-right-dir-2:before {
  content: '\eb94';
}

/* '' */
.icon-up-dir-2:before {
  content: '\eb95';
}

/* '' */
.icon-down-4:before {
  content: '\eb96';
}

/* '' */
.icon-up-4:before {
  content: '\eb97';
}

/* '' */
.icon-cw-4:before {
  content: '\eb98';
}

/* '' */
.icon-signal-3:before {
  content: '\eb99';
}

/* '' */
.icon-award-2:before {
  content: '\eb9a';
}

/* '' */
.icon-mobile-4:before {
  content: '\eb9b';
}

/* '' */
.icon-mobile-alt:before {
  content: '\eb9c';
}

/* '' */
.icon-tablet-2:before {
  content: '\eb9d';
}

/* '' */
.icon-ipod:before {
  content: '\eb9e';
}

/* '' */
.icon-cd-2:before {
  content: '\eb9f';
}

/* '' */
.icon-grid:before {
  content: '\eba0';
}

/* '' */
.icon-book-3:before {
  content: '\eba1';
}

/* '' */
.icon-easel:before {
  content: '\eba2';
}

/* '' */
.icon-globe-3:before {
  content: '\eba3';
}

/* '' */
.icon-chart-1:before {
  content: '\eba4';
}

/* '' */
.icon-chart-bar-4:before {
  content: '\eba5';
}

/* '' */
.icon-chart-pie-4:before {
  content: '\eba6';
}

/* '' */
.icon-dollar-1:before {
  content: '\eba7';
}

/* '' */
.icon-at-3:before {
  content: '\eba8';
}

/* '' */
.icon-colon:before {
  content: '\eba9';
}

/* '' */
.icon-semicolon:before {
  content: '\ebaa';
}

/* '' */
.icon-squares:before {
  content: '\ebab';
}

/* '' */
.icon-money-1:before {
  content: '\ebac';
}

/* '' */
.icon-facebook-3:before {
  content: '\ebad';
}

/* '' */
.icon-facebook-rect:before {
  content: '\ebae';
}

/* '' */
.icon-twitter-bird:before {
  content: '\ebaf';
}

/* '' */
.icon-youtube-1:before {
  content: '\ebb0';
}

/* '' */
.icon-windy-rain-inv:before {
  content: '\ebb1';
}

/* '' */
.icon-snow-inv:before {
  content: '\ebb2';
}

/* '' */
.icon-snow-heavy-inv:before {
  content: '\ebb3';
}

/* '' */
.icon-hail-inv:before {
  content: '\ebb4';
}

/* '' */
.icon-clouds-inv:before {
  content: '\ebb5';
}

/* '' */
.icon-clouds-flash-inv:before {
  content: '\ebb6';
}

/* '' */
.icon-temperature:before {
  content: '\ebb7';
}

/* '' */
.icon-compass-4:before {
  content: '\ebb8';
}

/* '' */
.icon-na:before {
  content: '\ebb9';
}

/* '' */
.icon-celcius:before {
  content: '\ebba';
}

/* '' */
.icon-fahrenheit:before {
  content: '\ebbb';
}

/* '' */
.icon-clouds-flash-alt:before {
  content: '\ebbc';
}

/* '' */
.icon-sun-inv-1:before {
  content: '\ebbd';
}

/* '' */
.icon-moon-inv-1:before {
  content: '\ebbe';
}

/* '' */
.icon-cloud-sun-inv:before {
  content: '\ebbf';
}

/* '' */
.icon-cloud-moon-inv:before {
  content: '\ebc0';
}

/* '' */
.icon-cloud-inv:before {
  content: '\ebc1';
}

/* '' */
.icon-cloud-flash-inv:before {
  content: '\ebc2';
}

/* '' */
.icon-drizzle-inv:before {
  content: '\ebc3';
}

/* '' */
.icon-rain-inv:before {
  content: '\ebc4';
}

/* '' */
.icon-windy-inv:before {
  content: '\ebc5';
}

/* '' */
.icon-sunrise:before {
  content: '\ebc6';
}

/* '' */
.icon-sun-3:before {
  content: '\ebc7';
}

/* '' */
.icon-moon-4:before {
  content: '\ebc8';
}

/* '' */
.icon-eclipse:before {
  content: '\ebc9';
}

/* '' */
.icon-mist:before {
  content: '\ebca';
}

/* '' */
.icon-wind-1:before {
  content: '\ebcb';
}

/* '' */
.icon-snowflake:before {
  content: '\ebcc';
}

/* '' */
.icon-cloud-sun-1:before {
  content: '\ebcd';
}

/* '' */
.icon-cloud-moon:before {
  content: '\ebce';
}

/* '' */
.icon-fog-sun:before {
  content: '\ebcf';
}

/* '' */
.icon-fog-moon:before {
  content: '\ebd0';
}

/* '' */
.icon-fog-cloud:before {
  content: '\ebd1';
}

/* '' */
.icon-fog:before {
  content: '\ebd2';
}

/* '' */
.icon-cloud-4:before {
  content: '\ebd3';
}

/* '' */
.icon-cloud-flash-1:before {
  content: '\ebd4';
}

/* '' */
.icon-cloud-flash-alt:before {
  content: '\ebd5';
}

/* '' */
.icon-drizzle-1:before {
  content: '\ebd6';
}

/* '' */
.icon-rain-2:before {
  content: '\ebd7';
}

/* '' */
.icon-windy:before {
  content: '\ebd8';
}

/* '' */
.icon-windy-rain:before {
  content: '\ebd9';
}

/* '' */
.icon-snow-1:before {
  content: '\ebda';
}

/* '' */
.icon-snow-alt:before {
  content: '\ebdb';
}

/* '' */
.icon-snow-heavy:before {
  content: '\ebdc';
}

/* '' */
.icon-hail:before {
  content: '\ebdd';
}

/* '' */
.icon-clouds:before {
  content: '\ebde';
}

/* '' */
.icon-clouds-flash:before {
  content: '\ebdf';
}

/* '' */
.icon-mail-5:before {
  content: '\ebe0';
}

/* '' */
.icon-heart-5:before {
  content: '\ebe1';
}

/* '' */
.icon-star-5:before {
  content: '\ebe2';
}

/* '' */
.icon-star-empty-2:before {
  content: '\ebe3';
}

/* '' */
.icon-ok-4:before {
  content: '\ebe4';
}

/* '' */
.icon-cancel-5:before {
  content: '\ebe5';
}

/* '' */
.icon-plus-5:before {
  content: '\ebe6';
}

/* '' */
.icon-help-circled-2:before {
  content: '\ebe7';
}

/* '' */
.icon-help-circled-alt:before {
  content: '\ebe8';
}

/* '' */
.icon-home-5:before {
  content: '\ebe9';
}

/* '' */
.icon-pencil-5:before {
  content: '\ebea';
}

/* '' */
.icon-attention-4:before {
  content: '\ebeb';
}

/* '' */
.icon-attention-alt-1:before {
  content: '\ebec';
}

/* '' */
.icon-cog-4:before {
  content: '\ebed';
}

/* '' */
.icon-mic-off:before {
  content: '\ebee';
}

/* '' */
.icon-clock-5:before {
  content: '\ebef';
}

/* '' */
.icon-stopwatch-1:before {
  content: '\ebf0';
}

/* '' */
.icon-hourglass-5:before {
  content: '\ebf1';
}

/* '' */
.icon-down-5:before {
  content: '\ebf2';
}

/* '' */
.icon-left-4:before {
  content: '\ebf3';
}

/* '' */
.icon-right-4:before {
  content: '\ebf4';
}

/* '' */
.icon-up-5:before {
  content: '\ebf5';
}

/* '' */
.icon-down-bold-1:before {
  content: '\ebf6';
}

/* '' */
.icon-left-bold-1:before {
  content: '\ebf7';
}

/* '' */
.icon-right-bold-1:before {
  content: '\ebf8';
}

/* '' */
.icon-up-bold-1:before {
  content: '\ebf9';
}

/* '' */
.icon-down-fat:before {
  content: '\ebfa';
}

/* '' */
.icon-left-fat:before {
  content: '\ebfb';
}

/* '' */
.icon-right-fat:before {
  content: '\ebfc';
}

/* '' */
.icon-up-fat:before {
  content: '\ebfd';
}

/* '' */
.icon-flash-4:before {
  content: '\ebfe';
}

/* '' */
.icon-cloud-5:before {
  content: '\ebff';
}

/* '' */
.icon-vector-pencil:before {
  content: '\ec00';
}

/* '' */
.icon-at-4:before {
  content: '\ec01';
}

/* '' */
.icon-female-1:before {
  content: '\ec02';
}

/* '' */
.icon-male-1:before {
  content: '\ec03';
}

/* '' */
.icon-king:before {
  content: '\ec04';
}

/* '' */
.icon-anchor-2:before {
  content: '\ec05';
}

/* '' */
.icon-aboveground-rail:before {
  content: '\ec06';
}

/* '' */
.icon-airfield:before {
  content: '\ec07';
}

/* '' */
.icon-airport:before {
  content: '\ec08';
}

/* '' */
.icon-art-gallery:before {
  content: '\ec09';
}

/* '' */
.icon-bar:before {
  content: '\ec0a';
}

/* '' */
.icon-baseball:before {
  content: '\ec0b';
}

/* '' */
.icon-basketball:before {
  content: '\ec0c';
}

/* '' */
.icon-beer-2:before {
  content: '\ec0d';
}

/* '' */
.icon-belowground-rail:before {
  content: '\ec0e';
}

/* '' */
.icon-bicycle-1:before {
  content: '\ec0f';
}

/* '' */
.icon-bus-1:before {
  content: '\ec10';
}

/* '' */
.icon-cafe:before {
  content: '\ec11';
}

/* '' */
.icon-campsite:before {
  content: '\ec12';
}

/* '' */
.icon-cemetery:before {
  content: '\ec13';
}

/* '' */
.icon-cinema:before {
  content: '\ec14';
}

/* '' */
.icon-college:before {
  content: '\ec15';
}

/* '' */
.icon-commerical-building:before {
  content: '\ec16';
}

/* '' */
.icon-credit-card-3:before {
  content: '\ec17';
}

/* '' */
.icon-cricket:before {
  content: '\ec18';
}

/* '' */
.icon-embassy:before {
  content: '\ec19';
}

/* '' */
.icon-fast-food:before {
  content: '\ec1a';
}

/* '' */
.icon-ferry:before {
  content: '\ec1b';
}

/* '' */
.icon-fire-station:before {
  content: '\ec1c';
}

/* '' */
.icon-football:before {
  content: '\ec1d';
}

/* '' */
.icon-fuel:before {
  content: '\ec1e';
}

/* '' */
.icon-garden:before {
  content: '\ec1f';
}

/* '' */
.icon-giraffe:before {
  content: '\ec20';
}

/* '' */
.icon-golf:before {
  content: '\ec21';
}

/* '' */
.icon-grocery-store:before {
  content: '\ec22';
}

/* '' */
.icon-harbor:before {
  content: '\ec23';
}

/* '' */
.icon-heliport:before {
  content: '\ec24';
}

/* '' */
.icon-hospital-1:before {
  content: '\ec25';
}

/* '' */
.icon-industrial-building:before {
  content: '\ec26';
}

/* '' */
.icon-library:before {
  content: '\ec27';
}

/* '' */
.icon-lodging:before {
  content: '\ec28';
}

/* '' */
.icon-london-underground:before {
  content: '\ec29';
}

/* '' */
.icon-minefield:before {
  content: '\ec2a';
}

/* '' */
.icon-monument:before {
  content: '\ec2b';
}

/* '' */
.icon-museum:before {
  content: '\ec2c';
}

/* '' */
.icon-pharmacy:before {
  content: '\ec2d';
}

/* '' */
.icon-pitch:before {
  content: '\ec2e';
}

/* '' */
.icon-police:before {
  content: '\ec2f';
}

/* '' */
.icon-post:before {
  content: '\ec30';
}

/* '' */
.icon-prison:before {
  content: '\ec31';
}

/* '' */
.icon-rail:before {
  content: '\ec32';
}

/* '' */
.icon-religious-christian:before {
  content: '\ec33';
}

/* '' */
.icon-religious-islam:before {
  content: '\ec34';
}

/* '' */
.icon-religious-jewish:before {
  content: '\ec35';
}

/* '' */
.icon-restaurant:before {
  content: '\ec36';
}

/* '' */
.icon-roadblock:before {
  content: '\ec37';
}

/* '' */
.icon-school:before {
  content: '\ec38';
}

/* '' */
.icon-shop:before {
  content: '\ec39';
}

/* '' */
.icon-skiing:before {
  content: '\ec3a';
}

/* '' */
.icon-soccer:before {
  content: '\ec3b';
}

/* '' */
.icon-swimming:before {
  content: '\ec3c';
}

/* '' */
.icon-tennis:before {
  content: '\ec3d';
}

/* '' */
.icon-theatre:before {
  content: '\ec3e';
}

/* '' */
.icon-toilet:before {
  content: '\ec3f';
}

/* '' */
.icon-town-hall:before {
  content: '\ec40';
}

/* '' */
.icon-trash-6:before {
  content: '\ec41';
}

/* '' */
.icon-tree-2:before {
  content: '\ec42';
}

/* '' */
.icon-tree-3:before {
  content: '\ec43';
}

/* '' */
.icon-warehouse:before {
  content: '\ec44';
}

/* '' */
.icon-glass:before {
  content: '\ec45';
}

/* '' */
.icon-right-open-3:before {
  content: '\ec46';
}

/* '' */
.icon-duckduckgo:before {
  content: '\ec47';
}

/* '' */
.icon-aim:before {
  content: '\ec48';
}

/* '' */
.icon-delicious-1:before {
  content: '\ec49';
}

/* '' */
.icon-paypal-2:before {
  content: '\ec4a';
}

/* '' */
.icon-flattr-1:before {
  content: '\ec4b';
}

/* '' */
.icon-android-1:before {
  content: '\ec4c';
}

/* '' */
.icon-eventful:before {
  content: '\ec4d';
}

/* '' */
.icon-smashmag:before {
  content: '\ec4e';
}

/* '' */
.icon-gplus-3:before {
  content: '\ec4f';
}

/* '' */
.icon-wikipedia:before {
  content: '\ec50';
}

/* '' */
.icon-lanyrd:before {
  content: '\ec51';
}

/* '' */
.icon-calendar-6:before {
  content: '\ec52';
}

/* '' */
.icon-stumbleupon-2:before {
  content: '\ec53';
}

/* '' */
.icon-fivehundredpx:before {
  content: '\ec54';
}

/* '' */
.icon-pinterest-3:before {
  content: '\ec55';
}

/* '' */
.icon-bitcoin-1:before {
  content: '\ec56';
}

/* '' */
.icon-w3c:before {
  content: '\ec57';
}

/* '' */
.icon-foursquare-1:before {
  content: '\ec58';
}

/* '' */
.icon-html5-1:before {
  content: '\ec59';
}

/* '' */
.icon-ie:before {
  content: '\ec5a';
}

/* '' */
.icon-call:before {
  content: '\ec5b';
}

/* '' */
.icon-grooveshark:before {
  content: '\ec5c';
}

/* '' */
.icon-ninetyninedesigns:before {
  content: '\ec5d';
}

/* '' */
.icon-forrst:before {
  content: '\ec5e';
}

/* '' */
.icon-digg-1:before {
  content: '\ec5f';
}

/* '' */
.icon-spotify-2:before {
  content: '\ec60';
}

/* '' */
.icon-reddit-1:before {
  content: '\ec61';
}

/* '' */
.icon-guest:before {
  content: '\ec62';
}

/* '' */
.icon-gowalla:before {
  content: '\ec63';
}

/* '' */
.icon-appstore:before {
  content: '\ec64';
}

/* '' */
.icon-blogger:before {
  content: '\ec65';
}

/* '' */
.icon-cc-2:before {
  content: '\ec66';
}

/* '' */
.icon-dribbble-4:before {
  content: '\ec67';
}

/* '' */
.icon-evernote-1:before {
  content: '\ec68';
}

/* '' */
.icon-flickr-3:before {
  content: '\ec69';
}

/* '' */
.icon-google-1:before {
  content: '\ec6a';
}

/* '' */
.icon-viadeo-1:before {
  content: '\ec6b';
}

/* '' */
.icon-instapaper:before {
  content: '\ec6c';
}

/* '' */
.icon-weibo-1:before {
  content: '\ec6d';
}

/* '' */
.icon-klout:before {
  content: '\ec6e';
}

/* '' */
.icon-linkedin-4:before {
  content: '\ec6f';
}

/* '' */
.icon-meetup-1:before {
  content: '\ec70';
}

/* '' */
.icon-vk:before {
  content: '\ec71';
}

/* '' */
.icon-plancast:before {
  content: '\ec72';
}

/* '' */
.icon-disqus:before {
  content: '\ec73';
}

/* '' */
.icon-rss-5:before {
  content: '\ec74';
}

/* '' */
.icon-skype-3:before {
  content: '\ec75';
}

/* '' */
.icon-twitter-5:before {
  content: '\ec76';
}

/* '' */
.icon-youtube-2:before {
  content: '\ec77';
}

/* '' */
.icon-vimeo-3:before {
  content: '\ec78';
}

/* '' */
.icon-windows-1:before {
  content: '\ec79';
}

/* '' */
.icon-xing-1:before {
  content: '\ec7a';
}

/* '' */
.icon-yahoo-1:before {
  content: '\ec7b';
}

/* '' */
.icon-chrome-1:before {
  content: '\ec7c';
}

/* '' */
.icon-email:before {
  content: '\ec7d';
}

/* '' */
.icon-macstore:before {
  content: '\ec7e';
}

/* '' */
.icon-myspace:before {
  content: '\ec7f';
}

/* '' */
.icon-podcast-1:before {
  content: '\ec80';
}

/* '' */
.icon-amazon-1:before {
  content: '\ec81';
}

/* '' */
.icon-steam-1:before {
  content: '\ec82';
}

/* '' */
.icon-cloudapp:before {
  content: '\ec83';
}

/* '' */
.icon-dropbox-2:before {
  content: '\ec84';
}

/* '' */
.icon-ebay:before {
  content: '\ec85';
}

/* '' */
.icon-facebook-5:before {
  content: '\ec86';
}

/* '' */
.icon-github-4:before {
  content: '\ec87';
}

/* '' */
.icon-github-circled-4:before {
  content: '\ec88';
}

/* '' */
.icon-googleplay:before {
  content: '\ec89';
}

/* '' */
.icon-itunes:before {
  content: '\ec8a';
}

/* '' */
.icon-plurk:before {
  content: '\ec8b';
}

/* '' */
.icon-songkick:before {
  content: '\ec8c';
}

/* '' */
.icon-lastfm-3:before {
  content: '\ec8d';
}

/* '' */
.icon-gmail:before {
  content: '\ec8e';
}

/* '' */
.icon-pinboard:before {
  content: '\ec8f';
}

/* '' */
.icon-openid-1:before {
  content: '\ec90';
}

/* '' */
.icon-quora-1:before {
  content: '\ec91';
}

/* '' */
.icon-soundcloud-3:before {
  content: '\ec92';
}

/* '' */
.icon-tumblr-2:before {
  content: '\ec93';
}

/* '' */
.icon-eventasaurus:before {
  content: '\ec94';
}

/* '' */
.icon-wordpress-1:before {
  content: '\ec95';
}

/* '' */
.icon-yelp-1:before {
  content: '\ec96';
}

/* '' */
.icon-intensedebate:before {
  content: '\ec97';
}

/* '' */
.icon-eventbrite:before {
  content: '\ec98';
}

/* '' */
.icon-scribd-1:before {
  content: '\ec99';
}

/* '' */
.icon-posterous:before {
  content: '\ec9a';
}

/* '' */
.icon-stripe:before {
  content: '\ec9b';
}

/* '' */
.icon-opentable:before {
  content: '\ec9c';
}

/* '' */
.icon-cart:before {
  content: '\ec9d';
}

/* '' */
.icon-print-5:before {
  content: '\ec9e';
}

/* '' */
.icon-angellist-1:before {
  content: '\ec9f';
}

/* '' */
.icon-instagram-3:before {
  content: '\eca0';
}

/* '' */
.icon-dwolla:before {
  content: '\eca1';
}

/* '' */
.icon-appnet:before {
  content: '\eca2';
}

/* '' */
.icon-statusnet:before {
  content: '\eca3';
}

/* '' */
.icon-acrobat:before {
  content: '\eca4';
}

/* '' */
.icon-drupal-1:before {
  content: '\eca5';
}

/* '' */
.icon-buffer:before {
  content: '\eca6';
}

/* '' */
.icon-pocket:before {
  content: '\eca7';
}

/* '' */
.icon-bitbucket-1:before {
  content: '\eca8';
}

/* '' */
.icon-lego:before {
  content: '\eca9';
}

/* '' */
.icon-login-3:before {
  content: '\ecaa';
}

/* '' */
.icon-stackoverflow-1:before {
  content: '\ecab';
}

/* '' */
.icon-hackernews:before {
  content: '\ecac';
}

/* '' */
.icon-lkdto:before {
  content: '\ecad';
}

/* '' */
.icon-facebook-6:before {
  content: '\ecae';
}

/* '' */
.icon-facebook-rect-1:before {
  content: '\ecaf';
}

/* '' */
.icon-twitter-6:before {
  content: '\ecb0';
}

/* '' */
.icon-twitter-bird-1:before {
  content: '\ecb1';
}

/* '' */
.icon-vimeo-4:before {
  content: '\ecb2';
}

/* '' */
.icon-vimeo-rect:before {
  content: '\ecb3';
}

/* '' */
.icon-tumblr-rect:before {
  content: '\ecb4';
}

/* '' */
.icon-googleplus-rect:before {
  content: '\ecb5';
}

/* '' */
.icon-github-text:before {
  content: '\ecb6';
}

/* '' */
.icon-github-5:before {
  content: '\ecb7';
}

/* '' */
.icon-icq:before {
  content: '\ecb8';
}

/* '' */
.icon-yandex-rect:before {
  content: '\ecb9';
}

/* '' */
.icon-vkontakte-rect:before {
  content: '\ecba';
}

/* '' */
.icon-odnoklassniki-1:before {
  content: '\ecbb';
}

/* '' */
.icon-odnoklassniki-rect:before {
  content: '\ecbc';
}

/* '' */
.icon-friendfeed:before {
  content: '\ecbd';
}

/* '' */
.icon-friendfeed-rect:before {
  content: '\ecbe';
}

/* '' */
.icon-blogger-1:before {
  content: '\ecbf';
}

/* '' */
.icon-blogger-rect:before {
  content: '\ecc0';
}

/* '' */
.icon-deviantart-1:before {
  content: '\ecc1';
}

/* '' */
.icon-lastfm-4:before {
  content: '\ecc2';
}

/* '' */
.icon-lastfm-rect:before {
  content: '\ecc3';
}

/* '' */
.icon-linkedin-rect:before {
  content: '\ecc4';
}

/* '' */
.icon-picasa-1:before {
  content: '\ecc5';
}

/* '' */
.icon-instagram-4:before {
  content: '\ecc6';
}

/* '' */
.icon-instagram-filled:before {
  content: '\ecc7';
}

/* '' */
.icon-box-4:before {
  content: '\ecc8';
}

/* '' */
.icon-box-rect:before {
  content: '\ecc9';
}

/* '' */
.icon-youku:before {
  content: '\ecca';
}

/* '' */
.icon-win8:before {
  content: '\eccb';
}

/* '' */
.icon-discover:before {
  content: '\eccc';
}

/* '' */
.icon-visa:before {
  content: '\eccd';
}

/* '' */
.icon-houzz-1:before {
  content: '\ecce';
}

/* '' */
.icon-glass-1:before {
  content: '\eccf';
}

/* '' */
.icon-music-3:before {
  content: '\ecd0';
}

/* '' */
.icon-search-6:before {
  content: '\ecd1';
}

/* '' */
.icon-search-circled:before {
  content: '\ecd2';
}

/* '' */
.icon-mail-6:before {
  content: '\ecd3';
}

/* '' */
.icon-mail-circled:before {
  content: '\ecd4';
}

/* '' */
.icon-heart-6:before {
  content: '\ecd5';
}

/* '' */
.icon-heart-circled:before {
  content: '\ecd6';
}

/* '' */
.icon-heart-empty-3:before {
  content: '\ecd7';
}

/* '' */
.icon-star-6:before {
  content: '\ecd8';
}

/* '' */
.icon-star-circled:before {
  content: '\ecd9';
}

/* '' */
.icon-star-empty-3:before {
  content: '\ecda';
}

/* '' */
.icon-user-6:before {
  content: '\ecdb';
}

/* '' */
.icon-group:before {
  content: '\ecdc';
}

/* '' */
.icon-group-circled:before {
  content: '\ecdd';
}

/* '' */
.icon-torso:before {
  content: '\ecde';
}

/* '' */
.icon-video-4:before {
  content: '\ecdf';
}

/* '' */
.icon-video-circled:before {
  content: '\ece0';
}

/* '' */
.icon-video-alt-1:before {
  content: '\ece1';
}

/* '' */
.icon-videocam-4:before {
  content: '\ece2';
}

/* '' */
.icon-video-chat:before {
  content: '\ece3';
}

/* '' */
.icon-picture-4:before {
  content: '\ece4';
}

/* '' */
.icon-camera-6:before {
  content: '\ece5';
}

/* '' */
.icon-photo:before {
  content: '\ece6';
}

/* '' */
.icon-photo-circled:before {
  content: '\ece7';
}

/* '' */
.icon-th-large-2:before {
  content: '\ece8';
}

/* '' */
.icon-th-3:before {
  content: '\ece9';
}

/* '' */
.icon-th-list-4:before {
  content: '\ecea';
}

/* '' */
.icon-view-mode:before {
  content: '\eceb';
}

/* '' */
.icon-ok-5:before {
  content: '\ecec';
}

/* '' */
.icon-ok-circled-2:before {
  content: '\eced';
}

/* '' */
.icon-ok-circled2-1:before {
  content: '\ecee';
}

/* '' */
.icon-cancel-6:before {
  content: '\ecef';
}

/* '' */
.icon-cancel-circled-4:before {
  content: '\ecf0';
}

/* '' */
.icon-cancel-circled2-1:before {
  content: '\ecf1';
}

/* '' */
.icon-plus-6:before {
  content: '\ecf2';
}

/* '' */
.icon-plus-circled-2:before {
  content: '\ecf3';
}

/* '' */
.icon-minus-4:before {
  content: '\ecf4';
}

/* '' */
.icon-minus-circled-2:before {
  content: '\ecf5';
}

/* '' */
.icon-help-3:before {
  content: '\ecf6';
}

/* '' */
.icon-help-circled-3:before {
  content: '\ecf7';
}

/* '' */
.icon-info-circled-3:before {
  content: '\ecf8';
}

/* '' */
.icon-home-6:before {
  content: '\ecf9';
}

/* '' */
.icon-home-circled:before {
  content: '\ecfa';
}

/* '' */
.icon-website:before {
  content: '\ecfb';
}

/* '' */
.icon-website-circled:before {
  content: '\ecfc';
}

/* '' */
.icon-attach-5:before {
  content: '\ecfd';
}

/* '' */
.icon-attach-circled:before {
  content: '\ecfe';
}

/* '' */
.icon-lock-6:before {
  content: '\ecff';
}

/* '' */
.icon-lock-circled:before {
  content: '\ed00';
}

/* '' */
.icon-lock-open-6:before {
  content: '\ed01';
}

/* '' */
.icon-lock-open-alt-2:before {
  content: '\ed02';
}

/* '' */
.icon-eye-6:before {
  content: '\ed03';
}

/* '' */
.icon-eye-off-1:before {
  content: '\ed04';
}

/* '' */
.icon-tag-5:before {
  content: '\ed05';
}

/* '' */
.icon-tags-2:before {
  content: '\ed06';
}

/* '' */
.icon-bookmark-3:before {
  content: '\ed07';
}

/* '' */
.icon-bookmark-empty-1:before {
  content: '\ed08';
}

/* '' */
.icon-flag-3:before {
  content: '\ed09';
}

/* '' */
.icon-flag-circled:before {
  content: '\ed0a';
}

/* '' */
.icon-thumbs-up-4:before {
  content: '\ed0b';
}

/* '' */
.icon-thumbs-down-4:before {
  content: '\ed0c';
}

/* '' */
.icon-download-6:before {
  content: '\ed0d';
}

/* '' */
.icon-download-alt:before {
  content: '\ed0e';
}

/* '' */
.icon-upload-5:before {
  content: '\ed0f';
}

/* '' */
.icon-share-3:before {
  content: '\ed10';
}

/* '' */
.icon-quote-1:before {
  content: '\ed11';
}

/* '' */
.icon-quote-circled:before {
  content: '\ed12';
}

/* '' */
.icon-export-5:before {
  content: '\ed13';
}

/* '' */
.icon-pencil-6:before {
  content: '\ed14';
}

/* '' */
.icon-pencil-circled:before {
  content: '\ed15';
}

/* '' */
.icon-edit-3:before {
  content: '\ed16';
}

/* '' */
.icon-edit-circled:before {
  content: '\ed17';
}

/* '' */
.icon-edit-alt:before {
  content: '\ed18';
}

/* '' */
.icon-print-6:before {
  content: '\ed19';
}

/* '' */
.icon-retweet-3:before {
  content: '\ed1a';
}

/* '' */
.icon-comment-5:before {
  content: '\ed1b';
}

/* '' */
.icon-comment-alt-1:before {
  content: '\ed1c';
}

/* '' */
.icon-bell-5:before {
  content: '\ed1d';
}

/* '' */
.icon-warning-1:before {
  content: '\ed1e';
}

/* '' */
.icon-exclamation:before {
  content: '\ed1f';
}

/* '' */
.icon-error:before {
  content: '\ed20';
}

/* '' */
.icon-error-alt:before {
  content: '\ed21';
}

/* '' */
.icon-location-6:before {
  content: '\ed22';
}

/* '' */
.icon-location-circled:before {
  content: '\ed23';
}

/* '' */
.icon-compass-5:before {
  content: '\ed24';
}

/* '' */
.icon-compass-circled:before {
  content: '\ed25';
}

/* '' */
.icon-trash-7:before {
  content: '\ed26';
}

/* '' */
.icon-trash-circled:before {
  content: '\ed27';
}

/* '' */
.icon-doc-6:before {
  content: '\ed28';
}

/* '' */
.icon-doc-circled:before {
  content: '\ed29';
}

/* '' */
.icon-doc-new:before {
  content: '\ed2a';
}

/* '' */
.icon-doc-new-circled:before {
  content: '\ed2b';
}

/* '' */
.icon-folder-5:before {
  content: '\ed2c';
}

/* '' */
.icon-folder-circled:before {
  content: '\ed2d';
}

/* '' */
.icon-folder-close:before {
  content: '\ed2e';
}

/* '' */
.icon-folder-open-2:before {
  content: '\ed2f';
}

/* '' */
.icon-rss-6:before {
  content: '\ed30';
}

/* '' */
.icon-phone-3:before {
  content: '\ed31';
}

/* '' */
.icon-phone-circled:before {
  content: '\ed32';
}

/* '' */
.icon-cog-5:before {
  content: '\ed33';
}

/* '' */
.icon-cog-circled:before {
  content: '\ed34';
}

/* '' */
.icon-cogs:before {
  content: '\ed35';
}

/* '' */
.icon-wrench-4:before {
  content: '\ed36';
}

/* '' */
.icon-wrench-circled:before {
  content: '\ed37';
}

/* '' */
.icon-basket-4:before {
  content: '\ed38';
}

/* '' */
.icon-basket-circled:before {
  content: '\ed39';
}

/* '' */
.icon-calendar-7:before {
  content: '\ed3a';
}

/* '' */
.icon-calendar-circled:before {
  content: '\ed3b';
}

/* '' */
.icon-mic-5:before {
  content: '\ed3c';
}

/* '' */
.icon-mic-circled:before {
  content: '\ed3d';
}

/* '' */
.icon-volume-off-4:before {
  content: '\ed3e';
}

/* '' */
.icon-volume-down-2:before {
  content: '\ed3f';
}

/* '' */
.icon-volume-1:before {
  content: '\ed40';
}

/* '' */
.icon-volume-up-3:before {
  content: '\ed41';
}

/* '' */
.icon-headphones-3:before {
  content: '\ed42';
}

/* '' */
.icon-clock-6:before {
  content: '\ed43';
}

/* '' */
.icon-clock-circled:before {
  content: '\ed44';
}

/* '' */
.icon-lightbulb-2:before {
  content: '\ed45';
}

/* '' */
.icon-lightbulb-alt:before {
  content: '\ed46';
}

/* '' */
.icon-block-4:before {
  content: '\ed47';
}

/* '' */
.icon-resize-full-5:before {
  content: '\ed48';
}

/* '' */
.icon-resize-full-alt-2:before {
  content: '\ed49';
}

/* '' */
.icon-resize-small-4:before {
  content: '\ed4a';
}

/* '' */
.icon-resize-vertical-2:before {
  content: '\ed4b';
}

/* '' */
.icon-resize-horizontal-2:before {
  content: '\ed4c';
}

/* '' */
.icon-move-3:before {
  content: '\ed4d';
}

/* '' */
.icon-zoom-in-4:before {
  content: '\ed4e';
}

/* '' */
.icon-zoom-out-4:before {
  content: '\ed4f';
}

/* '' */
.icon-down-open-3:before {
  content: '\ed50';
}

/* '' */
.icon-left-open-4:before {
  content: '\ed51';
}

/* '' */
.icon-right-open-5:before {
  content: '\ed52';
}

/* '' */
.icon-up-open-3:before {
  content: '\ed53';
}

/* '' */
.icon-down-6:before {
  content: '\ed54';
}

/* '' */
.icon-left-5:before {
  content: '\ed55';
}

/* '' */
.icon-right-5:before {
  content: '\ed56';
}

/* '' */
.icon-up-6:before {
  content: '\ed57';
}

/* '' */
.icon-down-circled-2:before {
  content: '\ed58';
}

/* '' */
.icon-left-circled-2:before {
  content: '\ed59';
}

/* '' */
.icon-right-circled-2:before {
  content: '\ed5a';
}

/* '' */
.icon-up-circled-2:before {
  content: '\ed5b';
}

/* '' */
.icon-down-hand-1:before {
  content: '\ed5c';
}

/* '' */
.icon-left-hand-1:before {
  content: '\ed5d';
}

/* '' */
.icon-right-hand-1:before {
  content: '\ed5e';
}

/* '' */
.icon-up-hand-1:before {
  content: '\ed5f';
}

/* '' */
.icon-cw-5:before {
  content: '\ed60';
}

/* '' */
.icon-cw-circled:before {
  content: '\ed61';
}

/* '' */
.icon-arrows-cw-2:before {
  content: '\ed62';
}

/* '' */
.icon-shuffle-4:before {
  content: '\ed63';
}

/* '' */
.icon-play-5:before {
  content: '\ed64';
}

/* '' */
.icon-play-circled-1:before {
  content: '\ed65';
}

/* '' */
.icon-play-circled2-1:before {
  content: '\ed66';
}

/* '' */
.icon-stop-6:before {
  content: '\ed67';
}

/* '' */
.icon-stop-circled:before {
  content: '\ed68';
}

/* '' */
.icon-pause-5:before {
  content: '\ed69';
}

/* '' */
.icon-pause-circled:before {
  content: '\ed6a';
}

/* '' */
.icon-record-2:before {
  content: '\ed6b';
}

/* '' */
.icon-eject-3:before {
  content: '\ed6c';
}

/* '' */
.icon-backward:before {
  content: '\ed6d';
}

/* '' */
.icon-backward-circled:before {
  content: '\ed6e';
}

/* '' */
.icon-fast-backward-2:before {
  content: '\ed6f';
}

/* '' */
.icon-fast-forward-2:before {
  content: '\ed70';
}

/* '' */
.icon-forward-3:before {
  content: '\ed71';
}

/* '' */
.icon-forward-circled:before {
  content: '\ed72';
}

/* '' */
.icon-step-backward:before {
  content: '\ed73';
}

/* '' */
.icon-step-forward:before {
  content: '\ed74';
}

/* '' */
.icon-target-4:before {
  content: '\ed75';
}

/* '' */
.icon-signal-4:before {
  content: '\ed76';
}

/* '' */
.icon-desktop-2:before {
  content: '\ed77';
}

/* '' */
.icon-desktop-circled:before {
  content: '\ed78';
}

/* '' */
.icon-laptop-2:before {
  content: '\ed79';
}

/* '' */
.icon-laptop-circled:before {
  content: '\ed7a';
}

/* '' */
.icon-network-1:before {
  content: '\ed7b';
}

/* '' */
.icon-inbox-3:before {
  content: '\ed7c';
}

/* '' */
.icon-inbox-circled:before {
  content: '\ed7d';
}

/* '' */
.icon-inbox-alt:before {
  content: '\ed7e';
}

/* '' */
.icon-globe-5:before {
  content: '\ed7f';
}

/* '' */
.icon-globe-alt-1:before {
  content: '\ed80';
}

/* '' */
.icon-cloud-6:before {
  content: '\ed81';
}

/* '' */
.icon-cloud-circled:before {
  content: '\ed82';
}

/* '' */
.icon-flight-2:before {
  content: '\ed83';
}

/* '' */
.icon-leaf-3:before {
  content: '\ed84';
}

/* '' */
.icon-font-1:before {
  content: '\ed85';
}

/* '' */
.icon-fontsize-1:before {
  content: '\ed86';
}

/* '' */
.icon-bold-1:before {
  content: '\ed87';
}

/* '' */
.icon-italic-1:before {
  content: '\ed88';
}

/* '' */
.icon-text-height-1:before {
  content: '\ed89';
}

/* '' */
.icon-text-width-1:before {
  content: '\ed8a';
}

/* '' */
.icon-align-left-1:before {
  content: '\ed8b';
}

/* '' */
.icon-align-center-1:before {
  content: '\ed8c';
}

/* '' */
.icon-align-right-1:before {
  content: '\ed8d';
}

/* '' */
.icon-align-justify-1:before {
  content: '\ed8e';
}

/* '' */
.icon-list-3:before {
  content: '\ed8f';
}

/* '' */
.icon-indent-left-1:before {
  content: '\ed90';
}

/* '' */
.icon-indent-right-1:before {
  content: '\ed91';
}

/* '' */
.icon-briefcase-3:before {
  content: '\ed92';
}

/* '' */
.icon-off-1:before {
  content: '\ed93';
}

/* '' */
.icon-road-1:before {
  content: '\ed94';
}

/* '' */
.icon-qrcode-1:before {
  content: '\ed95';
}

/* '' */
.icon-barcode-1:before {
  content: '\ed96';
}

/* '' */
.icon-braille-1:before {
  content: '\ed97';
}

/* '' */
.icon-book-4:before {
  content: '\ed98';
}

/* '' */
.icon-adjust-2:before {
  content: '\ed99';
}

/* '' */
.icon-tint-1:before {
  content: '\ed9a';
}

/* '' */
.icon-check-3:before {
  content: '\ed9b';
}

/* '' */
.icon-check-empty-1:before {
  content: '\ed9c';
}

/* '' */
.icon-asterisk-1:before {
  content: '\ed9d';
}

/* '' */
.icon-gift-2:before {
  content: '\ed9e';
}

/* '' */
.icon-fire-2:before {
  content: '\ed9f';
}

/* '' */
.icon-magnet-2:before {
  content: '\eda0';
}

/* '' */
.icon-chart-2:before {
  content: '\eda1';
}

/* '' */
.icon-chart-circled:before {
  content: '\eda2';
}

/* '' */
.icon-credit-card-4:before {
  content: '\eda3';
}

/* '' */
.icon-megaphone-2:before {
  content: '\eda4';
}

/* '' */
.icon-clipboard-2:before {
  content: '\eda5';
}

/* '' */
.icon-hdd-2:before {
  content: '\eda6';
}

/* '' */
.icon-key-4:before {
  content: '\eda7';
}

/* '' */
.icon-certificate-2:before {
  content: '\eda8';
}

/* '' */
.icon-tasks-1:before {
  content: '\eda9';
}

/* '' */
.icon-filter-1:before {
  content: '\edaa';
}

/* '' */
.icon-gauge-2:before {
  content: '\edab';
}

/* '' */
.icon-smiley:before {
  content: '\edac';
}

/* '' */
.icon-smiley-circled:before {
  content: '\edad';
}

/* '' */
.icon-address-book-1:before {
  content: '\edae';
}

/* '' */
.icon-address-book-alt:before {
  content: '\edaf';
}

/* '' */
.icon-asl:before {
  content: '\edb0';
}

/* '' */
.icon-glasses:before {
  content: '\edb1';
}

/* '' */
.icon-hearing-impaired:before {
  content: '\edb2';
}

/* '' */
.icon-iphone-home:before {
  content: '\edb3';
}

/* '' */
.icon-person:before {
  content: '\edb4';
}

/* '' */
.icon-adult:before {
  content: '\edb5';
}

/* '' */
.icon-child-1:before {
  content: '\edb6';
}

/* '' */
.icon-blind-1:before {
  content: '\edb7';
}

/* '' */
.icon-guidedog:before {
  content: '\edb8';
}

/* '' */
.icon-accessibility:before {
  content: '\edb9';
}

/* '' */
.icon-universal-access-1:before {
  content: '\edba';
}

/* '' */
.icon-male-2:before {
  content: '\edbb';
}

/* '' */
.icon-female-2:before {
  content: '\edbc';
}

/* '' */
.icon-behance-2:before {
  content: '\edbd';
}

/* '' */
.icon-blogger-2:before {
  content: '\edbe';
}

/* '' */
.icon-cc-3:before {
  content: '\edbf';
}

/* '' */
.icon-css:before {
  content: '\edc0';
}

/* '' */
.icon-delicious-2:before {
  content: '\edc1';
}

/* '' */
.icon-deviantart-2:before {
  content: '\edc2';
}

/* '' */
.icon-digg-2:before {
  content: '\edc3';
}

/* '' */
.icon-dribbble-5:before {
  content: '\edc4';
}

/* '' */
.icon-facebook-7:before {
  content: '\edc5';
}

/* '' */
.icon-flickr-4:before {
  content: '\edc6';
}

/* '' */
.icon-foursquare-2:before {
  content: '\edc7';
}

/* '' */
.icon-friendfeed-1:before {
  content: '\edc8';
}

/* '' */
.icon-friendfeed-rect-1:before {
  content: '\edc9';
}

/* '' */
.icon-github-6:before {
  content: '\edca';
}

/* '' */
.icon-github-text-1:before {
  content: '\edcb';
}

/* '' */
.icon-googleplus:before {
  content: '\edcc';
}

/* '' */
.icon-instagram-5:before {
  content: '\edcd';
}

/* '' */
.icon-linkedin-6:before {
  content: '\edce';
}

/* '' */
.icon-path:before {
  content: '\edcf';
}

/* '' */
.icon-picasa-2:before {
  content: '\edd0';
}

/* '' */
.icon-pinterest-4:before {
  content: '\edd1';
}

/* '' */
.icon-reddit-2:before {
  content: '\edd2';
}

/* '' */
.icon-skype-5:before {
  content: '\edd3';
}

/* '' */
.icon-slideshare-1:before {
  content: '\edd4';
}

/* '' */
.icon-stackoverflow-2:before {
  content: '\edd5';
}

/* '' */
.icon-stumbleupon-3:before {
  content: '\edd6';
}

/* '' */
.icon-twitter-7:before {
  content: '\edd7';
}

/* '' */
.icon-tumblr-4:before {
  content: '\edd8';
}

/* '' */
.icon-vimeo-5:before {
  content: '\edd9';
}

/* '' */
.icon-vkontakte-2:before {
  content: '\edda';
}

/* '' */
.icon-w3c-1:before {
  content: '\eddb';
}

/* '' */
.icon-music-4:before {
  content: '\eddc';
}

/* '' */
.icon-search-7:before {
  content: '\eddd';
}

/* '' */
.icon-mail-7:before {
  content: '\edde';
}

/* '' */
.icon-heart-7:before {
  content: '\eddf';
}

/* '' */
.icon-star-7:before {
  content: '\ede0';
}

/* '' */
.icon-user-7:before {
  content: '\ede1';
}

/* '' */
.icon-videocam-5:before {
  content: '\ede2';
}

/* '' */
.icon-camera-7:before {
  content: '\ede3';
}

/* '' */
.icon-photo-1:before {
  content: '\ede4';
}

/* '' */
.icon-attach-6:before {
  content: '\ede5';
}

/* '' */
.icon-lock-7:before {
  content: '\ede6';
}

/* '' */
.icon-eye-7:before {
  content: '\ede7';
}

/* '' */
.icon-tag-6:before {
  content: '\ede8';
}

/* '' */
.icon-thumbs-up-5:before {
  content: '\ede9';
}

/* '' */
.icon-pencil-7:before {
  content: '\edea';
}

/* '' */
.icon-comment-6:before {
  content: '\edeb';
}

/* '' */
.icon-location-7:before {
  content: '\edec';
}

/* '' */
.icon-cup-1:before {
  content: '\eded';
}

/* '' */
.icon-trash-8:before {
  content: '\edee';
}

/* '' */
.icon-doc-7:before {
  content: '\edef';
}

/* '' */
.icon-note-1:before {
  content: '\edf0';
}

/* '' */
.icon-cog-6:before {
  content: '\edf1';
}

/* '' */
.icon-params:before {
  content: '\edf2';
}

/* '' */
.icon-calendar-8:before {
  content: '\edf3';
}

/* '' */
.icon-sound-1:before {
  content: '\edf4';
}

/* '' */
.icon-clock-7:before {
  content: '\edf5';
}

/* '' */
.icon-lightbulb-3:before {
  content: '\edf6';
}

/* '' */
.icon-tv:before {
  content: '\edf7';
}

/* '' */
.icon-desktop-3:before {
  content: '\edf8';
}

/* '' */
.icon-mobile-6:before {
  content: '\edf9';
}

/* '' */
.icon-cd-3:before {
  content: '\edfa';
}

/* '' */
.icon-inbox-4:before {
  content: '\edfb';
}

/* '' */
.icon-globe-6:before {
  content: '\edfc';
}

/* '' */
.icon-cloud-7:before {
  content: '\edfd';
}

/* '' */
.icon-paper-plane-3:before {
  content: '\edfe';
}

/* '' */
.icon-fire-3:before {
  content: '\edff';
}

/* '' */
.icon-graduation-cap-2:before {
  content: '\ee00';
}

/* '' */
.icon-megaphone-3:before {
  content: '\ee01';
}

/* '' */
.icon-database-3:before {
  content: '\ee02';
}

/* '' */
.icon-key-5:before {
  content: '\ee03';
}

/* '' */
.icon-beaker-1:before {
  content: '\ee04';
}

/* '' */
.icon-truck-1:before {
  content: '\ee05';
}

/* '' */
.icon-money-2:before {
  content: '\ee06';
}

/* '' */
.icon-food-1:before {
  content: '\ee07';
}

/* '' */
.icon-shop-1:before {
  content: '\ee08';
}

/* '' */
.icon-diamond-1:before {
  content: '\ee09';
}

/* '' */
.icon-t-shirt:before {
  content: '\ee0a';
}

/* '' */
.icon-wallet:before {
  content: '\ee0b';
}

/* '' */
.icon-search-8:before {
  content: '\ee0c';
}

/* '' */
.icon-mail-8:before {
  content: '\ee0d';
}

/* '' */
.icon-heart-8:before {
  content: '\ee0e';
}

/* '' */
.icon-heart-empty-4:before {
  content: '\ee0f';
}

/* '' */
.icon-star-8:before {
  content: '\ee10';
}

/* '' */
.icon-user-8:before {
  content: '\ee11';
}

/* '' */
.icon-video-5:before {
  content: '\ee12';
}

/* '' */
.icon-picture-5:before {
  content: '\ee13';
}

/* '' */
.icon-th-large-3:before {
  content: '\ee14';
}

/* '' */
.icon-th-4:before {
  content: '\ee15';
}

/* '' */
.icon-th-list-5:before {
  content: '\ee16';
}

/* '' */
.icon-ok-6:before {
  content: '\ee17';
}

/* '' */
.icon-ok-circle-1:before {
  content: '\ee18';
}

/* '' */
.icon-cancel-7:before {
  content: '\ee19';
}

/* '' */
.icon-cancel-circle-2:before {
  content: '\ee1a';
}

/* '' */
.icon-plus-circle-1:before {
  content: '\ee1b';
}

/* '' */
.icon-minus-circle-1:before {
  content: '\ee1c';
}

/* '' */
.icon-link-5:before {
  content: '\ee1d';
}

/* '' */
.icon-attach-7:before {
  content: '\ee1e';
}

/* '' */
.icon-lock-8:before {
  content: '\ee1f';
}

/* '' */
.icon-lock-open-7:before {
  content: '\ee20';
}

/* '' */
.icon-tag-7:before {
  content: '\ee21';
}

/* '' */
.icon-reply-4:before {
  content: '\ee22';
}

/* '' */
.icon-reply-all-2:before {
  content: '\ee23';
}

/* '' */
.icon-forward-4:before {
  content: '\ee24';
}

/* '' */
.icon-code-3:before {
  content: '\ee25';
}

/* '' */
.icon-retweet-4:before {
  content: '\ee26';
}

/* '' */
.icon-comment-7:before {
  content: '\ee27';
}

/* '' */
.icon-comment-alt-2:before {
  content: '\ee28';
}

/* '' */
.icon-chat-6:before {
  content: '\ee29';
}

/* '' */
.icon-attention-5:before {
  content: '\ee2a';
}

/* '' */
.icon-location-8:before {
  content: '\ee2b';
}

/* '' */
.icon-doc-8:before {
  content: '\ee2c';
}

/* '' */
.icon-docs-landscape:before {
  content: '\ee2d';
}

/* '' */
.icon-folder-6:before {
  content: '\ee2e';
}

/* '' */
.icon-archive-2:before {
  content: '\ee2f';
}

/* '' */
.icon-rss-7:before {
  content: '\ee30';
}

/* '' */
.icon-rss-alt-1:before {
  content: '\ee31';
}

/* '' */
.icon-cog-7:before {
  content: '\ee32';
}

/* '' */
.icon-logout-3:before {
  content: '\ee33';
}

/* '' */
.icon-clock-8:before {
  content: '\ee34';
}

/* '' */
.icon-block-5:before {
  content: '\ee35';
}

/* '' */
.icon-resize-full-6:before {
  content: '\ee36';
}

/* '' */
.icon-resize-full-circle:before {
  content: '\ee37';
}

/* '' */
.icon-popup-5:before {
  content: '\ee38';
}

/* '' */
.icon-left-open-5:before {
  content: '\ee39';
}

/* '' */
.icon-right-open-4:before {
  content: '\ee3a';
}

/* '' */
.icon-down-circle-1:before {
  content: '\ee3b';
}

/* '' */
.icon-left-circle-1:before {
  content: '\ee3c';
}

/* '' */
.icon-right-circle-1:before {
  content: '\ee3d';
}

/* '' */
.icon-up-circle-1:before {
  content: '\ee3e';
}

/* '' */
.icon-down-dir-3:before {
  content: '\ee3f';
}

/* '' */
.icon-right-dir-3:before {
  content: '\ee40';
}

/* '' */
.icon-down-micro:before {
  content: '\ee41';
}

/* '' */
.icon-up-micro:before {
  content: '\ee42';
}

/* '' */
.icon-cw-circle:before {
  content: '\ee43';
}

/* '' */
.icon-arrows-cw-3:before {
  content: '\ee44';
}

/* '' */
.icon-updown-circle:before {
  content: '\ee45';
}

/* '' */
.icon-target-5:before {
  content: '\ee46';
}

/* '' */
.icon-signal-5:before {
  content: '\ee47';
}

/* '' */
.icon-progress-4:before {
  content: '\ee48';
}

/* '' */
.icon-progress-5:before {
  content: '\ee49';
}

/* '' */
.icon-progress-6:before {
  content: '\ee4a';
}

/* '' */
.icon-progress-7:before {
  content: '\ee4b';
}

/* '' */
.icon-progress-8:before {
  content: '\ee4c';
}

/* '' */
.icon-progress-9:before {
  content: '\ee4d';
}

/* '' */
.icon-progress-10:before {
  content: '\ee4e';
}

/* '' */
.icon-progress-11:before {
  content: '\ee4f';
}

/* '' */
.icon-font-2:before {
  content: '\ee50';
}

/* '' */
.icon-list-4:before {
  content: '\ee51';
}

/* '' */
.icon-list-numbered-1:before {
  content: '\ee52';
}

/* '' */
.icon-indent-left-2:before {
  content: '\ee53';
}

/* '' */
.icon-indent-right-2:before {
  content: '\ee54';
}

/* '' */
.icon-cloud-8:before {
  content: '\ee55';
}

/* '' */
.icon-terminal-1:before {
  content: '\ee56';
}

/* '' */
.icon-facebook-rect-2:before {
  content: '\ee57';
}

/* '' */
.icon-twitter-bird-2:before {
  content: '\ee58';
}

/* '' */
.icon-vimeo-rect-1:before {
  content: '\ee59';
}

/* '' */
.icon-tumblr-rect-1:before {
  content: '\ee5a';
}

/* '' */
.icon-googleplus-rect-1:before {
  content: '\ee5b';
}

/* '' */
.icon-linkedin-rect-1:before {
  content: '\ee5c';
}

/* '' */
.icon-skype-6:before {
  content: '\ee5d';
}

/* '' */
.icon-vkontakte-rect-1:before {
  content: '\ee5e';
}

/* '' */
.icon-youtube-4:before {
  content: '\ee5f';
}

/* '' */
.icon-odnoklassniki-rect-1:before {
  content: '\ee60';
}

/* '' */
.icon-wordpress-3:before {
  content: '\ee61';
}

/* '' */
.icon-youtube-3:before {
  content: '\ee62';
}

/* '' */
.icon-down-open-2:before {
  content: '\f004';
}

/* '' */
.icon-up-open-2:before {
  content: '\f005';
}

/* '' */
.icon-right-open-2:before {
  content: '\f006';
}

/* '' */
.icon-left-open-3:before {
  content: '\f007';
}

/* '' */
.icon-menu-3:before {
  content: '\f008';
}

/* '' */
.icon-th-list-3:before {
  content: '\f009';
}

/* '' */
.icon-th-thumb:before {
  content: '\f00a';
}

/* '' */
.icon-th-thumb-empty:before {
  content: '\f00b';
}

/* '' */
.icon-coverflow:before {
  content: '\f00c';
}

/* '' */
.icon-coverflow-empty:before {
  content: '\f00d';
}

/* '' */
.icon-pause-4:before {
  content: '\f00e';
}

/* '' */
.icon-play-4:before {
  content: '\f00f';
}

/* '' */
.icon-to-end-3:before {
  content: '\f010';
}

/* '' */
.icon-to-start-3:before {
  content: '\f011';
}

/* '' */
.icon-fast-forward-1:before {
  content: '\f012';
}

/* '' */
.icon-fast-backward-1:before {
  content: '\f013';
}

/* '' */
.icon-upload-cloud-4:before {
  content: '\f014';
}

/* '' */
.icon-download-cloud-2:before {
  content: '\f015';
}

/* '' */
.icon-data-science:before {
  content: '\f016';
}

/* '' */
.icon-data-science-inv:before {
  content: '\f017';
}

/* '' */
.icon-globe-4:before {
  content: '\f018';
}

/* '' */
.icon-globe-inv:before {
  content: '\f019';
}

/* '' */
.icon-math:before {
  content: '\f01a';
}

/* '' */
.icon-math-circled-empty:before {
  content: '\f01b';
}

/* '' */
.icon-math-circled:before {
  content: '\f01c';
}

/* '' */
.icon-paper-plane-2:before {
  content: '\f01d';
}

/* '' */
.icon-paper-plane-alt2:before {
  content: '\f01e';
}

/* '' */
.icon-paper-plane-alt:before {
  content: '\f01f';
}

/* '' */
.icon-color-adjust:before {
  content: '\f020';
}

/* '' */
.icon-star-half-1:before {
  content: '\f022';
}

/* '' */
.icon-star-half_empty:before {
  content: '\f024';
}

/* '' */
.icon-ccw-2:before {
  content: '\f025';
}

/* '' */
.icon-heart-broken:before {
  content: '\f028';
}

/* '' */
.icon-hash-1:before {
  content: '\f029';
}

/* '' */
.icon-reply-3:before {
  content: '\f02a';
}

/* '' */
.icon-retweet-2:before {
  content: '\f02b';
}

/* '' */
.icon-login-2:before {
  content: '\f02c';
}

/* '' */
.icon-logout-2:before {
  content: '\f02d';
}

/* '' */
.icon-download-5:before {
  content: '\f02e';
}

/* '' */
.icon-upload-4:before {
  content: '\f02f';
}

/* '' */
.icon-location-5:before {
  content: '\f031';
}

/* '' */
.icon-monitor-1:before {
  content: '\f032';
}

/* '' */
.icon-tablet-3:before {
  content: '\f033';
}

/* '' */
.icon-mobile-5:before {
  content: '\f034';
}

/* '' */
.icon-connected-object:before {
  content: '\f035';
}

/* '' */
.icon-isight:before {
  content: '\f039';
}

/* '' */
.icon-videocam-3:before {
  content: '\f03a';
}

/* '' */
.icon-shuffle-3:before {
  content: '\f03b';
}

/* '' */
.icon-chat-5:before {
  content: '\f03d';
}

/* '' */
.icon-bell-4:before {
  content: '\f03f';
}

/* '' */
.icon-movie:before {
  content: '\f040';
}

/* '' */
.icon-ruler:before {
  content: '\f044';
}

/* '' */
.icon-vector:before {
  content: '\f045';
}

/* '' */
.icon-move:before {
  content: '\f047';
}

/* '' */
.icon-mic-4:before {
  content: '\f048';
}

/* '' */
.icon-doc-5:before {
  content: '\f04a';
}

/* '' */
.icon-dribbble-circled-2:before {
  content: '\f04f';
}

/* '' */
.icon-dribbble-3:before {
  content: '\f050';
}

/* '' */
.icon-facebook-circled-2:before {
  content: '\f051';
}

/* '' */
.icon-facebook-4:before {
  content: '\f052';
}

/* '' */
.icon-github-circled-alt:before {
  content: '\f053';
}

/* '' */
.icon-github-circled-3:before {
  content: '\f054';
}

/* '' */
.icon-github-3:before {
  content: '\f055';
}

/* '' */
.icon-github-circled-alt2:before {
  content: '\f056';
}

/* '' */
.icon-twitter-circled-2:before {
  content: '\f057';
}

/* '' */
.icon-twitter-4:before {
  content: '\f058';
}

/* '' */
.icon-gplus-circled-1:before {
  content: '\f059';
}

/* '' */
.icon-gplus-2:before {
  content: '\f05a';
}

/* '' */
.icon-linkedin-circled-2:before {
  content: '\f05b';
}

/* '' */
.icon-linkedin-3:before {
  content: '\f05c';
}

/* '' */
.icon-instagram-2:before {
  content: '\f05d';
}

/* '' */
.icon-instagram-circled:before {
  content: '\f05e';
}

/* '' */
.icon-mfg-logo:before {
  content: '\f05f';
}

/* '' */
.icon-mfg-logo-circled:before {
  content: '\f060';
}

/* '' */
.icon-user-5:before {
  content: '\f061';
}

/* '' */
.icon-user-male:before {
  content: '\f062';
}

/* '' */
.icon-user-female:before {
  content: '\f063';
}

/* '' */
.icon-users-3:before {
  content: '\f064';
}

/* '' */
.icon-folder-4:before {
  content: '\f067';
}

/* '' */
.icon-folder-open-1:before {
  content: '\f068';
}

/* '' */
.icon-folder-empty-2:before {
  content: '\f069';
}

/* '' */
.icon-attach-4:before {
  content: '\f06a';
}

/* '' */
.icon-ok-circled-1:before {
  content: '\f06d';
}

/* '' */
.icon-cancel-circled-3:before {
  content: '\f06e';
}

/* '' */
.icon-inbox-2:before {
  content: '\f070';
}

/* '' */
.icon-trophy-1:before {
  content: '\f074';
}

/* '' */
.icon-lock-open-alt-1:before {
  content: '\f075';
}

/* '' */
.icon-link-4:before {
  content: '\f07b';
}

/* '' */
.icon-zoom-in-3:before {
  content: '\f07e';
}

/* '' */
.icon-zoom-out-3:before {
  content: '\f07f';
}

/* '' */
.icon-stop-5:before {
  content: '\f080';
}

/* '' */
.icon-export-4:before {
  content: '\f081';
}

/* '' */
.icon-eye-5:before {
  content: '\f082';
}

/* '' */
.icon-trash-5:before {
  content: '\f083';
}

/* '' */
.icon-hdd-1:before {
  content: '\f084';
}

/* '' */
.icon-info-circled-2:before {
  content: '\f085';
}

/* '' */
.icon-info-circled-alt:before {
  content: '\f086';
}

/* '' */
.icon-print-4:before {
  content: '\f087';
}

/* '' */
.icon-fontsize:before {
  content: '\f088';
}

/* '' */
.icon-soundcloud-2:before {
  content: '\f089';
}

/* '' */
.icon-soundcloud-circled:before {
  content: '\f08a';
}

/* '' */
.icon-link-ext:before {
  content: '\f08e';
}

/* '' */
.icon-check-empty:before {
  content: '\f096';
}

/* '' */
.icon-bookmark-empty:before {
  content: '\f097';
}

/* '' */
.icon-phone-squared:before {
  content: '\f098';
}

/* '' */
.icon-twitter:before {
  content: '\f099';
}

/* '' */
.icon-facebook:before {
  content: '\f09a';
}

/* '' */
.icon-github-circled:before {
  content: '\f09b';
}

/* '' */
.icon-rss:before {
  content: '\f09e';
}

/* '' */
.icon-hdd:before {
  content: '\f0a0';
}

/* '' */
.icon-certificate:before {
  content: '\f0a3';
}

/* '' */
.icon-left-circled:before {
  content: '\f0a8';
}

/* '' */
.icon-right-circled:before {
  content: '\f0a9';
}

/* '' */
.icon-up-circled:before {
  content: '\f0aa';
}

/* '' */
.icon-down-circled:before {
  content: '\f0ab';
}

/* '' */
.icon-tasks:before {
  content: '\f0ae';
}

/* '' */
.icon-filter:before {
  content: '\f0b0';
}

/* '' */
.icon-resize-full-alt:before {
  content: '\f0b2';
}

/* '' */
.icon-beaker:before {
  content: '\f0c3';
}

/* '' */
.icon-docs:before {
  content: '\f0c5';
}

/* '' */
.icon-blank:before {
  content: '\f0c8';
}

/* '' */
.icon-menu:before {
  content: '\f0c9';
}

/* '' */
.icon-list-bullet:before {
  content: '\f0ca';
}

/* '' */
.icon-list-numbered:before {
  content: '\f0cb';
}

/* '' */
.icon-strike:before {
  content: '\f0cc';
}

/* '' */
.icon-underline:before {
  content: '\f0cd';
}

/* '' */
.icon-table:before {
  content: '\f0ce';
}

/* '' */
.icon-magic:before {
  content: '\f0d0';
}

/* '' */
.icon-pinterest-circled:before {
  content: '\f0d2';
}

/* '' */
.icon-pinterest-squared:before {
  content: '\f0d3';
}

/* '' */
.icon-gplus-squared:before {
  content: '\f0d4';
}

/* '' */
.icon-gplus:before {
  content: '\f0d5';
}

/* '' */
.icon-money:before {
  content: '\f0d6';
}

/* '' */
.icon-columns:before {
  content: '\f0db';
}

/* '' */
.icon-sort:before {
  content: '\f0dc';
}

/* '' */
.icon-sort-down:before {
  content: '\f0dd';
}

/* '' */
.icon-sort-up:before {
  content: '\f0de';
}

/* '' */
.icon-mail-alt:before {
  content: '\f0e0';
}

/* '' */
.icon-linkedin:before {
  content: '\f0e1';
}

/* '' */
.icon-gauge:before {
  content: '\f0e4';
}

/* '' */
.icon-comment-empty:before {
  content: '\f0e5';
}

/* '' */
.icon-chat-empty:before {
  content: '\f0e6';
}

/* '' */
.icon-sitemap:before {
  content: '\f0e8';
}

/* '' */
.icon-paste:before {
  content: '\f0ea';
}

/* '' */
.icon-lightbulb:before {
  content: '\f0eb';
}

/* '' */
.icon-exchange:before {
  content: '\f0ec';
}

/* '' */
.icon-download-cloud:before {
  content: '\f0ed';
}

/* '' */
.icon-upload-cloud:before {
  content: '\f0ee';
}

/* '' */
.icon-user-md:before {
  content: '\f0f0';
}

/* '' */
.icon-stethoscope:before {
  content: '\f0f1';
}

/* '' */
.icon-suitcase:before {
  content: '\f0f2';
}

/* '' */
.icon-bell-alt:before {
  content: '\f0f3';
}

/* '' */
.icon-coffee:before {
  content: '\f0f4';
}

/* '' */
.icon-food:before {
  content: '\f0f5';
}

/* '' */
.icon-doc-text:before {
  content: '\f0f6';
}

/* '' */
.icon-building:before {
  content: '\f0f7';
}

/* '' */
.icon-hospital:before {
  content: '\f0f8';
}

/* '' */
.icon-ambulance:before {
  content: '\f0f9';
}

/* '' */
.icon-medkit:before {
  content: '\f0fa';
}

/* '' */
.icon-fighter-jet:before {
  content: '\f0fb';
}

/* '' */
.icon-beer:before {
  content: '\f0fc';
}

/* '' */
.icon-h-sigh:before {
  content: '\f0fd';
}

/* '' */
.icon-plus-squared:before {
  content: '\f0fe';
}

/* '' */
.icon-angle-double-left:before {
  content: '\f100';
}

/* '' */
.icon-angle-double-right:before {
  content: '\f101';
}

/* '' */
.icon-angle-double-up:before {
  content: '\f102';
}

/* '' */
.icon-angle-double-down:before {
  content: '\f103';
}

/* '' */
.icon-angle-left:before {
  content: '\f104';
}

/* '' */
.icon-angle-right:before {
  content: '\f105';
}

/* '' */
.icon-angle-up:before {
  content: '\f106';
}

/* '' */
.icon-angle-down:before {
  content: '\f107';
}

/* '' */
.icon-desktop:before {
  content: '\f108';
}

/* '' */
.icon-laptop:before {
  content: '\f109';
}

/* '' */
.icon-tablet:before {
  content: '\f10a';
}

/* '' */
.icon-mobile:before {
  content: '\f10b';
}

/* '' */
.icon-circle-empty:before {
  content: '\f10c';
}

/* '' */
.icon-quote-left:before {
  content: '\f10d';
}

/* '' */
.icon-quote-right:before {
  content: '\f10e';
}

/* '' */
.icon-spinner:before {
  content: '\f110';
}

/* '' */
.icon-circle:before {
  content: '\f111';
}

/* '' */
.icon-reply:before {
  content: '\f112';
}

/* '' */
.icon-github:before {
  content: '\f113';
}

/* '' */
.icon-folder-empty:before {
  content: '\f114';
}

/* '' */
.icon-folder-open-empty:before {
  content: '\f115';
}

/* '' */
.icon-smile:before {
  content: '\f118';
}

/* '' */
.icon-frown:before {
  content: '\f119';
}

/* '' */
.icon-meh:before {
  content: '\f11a';
}

/* '' */
.icon-gamepad:before {
  content: '\f11b';
}

/* '' */
.icon-keyboard:before {
  content: '\f11c';
}

/* '' */
.icon-flag-empty:before {
  content: '\f11d';
}

/* '' */
.icon-flag-checkered:before {
  content: '\f11e';
}

/* '' */
.icon-terminal:before {
  content: '\f120';
}

/* '' */
.icon-code:before {
  content: '\f121';
}

/* '' */
.icon-reply-all:before {
  content: '\f122';
}

/* '' */
.icon-star-half-alt:before {
  content: '\f123';
}

/* '' */
.icon-direction:before {
  content: '\f124';
}

/* '' */
.icon-crop:before {
  content: '\f125';
}

/* '' */
.icon-fork:before {
  content: '\f126';
}

/* '' */
.icon-unlink:before {
  content: '\f127';
}

/* '' */
.icon-help:before {
  content: '\f128';
}

/* '' */
.icon-info:before {
  content: '\f129';
}

/* '' */
.icon-attention-alt:before {
  content: '\f12a';
}

/* '' */
.icon-superscript:before {
  content: '\f12b';
}

/* '' */
.icon-subscript:before {
  content: '\f12c';
}

/* '' */
.icon-eraser:before {
  content: '\f12d';
}

/* '' */
.icon-puzzle:before {
  content: '\f12e';
}

/* '' */
.icon-mic:before {
  content: '\f130';
}

/* '' */
.icon-mute:before {
  content: '\f131';
}

/* '' */
.icon-shield:before {
  content: '\f132';
}

/* '' */
.icon-calendar-empty:before {
  content: '\f133';
}

/* '' */
.icon-extinguisher:before {
  content: '\f134';
}

/* '' */
.icon-rocket:before {
  content: '\f135';
}

/* '' */
.icon-maxcdn:before {
  content: '\f136';
}

/* '' */
.icon-angle-circled-left:before {
  content: '\f137';
}

/* '' */
.icon-angle-circled-right:before {
  content: '\f138';
}

/* '' */
.icon-angle-circled-up:before {
  content: '\f139';
}

/* '' */
.icon-angle-circled-down:before {
  content: '\f13a';
}

/* '' */
.icon-html5:before {
  content: '\f13b';
}

/* '' */
.icon-css3:before {
  content: '\f13c';
}

/* '' */
.icon-anchor:before {
  content: '\f13d';
}

/* '' */
.icon-lock-open-alt:before {
  content: '\f13e';
}

/* '' */
.icon-bullseye:before {
  content: '\f140';
}

/* '' */
.icon-ellipsis:before {
  content: '\f141';
}

/* '' */
.icon-ellipsis-vert:before {
  content: '\f142';
}

/* '' */
.icon-rss-squared:before {
  content: '\f143';
}

/* '' */
.icon-play-circled:before {
  content: '\f144';
}

/* '' */
.icon-ticket:before {
  content: '\f145';
}

/* '' */
.icon-minus-squared:before {
  content: '\f146';
}

/* '' */
.icon-minus-squared-alt:before {
  content: '\f147';
}

/* '' */
.icon-level-up:before {
  content: '\f148';
}

/* '' */
.icon-level-down:before {
  content: '\f149';
}

/* '' */
.icon-ok-squared:before {
  content: '\f14a';
}

/* '' */
.icon-pencil-squared:before {
  content: '\f14b';
}

/* '' */
.icon-link-ext-alt:before {
  content: '\f14c';
}

/* '' */
.icon-export-alt:before {
  content: '\f14d';
}

/* '' */
.icon-compass:before {
  content: '\f14e';
}

/* '' */
.icon-expand:before {
  content: '\f150';
}

/* '' */
.icon-collapse:before {
  content: '\f151';
}

/* '' */
.icon-expand-right:before {
  content: '\f152';
}

/* '' */
.icon-euro:before {
  content: '\f153';
}

/* '' */
.icon-pound:before {
  content: '\f154';
}

/* '' */
.icon-dollar:before {
  content: '\f155';
}

/* '' */
.icon-rupee:before {
  content: '\f156';
}

/* '' */
.icon-yen:before {
  content: '\f157';
}

/* '' */
.icon-rouble:before {
  content: '\f158';
}

/* '' */
.icon-won:before {
  content: '\f159';
}

/* '' */
.icon-bitcoin:before {
  content: '\f15a';
}

/* '' */
.icon-doc-inv:before {
  content: '\f15b';
}

/* '' */
.icon-doc-text-inv:before {
  content: '\f15c';
}

/* '' */
.icon-sort-name-up:before {
  content: '\f15d';
}

/* '' */
.icon-sort-name-down:before {
  content: '\f15e';
}

/* '' */
.icon-sort-alt-up:before {
  content: '\f160';
}

/* '' */
.icon-sort-alt-down:before {
  content: '\f161';
}

/* '' */
.icon-sort-number-up:before {
  content: '\f162';
}

/* '' */
.icon-sort-number-down:before {
  content: '\f163';
}

/* '' */
.icon-thumbs-up-alt:before {
  content: '\f164';
}

/* '' */
.icon-thumbs-down-alt:before {
  content: '\f165';
}

/* '' */
.icon-youtube-squared:before {
  content: '\f166';
}

/* '' */
.icon-youtube:before {
  content: '\f167';
}

/* '' */
.icon-xing:before {
  content: '\f168';
}

/* '' */
.icon-xing-squared:before {
  content: '\f169';
}

/* '' */
.icon-youtube-play:before {
  content: '\f16a';
}

/* '' */
.icon-dropbox:before {
  content: '\f16b';
}

/* '' */
.icon-stackoverflow:before {
  content: '\f16c';
}

/* '' */
.icon-instagram:before {
  content: '\f16d';
}

/* '' */
.icon-flickr:before {
  content: '\f16e';
}

/* '' */
.icon-adn:before {
  content: '\f170';
}

/* '' */
.icon-bitbucket:before {
  content: '\f171';
}

/* '' */
.icon-bitbucket-squared:before {
  content: '\f172';
}

/* '' */
.icon-tumblr:before {
  content: '\f173';
}

/* '' */
.icon-tumblr-squared:before {
  content: '\f174';
}

/* '' */
.icon-down:before {
  content: '\f175';
}

/* '' */
.icon-up:before {
  content: '\f176';
}

/* '' */
.icon-left:before {
  content: '\f177';
}

/* '' */
.icon-right:before {
  content: '\f178';
}

/* '' */
.icon-apple:before {
  content: '\f179';
}

/* '' */
.icon-windows:before {
  content: '\f17a';
}

/* '' */
.icon-android:before {
  content: '\f17b';
}

/* '' */
.icon-linux:before {
  content: '\f17c';
}

/* '' */
.icon-dribbble:before {
  content: '\f17d';
}

/* '' */
.icon-skype:before {
  content: '\f17e';
}

/* '' */
.icon-foursquare:before {
  content: '\f180';
}

/* '' */
.icon-trello:before {
  content: '\f181';
}

/* '' */
.icon-female:before {
  content: '\f182';
}

/* '' */
.icon-male:before {
  content: '\f183';
}

/* '' */
.icon-gittip:before {
  content: '\f184';
}

/* '' */
.icon-sun:before {
  content: '\f185';
}

/* '' */
.icon-moon:before {
  content: '\f186';
}

/* '' */
.icon-box:before {
  content: '\f187';
}

/* '' */
.icon-bug:before {
  content: '\f188';
}

/* '' */
.icon-vkontakte:before {
  content: '\f189';
}

/* '' */
.icon-weibo:before {
  content: '\f18a';
}

/* '' */
.icon-renren:before {
  content: '\f18b';
}

/* '' */
.icon-pagelines:before {
  content: '\f18c';
}

/* '' */
.icon-stackexchange:before {
  content: '\f18d';
}

/* '' */
.icon-right-circled2:before {
  content: '\f18e';
}

/* '' */
.icon-left-circled2:before {
  content: '\f190';
}

/* '' */
.icon-collapse-left:before {
  content: '\f191';
}

/* '' */
.icon-dot-circled:before {
  content: '\f192';
}

/* '' */
.icon-wheelchair:before {
  content: '\f193';
}

/* '' */
.icon-vimeo-squared:before {
  content: '\f194';
}

/* '' */
.icon-try:before {
  content: '\f195';
}

/* '' */
.icon-plus-squared-alt:before {
  content: '\f196';
}

/* '' */
.icon-space-shuttle:before {
  content: '\f197';
}

/* '' */
.icon-slack:before {
  content: '\f198';
}

/* '' */
.icon-mail-squared:before {
  content: '\f199';
}

/* '' */
.icon-wordpress:before {
  content: '\f19a';
}

/* '' */
.icon-openid:before {
  content: '\f19b';
}

/* '' */
.icon-bank:before {
  content: '\f19c';
}

/* '' */
.icon-graduation-cap:before {
  content: '\f19d';
}

/* '' */
.icon-yahoo:before {
  content: '\f19e';
}

/* '' */
.icon-google:before {
  content: '\f1a0';
}

/* '' */
.icon-reddit:before {
  content: '\f1a1';
}

/* '' */
.icon-reddit-squared:before {
  content: '\f1a2';
}

/* '' */
.icon-stumbleupon-circled:before {
  content: '\f1a3';
}

/* '' */
.icon-stumbleupon:before {
  content: '\f1a4';
}

/* '' */
.icon-delicious:before {
  content: '\f1a5';
}

/* '' */
.icon-digg:before {
  content: '\f1a6';
}

/* '' */
.icon-pied-piper-squared:before {
  content: '\f1a7';
}

/* '' */
.icon-pied-piper-alt:before {
  content: '\f1a8';
}

/* '' */
.icon-drupal:before {
  content: '\f1a9';
}

/* '' */
.icon-joomla:before {
  content: '\f1aa';
}

/* '' */
.icon-language:before {
  content: '\f1ab';
}

/* '' */
.icon-fax:before {
  content: '\f1ac';
}

/* '' */
.icon-building-filled:before {
  content: '\f1ad';
}

/* '' */
.icon-child:before {
  content: '\f1ae';
}

/* '' */
.icon-paw:before {
  content: '\f1b0';
}

/* '' */
.icon-spoon:before {
  content: '\f1b1';
}

/* '' */
.icon-cube:before {
  content: '\f1b2';
}

/* '' */
.icon-cubes:before {
  content: '\f1b3';
}

/* '' */
.icon-behance:before {
  content: '\f1b4';
}

/* '' */
.icon-behance-squared:before {
  content: '\f1b5';
}

/* '' */
.icon-steam:before {
  content: '\f1b6';
}

/* '' */
.icon-steam-squared:before {
  content: '\f1b7';
}

/* '' */
.icon-recycle:before {
  content: '\f1b8';
}

/* '' */
.icon-cab:before {
  content: '\f1b9';
}

/* '' */
.icon-taxi:before {
  content: '\f1ba';
}

/* '' */
.icon-tree:before {
  content: '\f1bb';
}

/* '' */
.icon-spotify:before {
  content: '\f1bc';
}

/* '' */
.icon-deviantart:before {
  content: '\f1bd';
}

/* '' */
.icon-soundcloud:before {
  content: '\f1be';
}

/* '' */
.icon-database:before {
  content: '\f1c0';
}

/* '' */
.icon-file-pdf:before {
  content: '\f1c1';
}

/* '' */
.icon-file-word:before {
  content: '\f1c2';
}

/* '' */
.icon-file-excel:before {
  content: '\f1c3';
}

/* '' */
.icon-file-powerpoint:before {
  content: '\f1c4';
}

/* '' */
.icon-file-image:before {
  content: '\f1c5';
}

/* '' */
.icon-file-archive:before {
  content: '\f1c6';
}

/* '' */
.icon-file-audio:before {
  content: '\f1c7';
}

/* '' */
.icon-file-video:before {
  content: '\f1c8';
}

/* '' */
.icon-file-code:before {
  content: '\f1c9';
}

/* '' */
.icon-vine:before {
  content: '\f1ca';
}

/* '' */
.icon-codeopen:before {
  content: '\f1cb';
}

/* '' */
.icon-jsfiddle:before {
  content: '\f1cc';
}

/* '' */
.icon-lifebuoy:before {
  content: '\f1cd';
}

/* '' */
.icon-circle-notch:before {
  content: '\f1ce';
}

/* '' */
.icon-rebel:before {
  content: '\f1d0';
}

/* '' */
.icon-empire:before {
  content: '\f1d1';
}

/* '' */
.icon-git-squared:before {
  content: '\f1d2';
}

/* '' */
.icon-git:before {
  content: '\f1d3';
}

/* '' */
.icon-hacker-news:before {
  content: '\f1d4';
}

/* '' */
.icon-tencent-weibo:before {
  content: '\f1d5';
}

/* '' */
.icon-qq:before {
  content: '\f1d6';
}

/* '' */
.icon-wechat:before {
  content: '\f1d7';
}

/* '' */
.icon-paper-plane:before {
  content: '\f1d8';
}

/* '' */
.icon-paper-plane-empty:before {
  content: '\f1d9';
}

/* '' */
.icon-history:before {
  content: '\f1da';
}

/* '' */
.icon-circle-thin:before {
  content: '\f1db';
}

/* '' */
.icon-header:before {
  content: '\f1dc';
}

/* '' */
.icon-paragraph:before {
  content: '\f1dd';
}

/* '' */
.icon-sliders:before {
  content: '\f1de';
}

/* '' */
.icon-share:before {
  content: '\f1e0';
}

/* '' */
.icon-share-squared:before {
  content: '\f1e1';
}

/* '' */
.icon-bomb:before {
  content: '\f1e2';
}

/* '' */
.icon-soccer-ball:before {
  content: '\f1e3';
}

/* '' */
.icon-tty:before {
  content: '\f1e4';
}

/* '' */
.icon-binoculars:before {
  content: '\f1e5';
}

/* '' */
.icon-plug:before {
  content: '\f1e6';
}

/* '' */
.icon-slideshare:before {
  content: '\f1e7';
}

/* '' */
.icon-twitch:before {
  content: '\f1e8';
}

/* '' */
.icon-yelp:before {
  content: '\f1e9';
}

/* '' */
.icon-newspaper:before {
  content: '\f1ea';
}

/* '' */
.icon-wifi:before {
  content: '\f1eb';
}

/* '' */
.icon-calc:before {
  content: '\f1ec';
}

/* '' */
.icon-paypal:before {
  content: '\f1ed';
}

/* '' */
.icon-gwallet:before {
  content: '\f1ee';
}

/* '' */
.icon-cc-visa:before {
  content: '\f1f0';
}

/* '' */
.icon-cc-mastercard:before {
  content: '\f1f1';
}

/* '' */
.icon-cc-discover:before {
  content: '\f1f2';
}

/* '' */
.icon-cc-amex:before {
  content: '\f1f3';
}

/* '' */
.icon-cc-paypal:before {
  content: '\f1f4';
}

/* '' */
.icon-cc-stripe:before {
  content: '\f1f5';
}

/* '' */
.icon-bell-off:before {
  content: '\f1f6';
}

/* '' */
.icon-bell-off-empty:before {
  content: '\f1f7';
}

/* '' */
.icon-trash:before {
  content: '\f1f8';
}

/* '' */
.icon-copyright:before {
  content: '\f1f9';
}

/* '' */
.icon-at:before {
  content: '\f1fa';
}

/* '' */
.icon-eyedropper:before {
  content: '\f1fb';
}

/* '' */
.icon-brush:before {
  content: '\f1fc';
}

/* '' */
.icon-birthday:before {
  content: '\f1fd';
}

/* '' */
.icon-chart-area:before {
  content: '\f1fe';
}

/* '' */
.icon-chart-pie:before {
  content: '\f200';
}

/* '' */
.icon-chart-line:before {
  content: '\f201';
}

/* '' */
.icon-lastfm:before {
  content: '\f202';
}

/* '' */
.icon-lastfm-squared:before {
  content: '\f203';
}

/* '' */
.icon-toggle-off:before {
  content: '\f204';
}

/* '' */
.icon-toggle-on:before {
  content: '\f205';
}

/* '' */
.icon-bicycle:before {
  content: '\f206';
}

/* '' */
.icon-bus:before {
  content: '\f207';
}

/* '' */
.icon-ioxhost:before {
  content: '\f208';
}

/* '' */
.icon-angellist:before {
  content: '\f209';
}

/* '' */
.icon-cc:before {
  content: '\f20a';
}

/* '' */
.icon-shekel:before {
  content: '\f20b';
}

/* '' */
.icon-meanpath:before {
  content: '\f20c';
}

/* '' */
.icon-buysellads:before {
  content: '\f20d';
}

/* '' */
.icon-connectdevelop:before {
  content: '\f20e';
}

/* '' */
.icon-dashcube:before {
  content: '\f210';
}

/* '' */
.icon-forumbee:before {
  content: '\f211';
}

/* '' */
.icon-leanpub:before {
  content: '\f212';
}

/* '' */
.icon-sellsy:before {
  content: '\f213';
}

/* '' */
.icon-shirtsinbulk:before {
  content: '\f214';
}

/* '' */
.icon-simplybuilt:before {
  content: '\f215';
}

/* '' */
.icon-skyatlas:before {
  content: '\f216';
}

/* '' */
.icon-cart-plus:before {
  content: '\f217';
}

/* '' */
.icon-cart-arrow-down:before {
  content: '\f218';
}

/* '' */
.icon-diamond:before {
  content: '\f219';
}

/* '' */
.icon-ship:before {
  content: '\f21a';
}

/* '' */
.icon-user-secret:before {
  content: '\f21b';
}

/* '' */
.icon-motorcycle:before {
  content: '\f21c';
}

/* '' */
.icon-street-view:before {
  content: '\f21d';
}

/* '' */
.icon-heartbeat:before {
  content: '\f21e';
}

/* '' */
.icon-venus:before {
  content: '\f221';
}

/* '' */
.icon-mars:before {
  content: '\f222';
}

/* '' */
.icon-mercury:before {
  content: '\f223';
}

/* '' */
.icon-transgender:before {
  content: '\f224';
}

/* '' */
.icon-transgender-alt:before {
  content: '\f225';
}

/* '' */
.icon-venus-double:before {
  content: '\f226';
}

/* '' */
.icon-mars-double:before {
  content: '\f227';
}

/* '' */
.icon-venus-mars:before {
  content: '\f228';
}

/* '' */
.icon-mars-stroke:before {
  content: '\f229';
}

/* '' */
.icon-mars-stroke-v:before {
  content: '\f22a';
}

/* '' */
.icon-mars-stroke-h:before {
  content: '\f22b';
}

/* '' */
.icon-neuter:before {
  content: '\f22c';
}

/* '' */
.icon-genderless:before {
  content: '\f22d';
}

/* '' */
.icon-facebook-official:before {
  content: '\f230';
}

/* '' */
.icon-pinterest:before {
  content: '\f231';
}

/* '' */
.icon-whatsapp:before {
  content: '\f232';
}

/* '' */
.icon-server:before {
  content: '\f233';
}

/* '' */
.icon-user-plus:before {
  content: '\f234';
}

/* '' */
.icon-user-times:before {
  content: '\f235';
}

/* '' */
.icon-bed:before {
  content: '\f236';
}

/* '' */
.icon-viacoin:before {
  content: '\f237';
}

/* '' */
.icon-train:before {
  content: '\f238';
}

/* '' */
.icon-subway:before {
  content: '\f239';
}

/* '' */
.icon-medium:before {
  content: '\f23a';
}

/* '' */
.icon-y-combinator:before {
  content: '\f23b';
}

/* '' */
.icon-optin-monster:before {
  content: '\f23c';
}

/* '' */
.icon-opencart:before {
  content: '\f23d';
}

/* '' */
.icon-expeditedssl:before {
  content: '\f23e';
}

/* '' */
.icon-battery-4:before {
  content: '\f240';
}

/* '' */
.icon-battery-3:before {
  content: '\f241';
}

/* '' */
.icon-battery-2:before {
  content: '\f242';
}

/* '' */
.icon-battery-1:before {
  content: '\f243';
}

/* '' */
.icon-battery-0:before {
  content: '\f244';
}

/* '' */
.icon-mouse-pointer:before {
  content: '\f245';
}

/* '' */
.icon-i-cursor:before {
  content: '\f246';
}

/* '' */
.icon-object-group:before {
  content: '\f247';
}

/* '' */
.icon-object-ungroup:before {
  content: '\f248';
}

/* '' */
.icon-sticky-note:before {
  content: '\f249';
}

/* '' */
.icon-sticky-note-o:before {
  content: '\f24a';
}

/* '' */
.icon-cc-jcb:before {
  content: '\f24b';
}

/* '' */
.icon-cc-diners-club:before {
  content: '\f24c';
}

/* '' */
.icon-clone:before {
  content: '\f24d';
}

/* '' */
.icon-balance-scale:before {
  content: '\f24e';
}

/* '' */
.icon-hourglass-o:before {
  content: '\f250';
}

/* '' */
.icon-hourglass-1:before {
  content: '\f251';
}

/* '' */
.icon-hourglass-2:before {
  content: '\f252';
}

/* '' */
.icon-hourglass-3:before {
  content: '\f253';
}

/* '' */
.icon-hourglass:before {
  content: '\f254';
}

/* '' */
.icon-hand-grab-o:before {
  content: '\f255';
}

/* '' */
.icon-hand-paper-o:before {
  content: '\f256';
}

/* '' */
.icon-hand-scissors-o:before {
  content: '\f257';
}

/* '' */
.icon-hand-lizard-o:before {
  content: '\f258';
}

/* '' */
.icon-hand-spock-o:before {
  content: '\f259';
}

/* '' */
.icon-hand-pointer-o:before {
  content: '\f25a';
}

/* '' */
.icon-hand-peace-o:before {
  content: '\f25b';
}

/* '' */
.icon-trademark:before {
  content: '\f25c';
}

/* '' */
.icon-registered:before {
  content: '\f25d';
}

/* '' */
.icon-creative-commons:before {
  content: '\f25e';
}

/* '' */
.icon-gg:before {
  content: '\f260';
}

/* '' */
.icon-gg-circle:before {
  content: '\f261';
}

/* '' */
.icon-tripadvisor:before {
  content: '\f262';
}

/* '' */
.icon-odnoklassniki:before {
  content: '\f263';
}

/* '' */
.icon-odnoklassniki-square:before {
  content: '\f264';
}

/* '' */
.icon-get-pocket:before {
  content: '\f265';
}

/* '' */
.icon-wikipedia-w:before {
  content: '\f266';
}

/* '' */
.icon-safari:before {
  content: '\f267';
}

/* '' */
.icon-chrome:before {
  content: '\f268';
}

/* '' */
.icon-firefox:before {
  content: '\f269';
}

/* '' */
.icon-opera:before {
  content: '\f26a';
}

/* '' */
.icon-internet-explorer:before {
  content: '\f26b';
}

/* '' */
.icon-television:before {
  content: '\f26c';
}

/* '' */
.icon-contao:before {
  content: '\f26d';
}

/* '' */
.icon-500px:before {
  content: '\f26e';
}

/* '' */
.icon-amazon:before {
  content: '\f270';
}

/* '' */
.icon-calendar-plus-o:before {
  content: '\f271';
}

/* '' */
.icon-calendar-minus-o:before {
  content: '\f272';
}

/* '' */
.icon-calendar-times-o:before {
  content: '\f273';
}

/* '' */
.icon-calendar-check-o:before {
  content: '\f274';
}

/* '' */
.icon-industry:before {
  content: '\f275';
}

/* '' */
.icon-map-pin:before {
  content: '\f276';
}

/* '' */
.icon-map-signs:before {
  content: '\f277';
}

/* '' */
.icon-map-o:before {
  content: '\f278';
}

/* '' */
.icon-map:before {
  content: '\f279';
}

/* '' */
.icon-commenting-o:before {
  content: '\f27b';
}

/* '' */
.icon-houzz:before {
  content: '\f27c';
}

/* '' */
.icon-vimeo:before {
  content: '\f27d';
}

/* '' */
.icon-black-tie:before {
  content: '\f27e';
}

/* '' */
.icon-fonticons:before {
  content: '\f280';
}

/* '' */
.icon-reddit-alien:before {
  content: '\f281';
}

/* '' */
.icon-edge:before {
  content: '\f282';
}

/* '' */
.icon-credit-card-alt:before {
  content: '\f283';
}

/* '' */
.icon-codiepie:before {
  content: '\f284';
}

/* '' */
.icon-modx:before {
  content: '\f285';
}

/* '' */
.icon-fort-awesome:before {
  content: '\f286';
}

/* '' */
.icon-usb:before {
  content: '\f287';
}

/* '' */
.icon-product-hunt:before {
  content: '\f288';
}

/* '' */
.icon-mixcloud:before {
  content: '\f289';
}

/* '' */
.icon-scribd:before {
  content: '\f28a';
}

/* '' */
.icon-pause-circle:before {
  content: '\f28b';
}

/* '' */
.icon-pause-circle-o:before {
  content: '\f28c';
}

/* '' */
.icon-stop-circle:before {
  content: '\f28d';
}

/* '' */
.icon-stop-circle-o:before {
  content: '\f28e';
}

/* '' */
.icon-shopping-bag:before {
  content: '\f290';
}

/* '' */
.icon-shopping-basket:before {
  content: '\f291';
}

/* '' */
.icon-hashtag:before {
  content: '\f292';
}

/* '' */
.icon-bluetooth:before {
  content: '\f293';
}

/* '' */
.icon-bluetooth-b:before {
  content: '\f294';
}

/* '' */
.icon-percent:before {
  content: '\f295';
}

/* '' */
.icon-gitlab:before {
  content: '\f296';
}

/* '' */
.icon-wpbeginner:before {
  content: '\f297';
}

/* '' */
.icon-wpforms:before {
  content: '\f298';
}

/* '' */
.icon-envira:before {
  content: '\f299';
}

/* '' */
.icon-universal-access:before {
  content: '\f29a';
}

/* '' */
.icon-wheelchair-alt:before {
  content: '\f29b';
}

/* '' */
.icon-question-circle-o:before {
  content: '\f29c';
}

/* '' */
.icon-blind:before {
  content: '\f29d';
}

/* '' */
.icon-audio-description:before {
  content: '\f29e';
}

/* '' */
.icon-volume-control-phone:before {
  content: '\f2a0';
}

/* '' */
.icon-braille:before {
  content: '\f2a1';
}

/* '' */
.icon-assistive-listening-systems:before {
  content: '\f2a2';
}

/* '' */
.icon-american-sign-language-interpreting:before {
  content: '\f2a3';
}

/* '' */
.icon-asl-interpreting:before {
  content: '\f2a4';
}

/* '' */
.icon-glide:before {
  content: '\f2a5';
}

/* '' */
.icon-glide-g:before {
  content: '\f2a6';
}

/* '' */
.icon-sign-language:before {
  content: '\f2a7';
}

/* '' */
.icon-low-vision:before {
  content: '\f2a8';
}

/* '' */
.icon-viadeo:before {
  content: '\f2a9';
}

/* '' */
.icon-viadeo-square:before {
  content: '\f2aa';
}

/* '' */
.icon-snapchat:before {
  content: '\f2ab';
}

/* '' */
.icon-snapchat-ghost:before {
  content: '\f2ac';
}

/* '' */
.icon-snapchat-square:before {
  content: '\f2ad';
}

/* '' */
.icon-pied-piper:before {
  content: '\f2ae';
}

/* '' */
.icon-first-order:before {
  content: '\f2b0';
}

/* '' */
.icon-yoast:before {
  content: '\f2b1';
}

/* '' */
.icon-themeisle:before {
  content: '\f2b2';
}

/* '' */
.icon-google-plus-circle:before {
  content: '\f2b3';
}

/* '' */
.icon-font-awesome:before {
  content: '\f2b4';
}

/* '' */
.icon-handshake-o:before {
  content: '\f2b5';
}

/* '' */
.icon-envelope-open:before {
  content: '\f2b6';
}

/* '' */
.icon-envelope-open-o:before {
  content: '\f2b7';
}

/* '' */
.icon-linode:before {
  content: '\f2b8';
}

/* '' */
.icon-address-book:before {
  content: '\f2b9';
}

/* '' */
.icon-address-book-o:before {
  content: '\f2ba';
}

/* '' */
.icon-address-card:before {
  content: '\f2bb';
}

/* '' */
.icon-address-card-o:before {
  content: '\f2bc';
}

/* '' */
.icon-user-circle:before {
  content: '\f2bd';
}

/* '' */
.icon-user-circle-o:before {
  content: '\f2be';
}

/* '' */
.icon-user-o:before {
  content: '\f2c0';
}

/* '' */
.icon-id-badge:before {
  content: '\f2c1';
}

/* '' */
.icon-id-card:before {
  content: '\f2c2';
}

/* '' */
.icon-id-card-o:before {
  content: '\f2c3';
}

/* '' */
.icon-quora:before {
  content: '\f2c4';
}

/* '' */
.icon-free-code-camp:before {
  content: '\f2c5';
}

/* '' */
.icon-telegram:before {
  content: '\f2c6';
}

/* '' */
.icon-thermometer:before {
  content: '\f2c7';
}

/* '' */
.icon-thermometer-3:before {
  content: '\f2c8';
}

/* '' */
.icon-thermometer-2:before {
  content: '\f2c9';
}

/* '' */
.icon-thermometer-quarter:before {
  content: '\f2ca';
}

/* '' */
.icon-thermometer-0:before {
  content: '\f2cb';
}

/* '' */
.icon-shower:before {
  content: '\f2cc';
}

/* '' */
.icon-bath:before {
  content: '\f2cd';
}

/* '' */
.icon-podcast:before {
  content: '\f2ce';
}

/* '' */
.icon-window-maximize:before {
  content: '\f2d0';
}

/* '' */
.icon-window-minimize:before {
  content: '\f2d1';
}

/* '' */
.icon-window-restore:before {
  content: '\f2d2';
}

/* '' */
.icon-window-close:before {
  content: '\f2d3';
}

/* '' */
.icon-window-close-o:before {
  content: '\f2d4';
}

/* '' */
.icon-bandcamp:before {
  content: '\f2d5';
}

/* '' */
.icon-grav:before {
  content: '\f2d6';
}

/* '' */
.icon-etsy:before {
  content: '\f2d7';
}

/* '' */
.icon-imdb:before {
  content: '\f2d8';
}

/* '' */
.icon-ravelry:before {
  content: '\f2d9';
}

/* '' */
.icon-eercast:before {
  content: '\f2da';
}

/* '' */
.icon-microchip:before {
  content: '\f2db';
}

/* '' */
.icon-snowflake-o:before {
  content: '\f2dc';
}

/* '' */
.icon-superpowers:before {
  content: '\f2dd';
}

/* '' */
.icon-wpexplorer:before {
  content: '\f2de';
}

/* '' */
.icon-meetup:before {
  content: '\f2e0';
}

/* '' */
.icon-github-squared:before {
  content: '\f300';
}

/* '' */
.icon-github-circled-1:before {
  content: '\f301';
}

/* '' */
.icon-twitter-3:before {
  content: '\f302';
}

/* '' */
.icon-flickr-1:before {
  content: '\f303';
}

/* '' */
.icon-twitter-squared:before {
  content: '\f304';
}

/* '' */
.icon-yandex:before {
  content: '\f305';
}

/* '' */
.icon-vimeo-1:before {
  content: '\f306';
}

/* '' */
.icon-vimeo-circled:before {
  content: '\f307';
}

/* '' */
.icon-facebook-squared:before {
  content: '\f308';
}

/* '' */
.icon-twitter-1:before {
  content: '\f309';
}

/* '' */
.icon-twitter-circled:before {
  content: '\f30a';
}

/* '' */
.icon-skype-4:before {
  content: '\f30b';
}

/* '' */
.icon-linkedin-squared:before {
  content: '\f30c';
}

/* '' */
.icon-facebook-circled:before {
  content: '\f30d';
}

/* '' */
.icon-facebook-squared-1:before {
  content: '\f30e';
}

/* '' */
.icon-gplus-1:before {
  content: '\f30f';
}

/* '' */
.icon-gplus-circled:before {
  content: '\f310';
}

/* '' */
.icon-tumblr-3:before {
  content: '\f311';
}

/* '' */
.icon-pinterest-1:before {
  content: '\f312';
}

/* '' */
.icon-pinterest-circled-1:before {
  content: '\f313';
}

/* '' */
.icon-twitter-rect:before {
  content: '\f314';
}

/* '' */
.icon-tumblr-1:before {
  content: '\f315';
}

/* '' */
.icon-tumblr-circled:before {
  content: '\f316';
}

/* '' */
.icon-jabber:before {
  content: '\f317';
}

/* '' */
.icon-linkedin-1:before {
  content: '\f318';
}

/* '' */
.icon-linkedin-circled:before {
  content: '\f319';
}

/* '' */
.icon-linkedin-5:before {
  content: '\f31a';
}

/* '' */
.icon-dribbble-1:before {
  content: '\f31b';
}

/* '' */
.icon-dribbble-circled:before {
  content: '\f31c';
}

/* '' */
.icon-wordpress-2:before {
  content: '\f31d';
}

/* '' */
.icon-stumbleupon-1:before {
  content: '\f31e';
}

/* '' */
.icon-stumbleupon-circled-1:before {
  content: '\f31f';
}

/* '' */
.icon-diigo:before {
  content: '\f320';
}

/* '' */
.icon-lastfm-1:before {
  content: '\f321';
}

/* '' */
.icon-lastfm-circled:before {
  content: '\f322';
}

/* '' */
.icon-tudou:before {
  content: '\f323';
}

/* '' */
.icon-rdio:before {
  content: '\f324';
}

/* '' */
.icon-rdio-circled:before {
  content: '\f325';
}

/* '' */
.icon-amex:before {
  content: '\f326';
}

/* '' */
.icon-spotify-1:before {
  content: '\f327';
}

/* '' */
.icon-spotify-circled:before {
  content: '\f328';
}

/* '' */
.icon-mastercard:before {
  content: '\f329';
}

/* '' */
.icon-qq-1:before {
  content: '\f32a';
}

/* '' */
.icon-bandcamp-1:before {
  content: '\f32b';
}

/* '' */
.icon-codepen:before {
  content: '\f32c';
}

/* '' */
.icon-instagram-1:before {
  content: '\f32d';
}

/* '' */
.icon-dropbox-1:before {
  content: '\f330';
}

/* '' */
.icon-evernote:before {
  content: '\f333';
}

/* '' */
.icon-flattr:before {
  content: '\f336';
}

/* '' */
.icon-skype-1:before {
  content: '\f339';
}

/* '' */
.icon-skype-circled:before {
  content: '\f33a';
}

/* '' */
.icon-renren-1:before {
  content: '\f33c';
}

/* '' */
.icon-sina-weibo:before {
  content: '\f33f';
}

/* '' */
.icon-paypal-1:before {
  content: '\f342';
}

/* '' */
.icon-picasa:before {
  content: '\f345';
}

/* '' */
.icon-soundcloud-1:before {
  content: '\f348';
}

/* '' */
.icon-mixi:before {
  content: '\f34b';
}

/* '' */
.icon-behance-1:before {
  content: '\f34e';
}

/* '' */
.icon-google-circles:before {
  content: '\f351';
}

/* '' */
.icon-vkontakte-1:before {
  content: '\f354';
}

/* '' */
.icon-smashing:before {
  content: '\f357';
}

/* '' */
.icon-comment-4:before {
  content: '\f4ac';
}

/* '' */
.icon-folder-open-empty-1:before {
  content: '\f4c2';
}

/* '' */
.icon-calendar-5:before {
  content: '\f4c5';
}

/* '' */
.icon-newspaper-2:before {
  content: '\f4f0';
}

/* '' */
.icon-camera-5:before {
  content: '\f4f7';
}

/* '' */
.icon-search-5:before {
  content: '\f50d';
}

/* '' */
.icon-lock-alt:before {
  content: '\f510';
}

/* '' */
.icon-lock-5:before {
  content: '\f512';
}

/* '' */
.icon-lock-open-5:before {
  content: '\f513';
}

/* '' */
.icon-joystick:before {
  content: '\f514';
}

/* '' */
.icon-fire-1:before {
  content: '\f525';
}

/* '' */
.icon-chart-bar-5:before {
  content: '\f526';
}

/* '' */
.icon-spread:before {
  content: '\f527';
}

/* '' */
.icon-spinner1:before {
  content: '\f528';
}

/* '' */
.icon-spinner2:before {
  content: '\f529';
}

/* '' */
.icon-db-shape:before {
  content: '\f600';
}

/* '' */
.icon-sweden:before {
  content: '\f601';
}

/* '' */
.icon-logo-db:before {
  content: '\f603';
}

/* '' */