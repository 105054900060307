@import url("fonts/fontello/fontello.css");
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,400,700|Pacifico');

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?2e743e");
  src: url("fonts/icomoon.eot?2e743e#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?2e743e") format("truetype"), url("fonts/icomoon.woff?2e743e") format("woff"), url("fonts/icomoon.svg?2e743e#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local('Raleway Medium'), local('Raleway-Medium'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwN4rWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwPIsWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIouWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@media only screen and (max-device-width: 1024px) {
  :root {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
}


button,
input,
optgroup,
option,
select,
textarea {
  font: normal normal normal 100%/normal -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  text-rendering: optimizespeed;
}

address,
b,
caption,
cite,
code,
dfn,
em,
h1,
h2,
h3,
h4,
h5,
h6,
i,
optgroup,
option,
strong,
th,
var {
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizespeed;
}

table {
  font-size: inherit;
  width: 100%;
}

address,
p,
dl,
blockquote,
pre,
td,
th,
label,
ol,
ul,
textarea {
  line-height: 1.61803;
  text-rendering: optimizespeed;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul,
menu,
dir,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

html:focus,
a:hover,
a:active {
  outline: 0;
}

:active,
:focus {
  outline: 0 !important;
}

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
svg {
  max-width: 100%;
  height: auto;
}

object,
embed {
  position: relative;
  max-width: 100%;
  height: 100%;
}

embed,
img,
object,
svg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}

dl,
dt,
dd {
  vertical-align: top;
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 0;
}

.clear5 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 5px;
}

.clear10 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 10px;
}

.clear15 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 15px;
}

.clear20 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 20px;
}

.clear25 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 25px;
}

.clear30 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 30px;
}

.clear35 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 35px;
}

.clear40 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 40px;
}

.clear45 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 45px;
}

.clear50 {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
  height: 50px;
}

.btn-default {
  color: #fcfcfc;
  background-color: #1c181a;
  border-color: #ccc;
  padding: 14px 29px;
  font-size: 14px;
  font-weight: 600;
  border: none;
}

.btn-default:hover {
  background: #f48137;
  color: #fff
}

label {
  font-size: 16px;
}

a {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: rgb(222, 33, 45);
}

.cleartest,
p.texteste {
  display: none;
}

p.texteste {
  color: #f00;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.spacer15:before,
.spacer15:after,
.spacer30:before,
.spacer30:after,
.spacer45:before,
.spacer45:after,
.spacer60:before,
.spacer60:after {
  display: table;
  content: " ";
}

.spacer15:after,
.spacer30:after,
.spacer45:after,
.spacer60:after {
  clear: both;
}

.spacer15 {
  height: 15px;
}

.spacer30 {
  height: 30px;
}

.spacer45 {
  height: 45px;
}

.spacer60 {
  height: 60px;
}

a.btn-ricca {
  border: 2px solid #f48137;
  padding: 5px 10px;
  font-size: 0.9em;
}

a.btn-ricca:hover {
  border-color: #f48137;
  text-decoration: none;
}

button.btn-ricca {
  border: 2px solid #f48137;
  padding: 5px 10px;
  font-size: 0.9em;
  background-color: #FFF;
}

button.btn-ricca:hover {
  border-color: #f48137;
  text-decoration: none;
}

.form-control {
  height: 40px;
  margin: 5px 0;
  border-radius: 0;
}

.form-control:focus {
  border: 1px solid #333;
  -webkit-box-shadow: inset 1px 1px 4px 0px rgba(50, 50, 50, .2);
  box-shadow: inset 1px 1px 4px 0px rgba(50, 50, 50, .2);
  background: #fff;
}

html,
body {
  margin: 0px;
  padding: 0px;
  overflow-x: none;
}

body {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  background-size: cover;
  background: url(/assets/img/bgbg.jpg) fixed;
}

p,
form {
  margin: 0;
}

a {
  color: #1F1F1F;
}

.pagenator {
  width: 100%;
  overflow: hidden;
  clear: both;
  margin: 20px 0;
  padding: 0;
}

.pagenator img {
  margin: 7px 2px 0 2px;
}

.pagenator a {
  color: #333;
  text-decoration: none;
}

.pagenator a:hover {
  color: #333;
  text-decoration: underline;
}

.pagenator>table {
  float: right;
}

.fotoTopo {
  margin: 0 0 20px 0;
  width: 100% !important;
  text-align: center;
  display: block;
  clear: both;
  overflow: hidden;
}

.fotoTopo img {
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  padding: 4px;
}

.fotoright {
  float: right;
  text-align: right;
  padding: 0 0 10px 0;
}

.fotoinferior {
  margin: 0 20px 20px 0;
  display: inline-table;
}

.containerfotoleft {
  margin: 0 20px 0 0;
  float: left;
}

.containerfotoright {
  margin: 0 0 0 20px;
  float: right;
}

.containerfotoinferior {
  margin: 20px 0 0 0;
  text-align: justify;
  overflow: hidden;
  clear: both;
  display: block;
}

.fotolegenda {
  font-size: .75em;
  text-align: center;
  line-height: 140%;
  padding: 5px 10px;
  display: block;
}

.fotocreditos {
  font-size: .75em;
  text-align: right;
}

.foto {
  background: white;
  border: 1px solid #ddd;
  padding: 4px;
  border-radius: 3px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  margin-top: 0;
  margin-bottom: 2rem;
  position: relative;
  clear: both;
  font-weight: normal;
}

h1,
h2,
h3,
h4 {
  font-family: 'bebasbold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

h5,
h6 {
  font-family: 'bebasregular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 35px;
  color: #f48137;
}

.swal2-modal h2 {
  color: #575757;
  font-size: 36px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
}

h3 {
  font-size: 50px;
  color: #f48137;
}

h4 {
  font-size: 22px;
  color: #f48137;
}

h5 {
  font-size: 1em;
  color: #f48137;
}

h6 {
  font-size: 1em;
  color: #f48137;
}

.bullet:after {
  content: '\25cf';
  color: #f48137;
}

button,
.btn {
  font-weight: bold;
  text-shadow: 0 0 0;
}

/*======================================================================================
========================================================================================
================================== Start CSS DO SITE ===================================
========================================================================================
======================================================================================*/

.inline-block {
  display: inline-block;
}

button:focus {
  outline: 0;
}

header {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  z-index: 500;
}

header .content-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 13px 0 23px 0;
  width: 100%;
}

.content-header a i {
  color: #f48137;
  -webkit-transition: All 0.3s ease-in-out;
  -o-transition: All 0.3s ease-in-out;
  transition: All 0.3s ease-in-out;
  margin-right: 5px;
  font-size: 20px;
}

a.btnAtendimentoOnline {
  background: rgb(222, 33, 45);
  border-radius: 9px;
  color: #fff;
  padding: 5px 20px;
  font-size: 20px;
  font-weight: 300;
  font-family: 'bebasregular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  ;
  margin-right: 50px;
}

a:hover.btnAtendimentoOnline {
  background: #333;
  border-radius: 9px;
  color: #fff;
}

a.btnBotaoTopo {
  color: #ffffff;
  font-size: 13px !important;
  font-weight: 500;
  font-family: 'bebasregular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  ;
  letter-spacing: -0.4px;
}

a:hover.btnBotaoTopo {
  text-decoration: underline
}

a.btnBotaoTopo span {
  color: rgb(84, 146, 117);
  font-size: 18px !important;
  font-weight: 800;
  font-family: 'bebasregular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  ;
}

.box-opcoesTopo-wrap div a {
  text-align: center;
}

.box-opcoesTopo-wrap div .cart {
  font-size: 21px;
  color: rgb(85, 147, 118);
  padding-top: 5px;
}

header .content-header .item {
  display: inline-block;
}

header .content-header .logo {
  text-align: left;

}

header .content-header .logo img {
  display: inline-block;
  width: 210px;
  height: auto;
}

header .content-header .buscas-topo {
  display: inline-block;
}

header .content-header .buscaTopo {
  position: relative;
  display: inline-block;
  text-align: left;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #cccccc;
}

header .content-header .buscaTopo input {
  width: 360px;
  height: auto;
  border-radius: 5px;
  padding: 7px 15px;
  font-size: 16px;
  border: none;
  color: #333 !important;
  outline: 0;
  background: none;
  font-family: roboto;
  -webkit-box-shadow: 2px 4px 13px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 4px 13px 2px rgba(0, 0, 0, 0.08);
}

header .content-header .buscaTopo input[type="text"]::-webkit-input-placeholder {
  color: darkgray !important
}

header .content-header .buscaTopo input[type="text"]:-ms-input-placeholder {
  color: darkgray !important
}

header .content-header .buscaTopo input[type="text"]::-ms-input-placeholder {
  color: darkgray !important
}

header .content-header .buscaTopo input[type="text"]::placeholder {
  color: darkgray !important
}

.newsletterFooter input {
  -webkit-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14) !important;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14) !important;
  border-radius: 4px;
}

#formContatoApp input,
#formContatoApp select,
#formContatoApp textarea,
#info-cep,
.frete,
#collapseFiltros input,
#collapseFiltros select {
  width: 100%;
  background: #fff;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #cacaca;
  margin-right: 10px;
  padding: 0 15px;
  color: #333 !important;
  outline: 0;
  -webkit-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
}

#formContatoApp textarea {
  height: 120px;
  padding: 15px;
}

.box-opcoesTopo-wrap {
  display: inline-block;
}

.box-opcoesTopo-wrap div {}

.box-opcoesTopo-wrap div:last-child {
  display: inline-block;
  color: #007ec3;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 600;
}

a.btnUserAcesso {
  /* background: #252525; */
  border-radius: 5px;
  /* height: 28px; */
  color: #100c0e;
  padding: 6px 14px 7px 12px;
  font-size: 16px;
  font-weight: 500;
  font-family: roboto;
  display: inline-block;
  -webkit-transition: All 0.3s ease-in-out;
  -o-transition: All 0.3s ease-in-out;
  transition: All 0.3s ease-in-out;
}

.fixcolor:hover {
  color: #f48137 !important
}

.fixcolor:hover i {
  color: #f48137 !important
}

a:hover.btnUserAcesso {
  background: #f48137;
  color: #fff!important;
}

a:hover.btnUserAcesso i {
  color: #fff!important;
}

.box-opcoesTopo-wrap .redes-sociais {
  margin-right: 30px;
}

header .content-header .buscaTopo input::-webkit-input-placeholder {
  color: #f48137;
}

header .content-header .buscaTopo input::-moz-input-placeholder {
  color: #f48137;
}

header .content-header .buscaTopo input::-ms-input-placeholder {
  color: #f48137;
}

header .content-header .buscaTopo input::-webkit-input-placeholder {
  color: #666666c4 !important;
}

header .content-header .buscaTopo input:-ms-input-placeholder {
  color: #666666c4 !important;
}

header .content-header .buscaTopo input::placeholder {
  color: #666666c4 !important;
}

header .content-header .buscaTopo button {
  padding-left: 0;
  font-size: 18px;
  height: 35px;
  border: none;
  position: absolute;
  top: 0px;
  padding: 0 18px 1px 13px;
  right: 0px;
  cursor: pointer;
  border-radius: 5px;
  background: transparent;
  color: #f48137;
}

header .content-header .box-opcoesTopo {
  position: relative;
  display: inline-block;
  text-align: right;
  margin-top: 2px;
}

.busca-avancada-topo {
  position: relative;
}

.busca-avancada-topo a {
  border-radius: 4px;
  background: #f48137;
  height: 45px;
  line-height: 45px;
  max-width: 180px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  padding: 0 20px;
  margin-left: 10px;
  text-decoration: none;
  -webkit-box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: All 0.3s ease-in-out;
  -o-transition: All 0.3s ease-in-out;
  transition: All 0.3s ease-in-out;
}

.busca-avancada-topo a:hover {
  -webkit-box-shadow: inset 0px 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: All 0.3s ease-in-out;
  -o-transition: All 0.3s ease-in-out;
  transition: All 0.3s ease-in-out;
}

header .content-header .redes-sociais a {
  font-size: 23px;
  margin: 0 5px;
}

.i-facebook {
  color: #254a87;
}

.i-instagram {
  color: #c9107f;
}

.i-twitter {
  color: #2aabd2;
}

header .content-header .redes-sociais a:hover {
  opacity: 0.7;
  color: #b8b4af;
}

header .content-header .box-opcoesTopo .text-muted {
  color: #ccc;
}

header .content-header .box-opcoesTopo a {
  color: #f48137;
}

header .content-header .box-opcoesTopo a.login,
header .content-header .box-opcoesTopo a.conta {
  padding-right: 0px;
}

header .content-header .box-opcoesTopo a.cart {
  padding-left: 5px;
  font-size: 23px;
  float: right;
  margin-top: -3px;
}

header .content-header .box-opcoesTopo a span {
  color: #f48137;
}

header .content-header .box-opcoesTopo a:hover,
header .content-header .box-opcoesTopo a:hover span {
  text-decoration: none;
  color: #333;
}

@media (max-width: 1199px) and (min-width: 992px) {
  header .content-header {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }

  header .content-header .buscaTopo input {
    width: 220px;
  }
}

@media (max-width: 992px) {
  header .content-header {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    padding: 20px 0 1px 0;
  }
}

header .box-menuTopo {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  height: 62px;
  background: rgba(244, 127, 55, 1);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(244, 127, 55, 1)), color-stop(100%, rgba(231, 55, 39, 1)));
  background: -webkit-linear-gradient(-45deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -o-linear-gradient(-45deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -webkit-linear-gradient(315deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -o-linear-gradient(315deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: linear-gradient(135deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f47f37', endColorstr='#e73727', GradientType=1);
  -webkit-box-shadow: 2px 4px 13px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 4px 13px 2px rgba(0, 0, 0, 0.08);
}

header .box-menuTopo a.btn-menu-topo-responsive {
  display: none;
}

header .box-menuTopo ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}

header .box-menuTopo ul li {
  display: inline;
  float: left;
  position: relative;
  background-color: none;
  -moz-transition: background-color 0.1s ease-in-out;
  -webkit-transition: background-color 0.1s ease-in-out;
  -ms-transition: background-color 0.1s ease-in-out;
}

header .box-menuTopo ul li a {
  position: relative;
  display: inline-block;
  height: 62px;
  padding: 0px 30px;
  border-left: 1px solid #ffffff47;
  line-height: 63px;
  font-size: 25px;
  letter-spacing: 1px !important;
  font-family: bebasbold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  color: #fff;
  -o-transition: all .11s ease-in-out;
  transition: all .11s ease-in-out;
  -webkit-transition: all .11s ease-in-out;
  text-transform: uppercase;
}

a,
.btn-default {
  -o-transition: all .11s ease-in-out;
  transition: all .11s ease-in-out;
  -webkit-transition: all .11s ease-in-out;
}

header .box-menuTopo ul li a {
  border-right: 0px !important;
}

header .box-menuTopo ul li a:hover,
header .box-menuTopo ul li a:focus,
header .box-menuTopo ul li a.ativado {
  color: #fff;
  background: #100C0E !important;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .box-menuTopo ul li:hover a {
  color: #fff !important;
}

.grupo-caetano {
  display: inline-block;
  float: right;
  width: 130px;
  margin-top: 14px;
}

header .box-opcoesTopo .box-carrrinho {
  display: block;
  position: relative;
  margin-top: 20px;
  height: 60px;
  line-height: 60px;
  color: #f48137;
}

header .box-opcoesTopo .box-carrrinho .badge {
  background: none;
  color: #f48137;
}

header .box-opcoesTopo .box-carrrinho .badge span {
  background: none;
  color: #f48137;
}

header .menuFixo-topo {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: inline-block;
  color: #fff;
  width: 100%;
  height: 60px;
  margin-top: -64px;
  background: rgba(244, 127, 55, 1);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(244, 127, 55, 1)), color-stop(100%, rgba(231, 55, 39, 1)));
  background: -webkit-linear-gradient(-45deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -o-linear-gradient(-45deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -webkit-linear-gradient(315deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -o-linear-gradient(315deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: linear-gradient(135deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f47f37', endColorstr='#e73727', GradientType=1);
  -webkit-box-shadow: 2px 4px 13px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 4px 13px 2px rgba(0, 0, 0, 0.08);
  opacity: 0.95;
}

header .menuFixo-topo .box-logo-bird-menuFixo {
  position: relative;
  display: inline-block;
  height: 20px;
}

header .menuFixo-topo .box-logo-bird-menuFixo img {
  height: 100%;
}

header .menuFixo-topo .box-logo-bird-menuFixo a {
  display: inline-block;
  position: relative;
  height: 42px;
  margin: 10px 0 0;
}

header .menuFixo-topo .box-carrinho-menuFixo {
  width: 100%;
  display: inline-block;
  position: relative;
  text-align: center;
  margin-top: 15px;
  margin-left: 40px;
}

.box-carrinho-menuFixo strong {
  font-size: 15px !important;
}

header .menuFixo-topo .box-carrinho-menuFixo a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  padding: 7px 15px;
  background: #100c0e;
  font-weight: bold;
  border-radius: 4px;
}

header .menuFixo-topo .box-carrinho-menuFixo a:hover {
  color: #f48137;
  background: #ffffff;
}

header .menuFixo-topo .box-carrinho-menuFixo a span {
  padding: 3px 10px;
  background: #f48137;
  color: #fff;
  border-radius: 50px;
}

header .menuFixo-topo .btn-menu-responsive-menuFixo a {
  float: right;
  margin: 15px 0 0;
}

header .menuFixo-topo .btn-menu-responsive-menuFixo a span {
  font-size: 30px;
  color: #fff;
}

.showMenuFixo {
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  header .menuFixo-topo .box-carrinho-menuFixo {
    margin-left: 0;
    margin-top: 17px;
    margin-bottom: -4px;
  }

  header .content-header {
    padding: 15px 0;
  }

  header .menuFixo-topo .box-carrinho-menuFixo .inline-block {
    display: none;
  }

  header .content-header .buscas-topo .buscaTopo {
    margin: 0 0 10px 0;
  }

  header .content-header .buscas-topo {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0px 0 22px 0;
  }

}

@media (max-width: 480px) {}

.ati-home .box-sessao-logo,
.ati-institucional .box-sessao-logo,
.ati-blog .box-sessao-logo,
.ati-contato .box-sessao-logo,
.ati-login .box-sessao-logo,
.ati-home .menuInt {
  display: none !important;
}

.ati-home .nav-home,
.ati-riccajoias .nav-riccajoias,
.ati-produtos .nav-produtos,
.ati-lista .nav-listas,
.ati-noticia .nav-promo,
.ati-contato .nav-contato,
.ati-home .nav-home>a,
.ati-riccajoias .nav-riccajoias>a,
.ati-produtos .nav-produtos>a,
.ati-lista .nav-listas>a,
.ati-noticia .nav-promo>a,
.ati-contato .nav-contato>a {
  color: #fff !important;
  background: #f48137 !important;
}

#passarinho {
  left: 220px;
  top: 21px;
  width: 90px;
  position: absolute;
  z-index: 99;
  -webkit-animation: volar 6s;
  animation: volar 6s;
}

@-webkit-keyframes volar {
  0% {
    left: -200px;
    top: -100px;
  }

  25% {
    top: 100px;
  }

  50% {
    top: 50px;
  }

  75% {
    top: 25px;
  }

  100% {
    left: 220px;
    top: 21px;
  }
}

@keyframes volar {
  0% {
    left: -200px;
    top: -100px;
  }

  25% {
    top: 100px;
  }

  50% {
    top: 50px;
  }

  75% {
    top: 25px;
  }

  100% {
    left: 220px;
    top: 21px;
  }
}

.mm-menu {
  background: rgba(244, 127, 55, 1);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(244, 127, 55, 1)), color-stop(100%, rgba(231, 55, 39, 1)));
  background: -webkit-linear-gradient(-45deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -o-linear-gradient(-45deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -webkit-linear-gradient(315deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: -o-linear-gradient(315deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  background: linear-gradient(135deg, rgba(244, 127, 55, 1) 0%, rgba(231, 55, 39, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f47f37', endColorstr='#e73727', GradientType=1);
}

.mm-menu.mm-current {
  z-index: 0;
}

.mm-navbar.mm-hasbtns {
  padding: 0 20px;
  background-color: rgba(0, 0, 0, .5);
}

.mm-menu .mm-navbar>*,
.mm-menu .mm-navbar a {
  color: #f48137 !important;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next),
.mm-menu .mm-listview>li.mm-selected>span,
.mm-menu .mm-listview>li:hover>a:not(.mm-next),
.mm-menu .mm-listview>li:hover>span {
  background: rgba(0, 0, 0, 0.5);
}

.mm-listview>li>a,
.mm-listview>li>span {
  color: #fff !important;
}

.mm-listview>li,
.mm-listview>li:after,
.mm-listview>li .mm-next,
.mm-listview>li .mm-next:before {
  border-color: rgba(255, 255, 255, 0.1);
}

.mm-listview>li>a:hover,
.mm-listview>li>span:hover {
  color: #fff !important;
}

@media (min-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
  }
}

ul.menuPrincipal li ul {
  background: #fff !important;
  padding: 0;
  margin: 0px;
  list-style: none;
  display: none;
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100%;
  height: auto;
  -webkit-transition: all 0.4021s ease;
  z-index: 99;
  border-radius: 0 0 3px 3px;
  -webkit-box-shadow: 6px 9px 13px 2px rgba(0, 0, 0, 0.14);
  box-shadow: 6px 9px 13px 2px rgba(0, 0, 0, 0.14);
}

/* ul.menuPrincipal li:last-child ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: none;
    position: absolute;
    left: auto;
    right: 0px;
    top: 100%;
    background: none;
    width: 100%;
    height: auto;
    -webkit-transition: all 0.4021s ease;
    z-index: 9999;
}
 */
ul.menuPrincipal li:hover>ul {
  display: block;
  padding: 10px 0 0;
  /*width: 100%;*/
  width: 250px;
}

ul.menuPrincipal li ul li {
  float: left;
  clear: both;
  border: none;
  background: none;
  position: relative;
  margin: 0px;
  padding: 0px;
  width: 100%;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

ul.menuPrincipal li ul li a {
  float: left;
  color: #fff;
  clear: both;
  width: 100%;
  height: auto !important;
  line-height: 40px !important;
  background: none;
  text-decoration: none;
  margin: 0px;
  text-transform: none;
  font-size: 17px;
}

ul.menuPrincipal li ul li {
  background: none;
}

ul.menuPrincipal li ul li:hover {
  background: #007ec3;
}

ul.menuPrincipal li ul li a {
  color: #fff;
  text-align: left;
}

ul.menuPrincipal li ul li a:hover {
  float: left;
  clear: both;
  color: #fff !important;
  background: #007ec3 !important;
}

ul.menuPrincipal li ul li ul {
  display: none;
  position: absolute;
  left: 225px;
  width: auto !important;
  top: 0px;
  padding: 0px;
  margin: 0px;
  list-style: none;
  background: none;
  height: auto;
  -webkit-transition: all 0.4021s ease;
  z-index: 99999;
}

ul.menuPrincipal li:last-child ul li ul {
  display: none;
  position: absolute;
  left: -225px;
  width: 225px !important;
  top: 0px;
  padding: 0px;
  margin: 0px;
  list-style: none;
  background: none;
  width: auto;
  height: auto;
  -webkit-transition: all 0.4021s ease;
  z-index: 99999;
}

ul.menuPrincipal li ul li:hover>ul {
  display: block;
}

.content-slider {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  padding: 0;
  margin-bottom: 60px;
}

.content-slider .owl-prev {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-150px);
  -ms-transform: translateX(-150px);
  transform: translateX(-150px);
  bottom: -15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  z-index: 10;
}

.content-slider .owl-next {
  position: absolute;
  right: 50%;
  -webkit-transform: translateX(150px);
  -ms-transform: translateX(150px);
  transform: translateX(150px);
  bottom: -15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  z-index: 10;
}

.content-slider .owl-prev span {
  width: 40px;
  height: 40px;
  padding: 13px 0 0 8px;
  display: block;

}

.content-slider .owl-prev:hover {
  background: #f48137;
}

.content-slider .owl-prev:hover span {
  color: #fff;
}

.content-slider .owl-next span {
  width: 40px;
  height: 40px;
  display: block;
  padding: 13px 0 0 10px;
}

.content-slider .owl-next:hover {
  background: #f48137;
}

.content-slider .owl-next:hover span {
  color: #fff;
}

.content-slider .owl-dots {
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  padding-top: 40px;
}

.content-slider .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  display: inline-block;
  margin: 0 2.5px;
  border-radius: 30px;
}

.content-slider .owl-dots .owl-dot.active {
  background: #f48137;
}

.content-box-banners {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .content-slider .owl-prev {
    bottom: -55px;
  }

  .content-slider .owl-next {
    bottom: -55px;
  }

  .content-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-flow: column !important;
    flex-flow: column !important;
  }

  header .content-header .buscaTopo input {
    width: 280px !important;
  }

  header .content-header .buscaTopo button {
    padding-left: 5px;
    padding-right: 5px;
  }

  a.btnAtendimentoOnline {
    background: rgb(222, 33, 45);
    border-radius: 5px;
    color: #fff;
    padding: 5px 20px;
    font-size: 15px;
    font-weight: 300;
    font-family: 'bebasregular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    ;
    margin-right: 50px;
    text-align: center;
    display: block;
    margin: 0 auto 30px auto;
  }
}

.content-box-banners .box-banner-grande {
  width: 50%;
  width: calc(50% - 10px);
  width: -ms-calc(50% - 10px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.content-box-banners .box-banner-grande::before {
  background: url("../images/bg-arrow-box-left.png") no-repeat center center;
  position: absolute;
  background-size: contain;
  height: 16px;
  width: 144px;
  left: 0px;
  bottom: 13px;
  content: " ";
  display: block;
}

.content-box-banners .box-banner-grande::after {
  background: url("../images/bg-arrow-box-right.png") no-repeat center center;
  background-size: contain;
  height: 16px;
  width: 144px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  content: " ";
  display: block;
}

.content-box-banners .box-banner-medio {
  width: 30%;
  width: calc(33.333% - 15px);
  width: -ms-calc(33.333% - 15px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.content-box-banners .box-banner-medio::before {
  background: url("../images/bg-arrow-box-left.png") no-repeat center center;
  position: absolute;
  background-size: contain;
  height: 10px;
  width: 100px;
  left: 0px;
  bottom: 13px;
  content: " ";
  display: block;
}

.content-box-banners .box-banner-medio::after {
  background: url("../images/bg-arrow-box-right.png") no-repeat center center;
  background-size: contain;
  height: 10px;
  width: 100px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  content: " ";
  display: block;
}

.content-box-banners .box-banner-grande h2,
.content-box-banners .box-banner-medio h2 {
  text-shadow: 0 2px 5px #ccc;
}

.content-box-banners .box-banner-grande .box-img-banner,
.content-box-banners .box-banner-medio .box-img-banner {
  display: block;
  position: relative;
  margin-bottom: 20px;
  background: #fff;
}

.content-box-banners .box-banner-grande .box-img-banner img,
.content-box-banners .box-banner-medio .box-img-banner img {
  display: block;
  position: relative;
  margin: 0px;
  max-width: 100%;
  opacity: 0.75;
}

.content-box-banners .box-banner-grande .box-img-banner::before,
.content-box-banners .box-banner-medio .box-img-banner::before {
  content: "";
  display: block;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  width: -webkit-calc(100% - 20px);
  height: -webkit-calc(100% - 20px);
  width: -ms-calc(100% - 20px);
  height: -ms-calc(100% - 20px);
  width: -moz-calc(100% - 20px);
  height: -moz-calc(100% - 20px);
  border: 1px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.content-box-banners .box-banner-grande a {
  color: #f48137;
  font-size: 28px;
  text-decoration: none;
}

.content-box-banners .box-banner-medio a {
  color: #f48137;
  font-size: 22px;
  text-decoration: none;
}

.filtro {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  margin: 20px 0 10px 0;
}

.filtro .menuCategorias {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  border-top: 1px solid #f48137;
  border-bottom: 1px solid #f48137;
  padding: 15px 0;
}

.filtro .menuCategorias ul {
  list-style: none;
  padding: 0;
  text-align: center;
  margin: 0;
}

.filtro .menuCategorias ul li {
  display: inline-block;
  position: relative;
  padding: 10px;
}

.filtro .menuCategorias ul li:hover {
  cursor: pointer;
}

.filtro .menuCategorias ul li.black-button {
  border-radius: 3px;
  margin-left: 15px;
}

.filtro .menuCategorias ul li.black-button:before {
  left: -15px !important;
}

.filtro .menuCategorias ul li.black-button {
  background: #282828 !important;
  color: white;
  font-weight: bold;
}

.filtro .menuCategorias ul li.black-button:hover a {
  color: #f52222;
  background: none !important;
}

.filtro .menuCategorias ul li:first-child:before {
  display: none;
}

.filtro .menuCategorias ul li:before {
  content: ".";
  color: #f48137;
  position: absolute;
  left: -5px;
  top: 6px;
}

.filtro .menuCategorias ul li:first-child:before {
  display: none;
}

.filtro .menuCategorias ul li a {
  color: #f48137;
}

.filtro .menuCategorias ul li a:hover {
  background: #f48137;
  color: #fff;
  text-decoration: none;
}

.filtro .menuCategorias ul li ul {
  display: none;
}

.filtro .menuCategorias ul li:hover {
  border-radius: 3px;
  padding: 10px;
  background: #f48137;
}

.filtro .menuCategorias ul li:hover a {
  color: #fff;
}

.filtro .menuCategorias ul li:hover ul {
  width: 350px;
  display: block;
  background: #f48137;
  border-radius: 0 3px 3px 3px;
  position: absolute;
  right: 0px;
  top: 35px;
  padding: 10px 10px 20px 10px;
  margin: 0;
  list-style: none;
  z-index: 999;
}

.filtro .menuCategorias ul li:hover ul li {
  display: inline-block;
  vertical-align: top;
  width: -ms-calc(50% - 20px);
  position: relative;
  text-align: left;
  padding: 0px;
}

.filtro .menuCategorias ul li:hover ul li::before {
  content: none;
}

.filtro .menuCategorias ul li:hover ul li a {
  text-decoration: none;
  color: #fff;

  line-height: 30px;
}

.filtro .menuCategorias ul li:hover ul li a:hover {
  color: #f48137;
  background: none;
}

.filtro .menuCategorias ul li:nth-child(1) ul,
.filtro .menuCategorias ul li:nth-child(2) ul,
.filtro .menuCategorias ul li:nth-child(3) ul,
.filtro .menuCategorias ul li:nth-child(4) ul,
.filtro .menuCategorias ul li:nth-child(5) ul,
.filtro .menuCategorias ul li:nth-child(6) ul,
.filtro .menuCategorias ul li:nth-child(7) ul {
  left: 0;
  right: auto;
}

.filtro .filtroCampos {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  text-align: center;
  padding: 25px 0;
}

.filtro .filtroCampos h3 {
  width: auto;
  font-size: 25px;
  display: inline-block;
  position: relative;
}

.filtro .filtroCampos form {
  display: inline-block;
}

.filtro .filtroCampos form ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.filtro .filtroCampos form ul li {
  display: inline-block;
  position: relative;
  color: #999;
  font-size: 14px;

  padding: 0 20px;
}

.filtro .filtroCampos form ul li label {
  font-weight: 400;
  margin-bottom: 5px;
}

.filtro .filtroCampos form ul li label select {
  background: #fff;
  padding: 10px;
  height: 40px;
  width: 170px;
  border: none;
  border-radius: 0px !important;
}

.filtro .filtroCampos form ul li:last-child {
  margin: 0px;
}

.filtro .menuCategoria-mobile {
  display: none;
}

.listBanner {
  list-style: none;
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}

.listBanner li {
  display: inline-block;
  position: relative;
  margin: 0 0 20px 0;
  vertical-align: top;
}

.listBanner li img {
  max-width: 100%;
  position: relative;
  display: block;
  clear: both;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.listBanner li img:hover {
  -webkit-box-shadow: #b4b4b4 0 4px 15px;
  box-shadow: #b4b4b4 0 4px 15px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.listProdutos {
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 0;
}

.listagemFix {
  background: url(/assets/img/bgfix.png) top center no-repeat;
  padding-top: 10px
}

.listagem .listProdutos li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border: 2px solid #DDDDDD;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}

.listProdutos li {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 390px !important;
  margin-bottom: 20px;
  border: 1px solid #DDDDDD;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.14);
}

.listProdutos-interno li a {
  padding: 20px 20px 30px 20px;
}

.listProdutos li a {
  text-align: center;
  display: block;
  position: relative;
  width: 100%;

  height: 100%;
  overflow: hidden;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;

  padding-bottom: 40px;
}

.listProdutos li a hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.listProdutos li a .box-imgProd {
  position: relative;
  width: 100%;
  height: 215px;
  display: block;
  clear: both;
  float: none;
  text-align: center;
  overflow: hidden;
}

.listProdutos li a .box-imgProd img {
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: inline-block;
  position: relative;
  max-height: 240px;
  margin-top: 21px;
}

.listProdutos li a p {
  padding: 0px 26px;
  color: #313131;
  font-family: roboto;
  text-transform: initial;
  line-height: 30px;
  font-size: 22px;
}

.listProdutos li a p strong {
  padding-top: 5px;
  display: inline-block;
  color: #f48137;
  font-size: 17px;
  letter-spacing: 0;
  font-weight: 500;
}

.listProdutos li a p span {
  color: #f48137;
}

p.preco-de {
  height: 19px !important;
  font-size: 17px !important;
}

.listProdutos li a:hover {
  border-color: 2px solid #ef7a2d;
  text-decoration: none;
}

.listProdutos li .box-addCarrinho {
  width: 100%;
  height: 32px;
  bottom: -40px;
  left: 0px;
  position: absolute;
  z-index: 99;
  background: #f28135;
  border: none;
  overflow: hidden;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
}

.listProdutos li .box-addCarrinho span {
  display: inline-block;
  width: 100%;
  min-height: 0;
  line-height: 30px;
  text-decoration: none;
  color: #fff;
  font-size: 15px;
}

.listProdutos li .box-addCarrinho span .fa {
  color: #fff;
}

.listProdutos li:hover {
  border: 2px solid #f17d30;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
}

.listProdutos li:hover .box-addCarrinho {
  display: inline-block;
  bottom: 0px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.listProdutos li a.box-addLista {
  display: block;
  width: 80px;
  min-height: inherit;
  height: 80px !important;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 100%;
  position: absolute;
  background: #24c02e;
  border: none;
  text-align: center;
  color: #fff;
  padding-top: 12px;
  text-decoration: none;
  z-index: 98;
}

.listProdutos li a.box-addLista:hover {
  -webkit-box-shadow: 0 0 2px #e7e7e7;
  box-shadow: 0 0 2px #e7e7e7;
  display: block;
}

.listProdutos li a.box-addLista p {
  white-space: nowrap;
  width: 100%;
  height: 100%;
  min-height: inherit;
  line-height: 14px;
  margin-top: 5px;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
  margin-left: -2px;
}

@media (min-width: 1200px) {}

.box-instagram {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  margin-bottom: 45px;
}

.box-instagram .box-insta {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
}

.box-instagram .box-insta .box-endereco-insta {
  width: 100%;
  position: relative;
  display: block;
  text-align: center;
  padding: 10px 0;
}

.box-instagram .box-insta .box-endereco-insta a {
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.box-instagram .box-insta .box-endereco-insta a:hover {
  color: #f48137 !important;
  text-decoration: none;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.box-instagram .box-insta .bcr_ins_gallery {
  width: 100%;
  text-align: center;
  display: inline-block;
  position: relative;
  margin: 30px 0;
}

.box-instagram .box-insta .bcr_ins_gallery .ins_img {
  width: 16.666%;
  display: inline-block;
  position: relative;
}

footer {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  padding: 0;
  background: url('/assets/img/bgrodape.jpg');
  background-size: cover;
}

footer .btn-servicos-footer {
  position: relative;
  font-family: 'Open Sans';
  display: inline-block;
  width: 100%;
  height: 55px;
  margin-bottom: 30px;
  line-height: 51px;
  font-size: 15px;
  color: #f48137;
  text-align: center;
  text-decoration: none;
  border: 2px solid #f48137;
  border-radius: 4px;
  font-weight: 600;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

footer .btn-servicos-footer i {
  font-size: 30px;
  float: left;
  display: inline-block;
  padding-top: 12px;
  padding-right: 7px;
}

footer .btn-servicos-footer span {
  display: inline-block;
}

footer .btn-servicos-footer img {
  float: right;
  margin: 10px 15px 0 0;
}

footer .btn-servicos-footer:hover {
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  color: #f48137;
  border-color: #f48137;
}

footer .box-selos {
  text-align: center;
  display: inline-block;
  width: 100%;
  position: relative;
}

footer .box-selos img {
  max-width: 100%;
  text-align: center;
}

footer .informativos {
  position: relative;
  padding-top: 40px;
}

footer .newsletterFooter {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  color: #333;
  float: none;
  margin-bottom: 20px;
}

footer .newsletterFooter p {
  display: block;
  margin-bottom: 10px;
}

footer .newsletterFooter input[type=text],
footer .newsletterFooter input[type=email] {
  background: #fff;
  border: 1px solid #ccc;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  height: 45px;
  line-height: 45px;
  /* padding: 0 0 0 10px; */
  width: auto;
  display: inline-block;
}

footer .newsletterFooter input[type=email] {
  padding-right: 47px;
}

footer .newsletterFooter button {
  position: absolute;
  bottom: 15px;
  width: 25px;
  height: 25px;
  border: none;
  background: url(/assets/img/icon-check.png) no-repeat center center;
  background-size: 25px 25px;
  cursor: pointer;
  margin-left: -35px;
}

footer .newsletterFooter input[type=text]:focus,
footer .newsletterFooter input[type=email]:focus {
  border: 1px solid #007ec3;
  -webkit-box-shadow: inset 1px 1px 4px 0px rgba(50, 50, 50, .2);
  box-shadow: inset 1px 1px 4px 0px rgba(50, 50, 50, .2);
  background: #fff;
}

footer .box-formasPagamento {
  position: relative;
  display: inline-block;
  float: none;
  clear: both;
  margin-right: 100px;
  color: #333;
}

footer .box-formasPagamento p {
  display: block;
  margin-bottom: 10px;
}

footer .box-formasPagamento ul {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  list-style: none;
  padding: 0px;
  margin: 0;
}

footer .box-formasPagamento ul li {
  display: inline-block;
}

footer .copyright {
  font-size: 14px;
  color: #fff;
}

footer .logo-grupo-caetano {
  display: block;
  text-align: center;
}

footer .logo-grupo-caetano img {
  width: 144px;
  height: 37px;
}

footer .footer-social {
  display: block;
  margin-top: 8px;
  text-align: center;
}

footer .footer-social a {
  font-size: 24px;
  letter-spacing: 8px;
  color: #fff;
}

footer .logoIlion {
  position: relative;
  float: right;
  margin-top: 5px;
}

footer .logoIlion img {
  width: 90px;
}

footer .informativos .nav-footer {
  margin: 0;
}

footer .informativos .central-de-atendimento a,
footer .informativos nav.nav-footer a {
  display: block;
  text-decoration: none;
  outline: none;
  color: #7d7d7d;
  font-weight: 600;
}

footer .informativos .central-de-atendimento a:hover,
footer .informativos nav.nav-footer a:hover {
  color: #ffffff;
}

footer .informativos nav.nav-footer {
  -webkit-column-count: 1;
  column-count: 1;
  -webkit-column-gap: 20px;
  column-gap: 20px;
}

footer .informativos nav.nav-footer h3 {
  display: block !important;
}

footer .informativos h3 {
  font-size: 30px;
}

footer .informativos .menu-footer ul {
  display: block;
  list-style: none;
  color: #888;
  padding: 0px;
  margin-bottom: 30px;
}

footer .informativos .menu-footer ul li a {
  color: #888;
  font-size: 16px;
  text-decoration: none;
}

footer .informativos .menu-footer ul li a:hover {
  color: #f48137;
}

@media (max-width: 991px) {
  footer section.informativos {
    text-align: center;
  }

  footer .newsletterFooter {
    margin-top: 30px;
  }

  footer #sslblindado {
    margin: 0 auto;
  }

  .item.logo-wrap {
    margin-bottom: 21px;
  }
}

@media (max-width: 767px) {
  footer .newsletterFooter input {
    display: block !important;
    width: 100% !important;
    margin: 0 0 20px 0;
  }

  footer .newsletterFooter button {
    right: 10px;
    bottom: 30px;
  }

  footer .copyright {
    margin: 20px 0;
    text-align: center;
  }

  footer .footer-social {
    margin: 20px 0px;
  }

  footer .logoIlion {
    margin-bottom: 30px;
  }

  .item.logo-wrap {
    margin-bottom: 0px;
  }
}

.bloco-valores p {
  color: #666 !important;
  font-size: 15px !important;
}

.bloco-valores strong {
  font-size: 16px !important;
  color: #000 !important;
}

/*====================================================================================
====================================================================================*/

/*====================================================================================
======================================================================================
====================================== Int Site ======================================
======================================================================================
====================================================================================*/

.breadcrumb {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  float: left;
  /* height: 40px; */
  border-radius: 0px !important;
  background: none !important;
  margin: 0;
  padding: 9px 0 9px 0px;
}

.breadcrumb p {
  color: #007ec3;
  text-align: left;
  margin-top: 8px;
  font-size: 13px;
  font-weight: 600;
}

.breadcrumb p a {
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.breadcrumb p a:hover {
  color: #f48137;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  text-decoration: none;
}

.section-intro-image {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  float: left;
}

.box-image-intro {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  padding: 0;
  margin-top: 15px;
}

.box-image-intro img {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;

}

.box-image-intro h1 {
  font-size: 45px;
  display: none;
  text-align: center;
}

.section-article {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  padding: 0 0 0 0;
}

.section-article .content-article {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  padding: 40px 0;
  border-top: none;
  float: left;
}

@media (max-width: 550px) {
  .section-article .content-article:first-child {
    overflow-x: auto;
  }
}

.section-article .content-article aside {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  font-family: 'Montserrat';
}

.section-article .content-article article {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  color: #777;
  line-height: 22px;
}

.section-article .content-article article img {
  max-width: 100% !important;
}

.section-article .content-article article p {
  margin-bottom: 10px;
  line-height: 26px;
  color: #2d2d2d;
  font-size: 18px;
}

.section-article .content-article article p .inicialMaior {
  text-transform: capitalize;
  font-size: 50px;
}

.section-article .content-article article a {
  font-weight: 500;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  color: #f48137;
  font-size: 16px;
}

.section-article .content-article article a .icon-trash {
  font-weight: 500;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  color: #fff;
}

.section-article .content-article article a:hover {
  color: #f48137;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.section-article .content-article article ul,
.section-article .content-article article ol {
  padding: 0 0 0 0px !important;
  margin-bottom: 20px;
}

.section-article .content-article article h2.titulo-sessao {
  font-size: 40px;
  display: inline-block;
  width: auto;
  color: #f48137;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  text-align: center;
  padding: 0 20px;
  background: #fff;
  top: -70px;
}

.section-article .content-article article h3 {
  font-size: 43px;
}

.section-article .content-article article h4 {
  font-size: 25px;
}

.section-article .content-article article h5 {
  font-size: 20px;
}

.section-article .content-article article h6 {
  font-size: 15px;
}

.section-article .content-article article form label {
  font-size: 15px;
  color: #999;

  margin-top: 7px;
}

.section-article .content-article article form input[type=text],
.section- article .content-article article form input[type=email],
.section-article .content-article article form input[type=tel],
.section-article .content- article article form input[type=password],
.section-article .content-article article form textarea,
.section-article .content-article article form select,
.section-article .content-article form input[type=text],
.section-article .content-article form input[type=email],
.section-article .content-article form input[type=tel],
.section-article .content-article form input[type=password],
.section-article .content-article form select,
.section- article .content-article form textarea {
  /* border-radius: 0;     border:
none;     background: #fff;     box-shadow: inherit;     margin-bottom: 10px;
display: block;     height: 40px; */
}

.section-article .content-article article form button,
.section-article .content-article form button {
  padding: 10px 20px;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  -moz-transition: all .17s ease-in-out;
  -webkit-transition: all .17s ease-in-out;
  -ms-transition: all .17s ease-in-out;
  background: rgb(16, 12, 14);
}

.section-article .content-article article form button:hover {
  background: #f48137;
}

.section-article .content-article article form textarea {
  width: 100%;
  min-height: 80px;
}

.section-article .content-article article form select {
  display: block;
  width: 100%;
  /*border: none;*/
  background: #fff;
  height: 40px;
  color: #999;
  margin-bottom: 5px !important;
}

.section-article .content-article article form .forma_de_contato label {
  margin-right: 20px !important;
  margin-bottom: 10px !important;
}

form.form-padrao input[type=text],
form.form-padrao input[type=email],
form.form-padrao input[type=tel],
form.form-padrao input[type=password],
form.form-padrao textarea,
form.form-padrao select,
form.form-padrao input[type=text],
form.form-padrao input[type=email],
form.form-padrao input[type=tel],
form.form-padrao input[type=password],
form.form-padrao select,
form.form-padrao textarea {
  border-radius: 3px;
  border: none;
  background: #fff;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  margin-bottom: 5px;
  display: block;
  height: 40px;
}

form.form-padrao button,
form.form-padrao button {
  border: 2px solid #f48137;
  padding: 10px 20px;
  font-size: 15px;
  background: #fff;
  border-radius: 0;
  color: #f48137;
  text-transform: uppercase;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

form.form-padrao button:hover {
  border-color: #f48137;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  color: #f48137;
  background: white;
}

form.form-padrao textarea {
  width: 100%;
  min-height: 80px;
}

form.form-padrao select {
  display: block;
  width: 100%;
  border: none;
  background: #fff;
  height: 35px;
  color: #999;
  margin-bottom: 10px !important;
}

form.form-padrao label {
  margin-right: 20px !important;
  margin-bottom: 10px !important;
}

a.forget-pass {
  font-size: 11px;
  text-decoration: none;
  color: #989898 !important;
  font-weight: 300 !important;
}

/*====================================================================================
======================================================================================
=================================== PRODUTOS PAGE ===================================*/

.box-titulo-filtro {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  margin: 20px 0 0;
}

.box-titulo-filtro h2 {
  font-size: 40px;
  color: #8d5500;
  background: #fff;
  display: inline-block;
  width: auto;
  left: 50%;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  padding: 10px 20px;
  z-index: 9;
}

.box-titulo-filtro::after {
  content: "";
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: 8;
}

.listProdutos-interno li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 31.5%;
  margin-bottom: 20px;
  margin-right: 0;
  border: 1px solid #e9e9e9;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;

}

.listProdutos-interno li+li {
  margin-left: 15px;
}

.listProdutos-interno li:nth-child(3n+1) {
  clear: both;
}

.listProdutos-interno li.black-friday {
  position: relative;
  overflow: hidden;
}

.listProdutos-interno li.black-friday:before {
  content: "Black Friday";
  color: #FFF;
  padding: 10px 60px;
  background: #282828;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 25px;
  left: -50px;
  z-index: 99;
}

.listProdutos-interno li:nth-child(3n+1) {
  margin-left: 0;
}

.box-paginacao {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}

.box-paginacao ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: inline-block;
}

.box-paginacao ul li {
  display: inline-block;
  padding: 0 10px;
  color: #f48137;
  font-weight: bold;
  font-size: 18px;
}

.box-paginacao ul li a {
  color: #777;
  font-size: 15px;
  font-weight: normal;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.box-paginacao ul li a:hover {
  color: #f48137;
  text-decoration: none;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.labelNovidade {
  background: #f48137;
  color: #fff;
  border-radius: 3px;
  padding: 3px 5px;
  font-size: 12px;
  margin-bottom: 5px;
  display: inline-block;
}

/*====================================================================================
======================================================================================
=================================== PRODUTO PAGE ===================================*/

#carrinhoApp>.row {
  margin-bottom: 35px;
}

#slider-produto>#slider ul.slides li {
  position: relative;
  list-style-type: none;
  height: 500px !important;
  background: #fff;
}

#slider-produto>#carousel ul.slides li {
  position: relative;
  list-style-type: none;
  background: #fff;
  height: 150px !important;
  width: 180px !important;
  cursor: pointer;
}

#slider-produto>#slider ul li img,
#slider-produto>#carousel ul li img {
  position: absolute;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  height: 100%;
  min-height: 100%;
}

#slider-produto>#carousel ul li img {
  width: 70%;
}

.produtos-relaticonados {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  margin: 40px 0;
}

.produtos-relaticonados .box-titulo-relacionados {
  text-align: center;
}

.produtos-relaticonados .box-titulo-relacionados h2 {
  font-size: 40px;
  color: #5C4241;
  background: #fff;
  width: auto;
  display: inline-block;
  text-align: center;
  padding: 10px 20px;
  z-index: 9;
}

.produtos-relaticonados::after {
  content: "";
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: 8;
}

h2.nomeProduto {
  margin-top: 40px;
  font-size: 65px;
  color: #000000;
  font-family: bebasbold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  margin-bottom: 30px;
}

.precoProduto {
  color: #f48137;
  font-size: 27px;
  font-weight: 700;
}

.precoProduto span {
  font-size: 23px;

}

.ofertaProduto {
  font-size: 14px;
  font-style: italic;
  color: #b3b3b3;

}

.box-calculo-frete {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
}

.box-calculo-frete input[type=text] {
  width: 100%;
  padding: 0 0 0 10px;
  border: none;
  border-radius: 3px;
  background: #fff;
  height: 40px;
}

.box-calculo-frete button {
  position: absolute;
  right: 10px;
  top: 7px;
  border: none;
  background: none;
  color: #f48137;
  font-style: italic;
}

.container-boxQuant {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
}

.container-boxQuant .box-quantitdade {
  width: 94px;
  height: 60px;
}

.container-boxQuant .box-quantitdade input[type=text] {
  width: 60px;
  height: 40px;
  border: 1px solid #e9e9e9;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0px;
  text-align: center;
  font-size: 25px;
  color: #777;
  top: 20px;
}

.container-boxQuant .box-quantitdade button.maisUnid {
  top: 20px;
  position: absolute;
  left: 60px;
  border: none;
  background: #fff;
  width: 30px;
  height: 20px;
  border-radius: 0 3px 0 0;
  color: #f48137;
  padding: 0px;
  border-bottom: 1px solid #fff;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.container-boxQuant .box-quantitdade button.maisUnid:hover {
  color: #fff;
  background: #f28888;
}

.container-boxQuant .box-quantitdade button.menosUnid {
  top: 40px;
  width: 30px;
  height: 20px;
  position: absolute;
  border-top: 1px solid #fff;
  left: 60px;
  border: none;
  background: #fff;
  border-radius: 0 0 3px 0;
  color: #f48137;
  padding: 0px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.container-boxQuant .box-quantitdade button.menosUnid:hover {
  color: #fff;
  background: #f28888;
}

.container-boxQuant .box-quantitdade button.maisUnid:focus,
.container-boxQuant .box-quantitdade button.menosUnid:focus,
.container-boxQuant .box-quantitdade input[type=text]:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.box-tamanhos,
.box-cores {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
}

.box-tamanhos label,
.box-cores label {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  color: #a6a6a6;
  font-weight: normal;
}

.box-tamanhos label select,
.box-cores label select {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  background: #fff;
  border: none;
  height: 40px;
}

.box-opcoes {

  color: #a6a6a6;
}

a.btn-add-lista {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  border: 2px solid #BCA9A5;
  font-size: 15px;

  text-transform: uppercase;
  color: #BCA9A5;
  padding: 20px 0 20px 10px;
  background: url("../images/icone-cart-lista.jpg") no-repeat right center;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

a.btn-add-lista:hover {
  text-decoration: none;
  color: #735a55;
  border-color: #735a55;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

a.btn-add-carrinho {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  border: 2px solid #f48137;
  font-size: 15px;

  text-transform: uppercase;
  color: #f48137;
  padding: 20px 0 20px 10px;
  line-height: 18px;
  background: url("../images/icone-cart-cesta.jpg") no-repeat right center;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

a.btn-add-carrinho:hover {
  text-decoration: none;
  color: #f48137;
  border-color: #f48137;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

a.btn-concluir-compra {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  border: 2px solid #5c9907;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #5c9907;
  padding: 20px 0 20px 10px;
  line-height: 18px;
  text-align: center;
  background: none;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

a.btn-concluir-compra:hover {
  text-decoration: none;
  color: #88e20a;
  border-color: #88e20a;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

#info-cep {
  border-radius: 3px;
  height: 40px !important;
  width: 175px !important;
  margin-right: 10px !important;
}

#info-frete {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

#info-pedra {
  color: #f29129 !important;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
  display: block;
}

/*====================================================================================
======================================================================================
===================================== LISTAS =======================================*/

.box-image-destaque {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  margin: 0;
  height: 100px;
}

.icone-bebe {
  background: url("../images/icone-bebe.png") no-repeat center center;
}

.icone-casamento {
  background: url("../images/icone-casamento.png") no-repeat center center;
}

.btn-criar-lista,
.btn-acessar-lista {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
  border: 2px solid #BCA9A5;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #BCA9A5;
  margin-bottom: 20px;
  padding: 20px 5px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.btn-criar-lista:hover,
.btn-acessar-lista:hover {
  background: #BCA9A5;
  color: #fff;
  text-decoration: none;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.box-busca-lista {
  width: 100%;
  display: inline-block;
  clear: both;
  position: relative;
}

.box-busca-lista h3 {
  font-size: 30px;
}

.box-busca-lista input {
  background: #fff;
  height: 40px;
  width: 100%;
  display: block;
  margin: 0 0 10px 0;
  border: none;
  padding: 0 10px;
  border-radius: 3px;
}

.box-busca-lista button,
.box-busca-lista a {
  background: none;
  border: 2px solid #f48137;
  color: #f48137;
  padding: 10px 15px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  text-transform: uppercase;
}

.box-busca-lista button:hover,
.box-busca-lista a:hover {
  border: 2px solid #f48137;
  color: #f48137;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  text-decoration: none;
}

.tabela-lista th {
  text-align: center !important;
  color: #333;
  font-size: 14px !important;
  line-height: 16px !important;
}

.tabela-lista th:first-child {
  text-align: left !important;
}

/*====================================================================================
======================================================================================
===================================== CARRINHO =====================================*/

.tabela-carrinho td {
  vertical-align: middle !important;
}

.tabela-carrinho input.frete {
  width: auto;
  background: #fff;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #cacaca;
  margin-right: 10px;
  padding: 0 15px;
  color: #333 !important;
  outline: 0;
  -webkit-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
}

.tabela-carrinho .valorTotal {
  font-size: 40px;
  color: #f48137;
  font-weight: 800;
  line-height: 50px;
}

.tabela-carrinho a.btn-efetuar-pagamento {
  background: #80bd2d;
  color: #fff !important;
  text-decoration: none;
  padding: 20px 20px !important;
  font-weight: 100 !important;
  text-transform: uppercase;
  font-size: 16px;
  margin: 20px 0 0 0;
  display: inline-block;
}

.frete-input {
  display: inline-block;
  position: relative;
  margin-right: 15px;
}

.frete-input label {
  font-weight: 400;
}

.tabela-carrinho a.btn-efetuar-pagamento:hover {
  background: #649423;
}

.tabela-carrinho button.btn-efetuar-pagamento {
  background: #80bd2d;
  color: #fff !important;
  text-decoration: none;
  padding: 20px 30px !important;
  font-weight: 100 !important;
  text-transform: uppercase;
  font-size: 21px;
  margin: 20px 0 0 0;
  border: 0px;
  display: inline-block;
  border-radius: 4px;
  -webkit-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  padding: 8px;
  line-height: 22px;
  vertical-align: top;
  border-top: 1px solid #ddd;
  font-size: 16px;
}

.tabela-carrinho button.btn-efetuar-pagamento:hover {
  background: #649423;
}

.tabela-carrinho a.btn-continuar-comprando {
  background: #a0a0a0;
  color: #fff !important;
  text-decoration: none;
  padding: 10px 18px !important;
  text-transform: uppercase;
  font-size: 13px;
  margin: -50px 0 0 0;
  display: inline-block;
  border-radius: 3px;
  font-weight: 400 !important;
  -webkit-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
}

.tabela-carrinho a.btn-continuar-comprando:hover {
  background: #878787;
}

.menuLista-topo {
  display: none;
}

.ati-lista .menuLista-topo {
  display: block;
}

.ati-lista .menuFixo-topo {
  display: none;
}

.box-passos {
  width: 100%;
  height: 80px;
  position: relative;
  display: inline-block;
  margin: 20px 0;
}

.box-passos::after {
  width: 100%;
  position: absolute;
  background: #ccc;
  height: 3px;
  top: 35px;
  left: 0px;
  content: " ";
}

.box-passos ul {
  list-style: none;
  padding: 0px;
  width: 100%;
  display: inline-block;
  margin: 0px;
}

.box-passos ul li {
  list-style: none;
  position: absolute;
  width: 40px;
  height: 40px;
  display: inline-block;
  left: 10px;
  top: 14px;
  -webkit-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  z-index: 1;
}

.box-passos ul li:nth-child(2) {
  left: calc(33.333% + 10px);
  left: -ms-calc(33.333% + 10px);
  left: -moz-calc(33.333% + 10px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.box-passos ul li:nth-child(3) {
  left: calc(66.666% - 10px);
  left: -ms-calc(66.666% - 10px);
  left: -moz-calc(66.666% - 10px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.box-passos ul li:nth-child(4) {
  right: 10px;
  left: auto;
}

.box-passos ul li span {
  position: relative;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 3px solid #ccc;
  background: #fff;
  text-align: center;
  line-height: 33px;
}

.box-passos ul li span.active {
  border-color: #f48137;
  color: #f48137;
}

.box-passos ul li span.active::before {
  display: none;
}

.box-passos ul li span~p {
  display: none;
}

.box-passos ul li span.active~p {
  display: inline-block;
}

.box-passos ul li span.active::after {
  content: '\e911';
  font-family: 'fontello';
  /* position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: -14px; */
}

.box-passos ul li span.ok,
.box-passos ul li span.ok {
  border-color: #5c5c5c;
  color: #5c5c5c;
}

.box-passos ul li span.ok-active,
.box-passos ul li span.ok-active~p {
  color: #80bd2d;
  border-color: #80bd2d;
}

.box-passos ul li span.ok::after,
.box-passos ul li span.ok-active::after {
  content: '\e818';
  font-family: 'fontello';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.box-passos ul li p {
  font-size: 15px;
  color: #999;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: 100%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.box-passos ul li span.active~p {
  color: #f48137;
  font-size: 18px;
  font-weight: 500;
}

/*
MAX-WIDTH: 480
*/

@media (max-width: 550px) {
  /*.box-passos {
        width: auto !important;
        height: auto !important;
        margin: 50px 30px !important;
        margin-bottom: 0px !important;
    }
    .box-passos::after {
        width: 2px;
        height: 100%;
        top: -15px;
        left: 18px;
    }
    .box-passos ul {
        width: auto !important;
        display: block !important;
    }
    .box-passos ul li {
        left: 0px !important;
        position: relative !important;
        display: block !important;
        transform: translateX(0%) !important;
        -moz-transform: translateX(0%) !important;
        -webkit-transform: translateX(0%) !important;
        -ms-transform: translateX(0%)!important;
        margin-bottom: 30px;
    }
    .box-passos ul li p {
        left: 60px;
        top: 0px;
        text-align: left;
        transform: translateX(0%) !important;
        -moz-transform: translateX(0%) !important;
        -webkit-transform: translateX(0%) !important;
        -ms-transform: translateX(0%)!important;
    }
    .section-article .content-article:first-child {
        padding-bottom: 0px !important;
    }*/
}

body.page-pagamento .menuPrincipal,
body.page-pagamento .btn-servicos-footer,
body.page-pagamento .footer-sociallinks,
body.page-pagamento .redes-sociais {}

body.page-pagamento header .box-menuTopo {
  border-bottom: none;
}

body.page-pagamento .section-article {
  padding: 0px !important;
}

.box-info-pagamento {
  display: block;
  text-align: center;
}

.box-info-pagamento a {
  display: inline-block;
  width: auto;
  position: relative;
  border: 2px solid #f48137;
  color: #f48137;
  text-decoration: none;
  margin-top: 40px;
  padding: 0 15px;
  height: 55px;
  line-height: 51px;
}

/*====================================================================================
======================================================================================
============================== NOTICIAS E NOVIDADES ================================*/

.box-listagem {
  margin-bottom: 40px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 40px;
}

.box-listagem h4 {
  clear: none;
  display: block;
  width: auto;
}

.list-menu-area-restrita {
  padding: 0px !important;
}

.feeds-instagram .insta-holder {
  width: 16.666%;
  display: inline-block;
  position: relative;
}

.feeds-instagram .insta-holder-aside {
  width: 100%;
  position: relative;
  display: none;
}

.feeds-instagram .insta-holder-aside:first-child {
  display: inline-block !important;
}

.feeds-instagram .insta-holder a,
.feeds-instagram .insta-holder-aside a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.feeds-instagram .insta-holder a:after,
.feeds-instagram .insta-holder-aside a:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, .0);
  -webkit-transition: background-color .15s;
  -o-transition: background-color .15s;
  transition: background-color .15s;
}

.feeds-instagram .insta-holder a:hover:after,
.feeds-instagram .insta-holder-aside a:hover:after {
  background-color: rgba(0, 0, 0, .5);
}

.feeds-instagram .insta-holder a span,
.feeds-instagram .insta-holder-aside a span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  -webkit-transform: -webkit-translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  z-index: 200;
  font-size: 50px;
  color: #fff !important;
  display: none;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
}

.feeds-instagram .insta-holder:hover a span,
.feeds-instagram .insta-holder-aside:hover a span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  -webkit-transform: -webkit-translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  z-index: 200;
  display: block;
}

.feeds-instagram .insta-holder img,
.feeds-instagram .insta-holder-aside img {
  width: 100%;
  position: relative;
  display: inline-block;
  opacity: 1;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
}

/* .feeds-instagram .insta-holder:hover img,
.feeds-instagram .insta-holder-aside:hover img {
    width: 100%;
    position: relative;
    display: inline-block;
    opacity: 0.4;
} */

.box-instagran {
  width: 100%;
  float: left;
  position: relative;
  margin: 0 25px 0 0;
  font-family: 'Source Sans Pro', sans-serif;
}

body .blueimp-gallery {
  background: rgba(0, 0, 0, 0.8) !important;
}

body .blueimp-gallery h3 {
  color: #fff !important;
}

.blueimp-gallery>.slides>.slide>.slide-content,
.blueimp-gallery>.prev,
.blueimp-gallery>.next,
.blueimp-gallery>.close,
.blueimp-gallery>.play-pause {
  color: #fff !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

body .blueimp-gallery .prev,
body .blueimp-gallery .next {
  font-size: 20px;
  color: #fff;
  line-height: 44px;
  border: transparent;
  background: transparent;
}

body .blueimp-gallery .prev i,
body .blueimp-gallery .next i {
  margin-top: 10px;
  display: inline-block;
}

/*====================================================================================
======================================================================================
============================== Start R E S P O N S I V E =============================
======================================================================================
====================================================================================*/

/*====================================================================================
==================================== Large desktop ==================================*/

@media (min-width: 1200px) {
  .listProdutos {
    list-style: none;
    padding: 0;
    border-bottom: none;
    border-top: none;
  }

  .listProdutos li:nth-child(3n+0) {
    margin-right: 0px;
  }

  .listProdutos li.black-friday {
    position: relative;
    overflow: hidden;
  }

  .listProdutos li.black-friday:before {
    content: "Black Friday";
    color: #FFF;
    padding: 10px 60px;
    background: #282828;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 25px;
    left: -50px;
    z-index: 99;
  }
}

/*====================================================================================
===================== Portrait tablet to landscape and desktop =====================*/

@media (min-width: 992px) and (max-width: 1200px) {
  header .box-menuTopo ul li a {
    padding: 0 14px;
    font-size: 15px;
  }

  header .box-menuTopo a.btn-menu-topo-responsive {
    display: none;
  }

  header .box-menuTopo a.btn-menu-topo-responsive i {
    margin-right: 6px;
  }

  header .box-menuTopo a.btn-menu-topo-responsive span::before {
    font-size: 30px;
  }

  header .box-menuTopo .box-carrrinho::before {
    content: "Carrinho de compras:";
    color: #f48137;
  }

  .filtro .menuCategorias>ul li {
    font-size: 13px;
    padding: 10px 5px !important;
  }

  .filtro .menuCategorias>ul li:hover {
    padding: 10px 5px !important;
  }

  .listProdutos {
    list-style: none;
    padding: 0;
    border-bottom: none;
    border-top: none;
  }

  .listProdutos li:nth-child(3n+0) {
    margin-right: 0px;
  }

  .listBanner li {
    padding: 0 10px;
  }

  footer .box-formasPagamento {
    margin: 0px;
  }

  .content-box-banners .box-banner-grande::before,
  .content-box-banners .box-banner-grande::after,
  .content-box-banners .box-banner-medio::before,
  .content-box-banners .box-banner-medio::after {
    display: none !important;
  }
}

/*====================================================================================
====================== Portrait tablet to landscape and desktop ====================*/

@media (min-width: 768px) and (max-width: 991px) {
  header .content-header .logo {
    top: 15px;
    left: 0px;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
  }

  header .box-menuTopo ul.menuPrincipal {
    display: none;
  }

  header .box-menuTopo a.btn-menu-topo-responsive {
    font-size: 20px;
    color: #ffffff;
    display: block;
    margin-top: 16px;
  }

  header .box-menuTopo a.btn-menu-topo-responsive span::before {
    font-size: 30px;
  }

  header .box-menuTopo .box-carrrinho::before {
    content: "Carrinho de compras:";
    color: #f48137;
  }

  header .content-header .buscaTopo {
    margin: 0 0 30px 0;
  }

  .filtro .menuCategorias {
    padding: 20px 0 30px 0;
  }

  .filtro .menuCategorias::before {
    content: "Selecione uma Categoria:";
    color: #f48137;
    text-align: center;
    display: block;
    padding-bottom: 10px;
  }

  .filtro .menuCategorias .menuCategoria-mobile {
    display: block;
  }

  .filtro .menuCategorias ul {
    display: none;
  }

  .filtro .filtroCampos ul li {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .filtro .filtroCampos ul li select {
    max-width: 100%;
    min-width: 300px;
    width: auto !important;
    display: block;
  }

  .listProdutos li .box-addCarrinho {
    display: inline-block;
    bottom: 0px;
  }

  .listProdutos li .box-addCarrinho span {
    color: #fff;
    display: inline-block;
    text-decoration: none;
  }

  .listProdutos-interno {
    padding-left: 0;
  }

  .listProdutos-interno li {
    width: 31.5%;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .listProdutos-interno li+li {
    margin-left: 10px !important;
  }

  .listProdutos-interno li:nth-child(3n+1) {
    margin-left: 0 !important;
  }

  .listBanner li {
    padding: 0 10px;
  }

  .box-instagram .box-insta .bcr_ins_gallery .ins_img {
    width: 33%;
    margin-bottom: 30px;
  }

  footer .box-formasPagamento {
    width: 100%;
    float: none;
    display: inline-block;
    position: relative;
    text-align: center;
    margin: -20px 0;
  }

  .content-box-banners .box-banner-grande::before,
  .content-box-banners .box-banner-grande::after,
  .content-box-banners .box-banner-medio::before,
  .content-box-banners .box-banner-medio::after {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .listProdutos li+li {
    margin-left: inherit;
  }
}

/*===================================================================================
====================== Landscape phone to portrait tablet =========================*/

@media (max-width: 767px) {
  header .content-header .logo {
    top: 15px;
    left: 0px;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
  }

  header .box-menuTopo ul.menuPrincipal {
    display: none;
  }

  header .box-menuTopo a.btn-menu-topo-responsive {
    font-size: 20px;
    color: #ffffff;
    display: block;
    margin-top: 16px;
  }

  header .box-menuTopo a.btn-menu-topo-responsive span::before {
    font-size: 30px;
  }

  header .box-menuTopo .box-carrrinho::before {
    content: "Carrinho de compras:";
    color: #f48137;
  }

  header .content-header .buscaTopo {
    margin: 20px 0;
  }

  header .content-header .buscas-topo {

    width: auto;
  }

  header .content-header .buscaTopo input {
    width: 200px;
  }

  .box-carrinho-menuFixo {
    text-align: left !important;
  }

  .box-carrinho-menuFixo a {
    font-size: 14px !important;
  }

  .btn-menu-responsive-menuFixo a {
    position: absolute;
    right: 0;
    top: auto;
  }

  .filtro .menuCategorias {
    padding: 20px 0 30px 0;
  }

  .filtro .menuCategorias::before {
    content: "Selecione uma Categoria:";
    color: #f48137;
    text-align: center;
    display: block;
    padding-bottom: 10px;
  }

  .filtro .menuCategorias .menuCategoria-mobile {
    display: block;
  }

  .filtro .menuCategorias ul {
    display: none;
  }

  .filtro .filtroCampos ul li {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .filtro .filtroCampos ul li select {
    max-width: 100%;
    min-width: 300px;
    width: auto !important;
    display: block;
  }

  .listProdutos {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }

  .listProdutos li .box-addCarrinho {
    display: inline-block;
    bottom: 0px;
  }

  .listBanner li {
    padding: 0 10px;
  }

  .box-instagram .box-insta .bcr_ins_gallery .ins_img {
    width: 33%;
    margin-bottom: 30px;
  }

  footer .box-formasPagamento {
    width: 100%;
    float: none;
    display: inline-block;
    position: relative;
    text-align: center;
    margin: -20px 0;
  }

  footer .footer-sociallinks {
    text-align: center;
    margin-bottom: 30px;
  }

  footer .enderecoFooter {
    margin-bottom: 30px;
  }

  footer .logoIlion {
    display: inline-block;
    margin: 0 auto 30px;
    float: none;
    position: absolute !important;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    left: 50%;
    display: inline-block;
    position: relative;
  }

  .section-article .content-article article h2.titulo-sessao {
    font-size: 35px;
    display: block;
    left: auto;
    top: auto;
    text-align: center;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
    margin-bottom: 20px;
  }

  .box-titulo-filtro h2 {
    font-size: 35px;
    text-align: center;
  }

  .box-paginacao ul li {
    display: inline-block;
  }

  .box-paginacao ul li a {
    font-size: 18px;
  }

  .box-tamanhos,
  .box-cores {
    margin-bottom: 20px;
  }

  .box-opcoes {
    border-bottom: 1px solid #f48137;
    border-top: 1px solid #f48137;
    padding: 10px 0;
  }

  .box-listagem {
    margin-bottom: 40px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 40px;
  }

  .box-listagem .media-left {
    width: 33%;
  }

  .content-box-banners .box-banner-grande::before,
  .content-box-banners .box-banner-grande::after,
  .content-box-banners .box-banner-medio::before,
  .content-box-banners .box-banner-medio::after {
    display: none !important;
  }

  .content-box-banners {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .content-box-banners .box-banner-grande,
  .content-box-banners .box-banner-medio {
    width: 100%;
  }

  .content-box-banners .box-banner-grande a,
  .content-box-banners .box-banner-medio a {
    font-size: 25px;
  }
}

/*===================================================================================
========================= Landscape phones and down ===============================*/

@media (max-width: 480px) {

  .fixmobileprodutoslista,
  .listProdutos li {
    width: 100%
  }

  .logo-wrap {
    display: none;
  }

  .fotoright {
    float: right;
    text-align: right;
    padding: 0 0 10px 0;
  }

  .fotoinferior {
    margin: 0 0px 10px 0;
  }

  .containerfotoleft {
    margin: 0 10px 10px 0;
  }

  .containerfotoright {
    margin: 0 0 10px 10px;
  }

  .containerfotoinferior {
    margin: 10px 0 0 0;
  }

  .box-carrinho-menuFixo {
    text-align: left !important;
  }

  .box-carrinho-menuFixo i {
    display: none;
  }

  .box-carrinho-menuFixo a span {
    padding: 3px 5px !important;
  }

  .btn-menu-responsive-menuFixo a {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .form-produto .btn-carrinho i {
    top: 12px !important;
  }

  .content-slider .owl-next span,
  .content-slider .owl-prev span {
    color: #fff;
  }

  .filtro .menuCategorias::before {
    content: "Selecione uma Categoria:";
    color: #f48137;
    text-align: center;
    display: block;
    padding-bottom: 10px;
  }

  .filtro .menuCategorias ul {
    display: none;
  }

  .filtro .filtroCampos ul li {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 0px !important;
  }

  .filtro .filtroCampos ul li label {
    display: block;
    position: relative;
    width: 100%;
  }

  .filtro .filtroCampos ul li label select {
    max-width: auto !important;
    min-width: auto !important;
    width: 100% !important;
    display: block;
    padding: 0 !important;
  }

  .listBanner li {
    padding: 0px;
  }

  .box-instagram .box-insta .bcr_ins_gallery .ins_img {
    width: 50%;
    margin-bottom: 30px;
    padding: 5px;
  }

  .box-instagram .box-insta .bcr_ins_gallery .ins_img img {
    width: 100%;
  }

  .box-instagram .box-insta .box-endereco-insta {
    font-size: 12px;
  }

  .section-article .content-article article h2.titulo-sessao {
    font-size: 25px;
    display: block;
    left: auto;
    top: auto;
    text-align: center;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
    margin-bottom: 20px;
  }

  .box-image-intro {
    height: 220px !important;
    background-size: cover !important;
  }

  .box-image-intro img {
    display: none;
  }

  .box-image-intro h1 {
    display: block;
  }

  .box-titulo-filtro h2 {
    font-size: 30px;
    text-align: center;
  }

  .section-article .content-article article h2 {
    font-size: 27px;
  }

  .box-calculo-frete {
    margin: 20px 0;
    display: inline-block;
  }

  .box-quantitdade::after {
    content: "Informe a quantidade";
    position: absolute;
    left: 100px;
    top: 20px;
    color: #777;

    font-style: italic;
  }

  .btn-add-lista {
    font-size: 14px;
    min-height: auto !important;
    background: none !important;
    text-align: center !important;
    padding: 20px 10px !important;
    margin: 20px 0;
    display: inline-block;
  }

  .btn-add-carrinho {
    font-size: 14px;
    min-height: auto !important;
    background: none !important;
    text-align: center !important;
    padding: 20px 10px !important;
    margin: 0;
    display: inline-block;
  }

  .btn-concluir-compra {
    font-size: 14px;
    min-height: auto !important;
    background: none !important;
    text-align: center !important;
    padding: 20px 10px !important;
    margin: 0;
    display: inline-block;
  }

  .box-tamanhos,
  .box-cores {
    margin-bottom: 20px;
  }

  .produtos-relaticonados .box-titulo-relacionados h2 {
    font-size: 30px;
  }

  .box-listagem {
    margin-bottom: 40px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 40px;
  }

  .box-listagem .media-left {
    width: 100%;
    display: block;
    clear: both;
    margin-bottom: 20px;
  }

  .box-listagem .media-left a {
    display: inline-block;
    width: 100%;
    clear: both;
    text-align: center;
  }

  .box-table-scroll {
    width: 100%;
    overflow-x: scroll;
  }

  .box-table-scroll table {
    width: 980px;
  }

  .box-table-scroll table td {
    vertical-align: middle !important;
  }

  .pagination table tbody tr {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .pagination table tbody tr td,
  .pagination table tbody tr th {
    margin: 0px !important;
    padding: 6px 10px !important;
  }

  .pagination table tbody tr th span {
    text-align: center;
  }
}

/*====================================================================================
======================================================================================
===================================== CSS - ACOES ====================================
======================================================================================
====================================================================================*/

.box404 {
  border: 1px solid #ddd;
  padding: 20px;
  line-height: 160%;
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 10px #e7e7e7;
  box-shadow: inset 0 0 10px #e7e7e7;
}

body.body404 {
  background: #fff;
  margin: 30px;
  overflow: hidden;
}

body.body404 h1 {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
  margin: 0 0 10px 0;
  float: none;
  text-transform: none;
}

body.body404 .logo {
  position: relative;
  top: auto;
  left: auto;
  margin: 0 auto 35px auto;
}

body.body404 .logo span {
  display: none;
}

.clearthis {
  clear: both;
}

.noTextTransform {
  text-transform: none;
}

.nomargim {
  margin: 0;
}

.hidden {
  display: none;
}

.textsmall {
  font-size: .7em;
  line-height: normal;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.overflowHidden {
  overflow: hidden;
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

.positionRelative {
  position: relative;
}

.classErro {
  color: red;
}

.noBorderBottom {
  border-bottom: none;
}

.modal {
  z-index: 3000;
}

.modal-content {
  border-radius: 0px;
}

.modal-content .modal-header {
  color: #fff;
}

.modal-content .modal-header h4 {
  color: #f48137 !important;
  display: inline-block;
  width: auto;
}

.modal-body {
  display: inline-block;
}

#boxPassarinho {
  background: url(../images/bird-gif.gif) no-repeat 5px 3px;
  padding-left: 20px;
}

table.paginacao {
  margin: auto;
  border: none;
}

table.paginacao td.pagenator td {
  margin: auto;
  border: 1px solid #e6e6e6;
}

table.paginacao td {
  margin: auto;
  border: none;
}

table.paginacao .pagenator tr td,
table.paginacao .pagenator tr th {
  width: 43px;
  height: 36px;
}

table.paginacao .pagenator tr td:nth-child(1),
table.paginacao .pagenator tr td:nth-child(2),
table.paginacao .pagenator tr td:nth-last-child(1) {
  display: none
}

table.paginacao .pagenator tr td:nth-child(3) img,
table.paginacao .pagenator tr td:nth-last-child(2) img {
  display: none
}

table.paginacao .pagenator tr td:nth-child(3):before {
  content: "Anterior"
}

table.paginacao .pagenator tr td:nth-last-child(2):before {
  content: "Pr\00f3xima"
}

table.paginacao .pagenator tr td:nth-child(3) a:hover:before {
  content: "Anterior"
}

table.paginacao .pagenator tr td:nth-last-child(2) a:hover:before {
  content: "Pr\00f3xima"
}

table.paginacao .pagenator tr td:nth-child(3) {
  width: auto;
  padding: 6px 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

table.paginacao .pagenator tr td:nth-last-child(2) a:hover,
table.paginacao .pagenator tr td:nth-child(3) a:hover {
  color: #333333 !important;
}

table.paginacao .pagenator tr td:nth-last-child(2) {
  width: auto;
  padding: 6px 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

table.paginacao .pagenator tr td,
table.paginacao .pagenator tr th {
  position: relative;
  float: left;
  padding: 0;
  margin-left: -1px;
  line-height: 1.42857;
  color: #EC6902 !important;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

table.paginacao .pagenator tr td a,
table.paginacao .pagenator tr th span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 6px 12px;
  text-align: center;
}

table.paginacao .pagenator tr td a {
  color: #EC6902;
}

table.paginacao .pagenator table tbody tr th,
table.paginacao .pagenator tr td:hover,
table.paginacao .pagenator tr td a:hover {
  background-color: #eee;
  color: #f48137 !important;
  border-color: #ddd;
  font-weight: normal;
}

.produto-indisponivel {
  opacity: 0.3;
}

.confirm.btn.btn-lg.btn-primary {
  border: 2px solid #f48137;
  padding: 10px 20px;
  font-size: 15px;
  background: #fff;
  border-radius: 0;
  color: #f48137;
  text-transform: uppercase;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.box-paginacao table tr td {
  font-size: 13px
}

.pagination>li>a,
.pagination>li>span {
  border-radius: 4px;
  margin-right: 5px;
  background: #f6f6f6;
  border: none;
  color: #595959;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.pagination>li.active>a {
  background: #0d4a87;
}

.pagination tr th,
.pagination tr td {
  border-radius: 4px;
  /* margin-right: 5px; */
  border: none;
  color: #55d37d;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  float: left;
  padding: 5px 4px;
  margin-left: -1px;
  line-height: 20px;
}

.pagination tr td:nth-child(1),
.pagination table tbody tr td:nth-child(2),
.pagination table tbody tr td:nth-last-child(1) {
  display: none
}

.pagination>li.active>a {
  background: #0d4a87;
}

.pagination table tbody tr td,
.pagination table tbody tr td a {
  color: #777 !important;

}

.pagination table tbody tr td a span {}

.pagination table tbody tr td a:hover {
  color: #f48137 !important;
  font-weight: 200;
  text-decoration: none;
}

.pagination table tbody tr td:nth-child(3):after {
  content: "\e857";
  font-family: fontello;
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: block;
}

.pagination table tbody tr td:nth-child(3) a {
  position: absolute;
  z-index: 999;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: block;
  padding: 0;
}

.pagination table tbody tr td:nth-last-child(2) a {
  position: absolute;
  z-index: 999;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: block;
  padding: 0;
}

.pagination table tbody tr td:nth-last-child(2):after {
  content: "\e858";
  font-family: fontello;
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: block;
}

.product-list table tr td {
  border: none;
}

.pagination table tbody tr {
  display: table-row;
}

.pagination table tbody tr td img,
.pagination table tbody tr td a img {
  display: none;
}

.box-detalhes {
  border: 2px solid #eee;
  color: #f48137;
  background: #fff;
  padding: 2rem;
}

.box-detalhes h1 {
  border-bottom: 2px solid #eee;
  padding: 10px 0;
}

.box-detalhes p,
.box-detalhes p a {
  color: #000;
}

#forma-de-pagamento {
  text-align: center;
  margin-top: 20px;
}

#forma-de-pagamento h1 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
}

.produtos-lista-de-compras h1 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  text-align: center;
}

.btn-concluir {
  display: inline-block;
  /* clear: both; */
  position: relative;
  border: 3px solid #80bd2d;
  color: #ffffff;
  background: #80bd2d;
  text-decoration: none;
  text-transform: uppercase;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 16px 30px;
  font-weight: 700;
  font-size: 23px;
  font-family: monospace;
  border-radius: 6px
}

.btn-concluir:hover,
.btn-concluir-disabled {
  color: #f48137;
  background: #fff;
  border-color: #f48137;
  text-decoration: none;
}

.radio-frete-pagamento {
  display: inline-block;
  margin-left: 15px;
}

.loading {
  text-align: center;
}

select.select-ricca {
  display: inline-block;
  clear: both;
  position: relative;
  background: #fff;
  border: none;
  height: 40px;
}

.preco-de {
  text-decoration: line-through;
  color: #ccc !important;
}

.swal2-overlay {
  z-index: 6999;
}

.swal2-modal {
  z-index: 7000;
}

.box-frete {}

.overlay-popup {
  padding: 20px;
}

.overlay-popup .hold-content {
  padding-top: 45px;
}

.overlay-popup .hold-content button.mfp-close {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 40px;
  right: calc(50% - 22px);
  background: white;
  border-radius: 44px;
  background-clip: padding-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.overlay-popup .hold-content button.mfp-close:before {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e901";
  color: #3e192b;
  font-size: 44px;
}

.overlay-popup .hold-content .content {
  padding: 20px;
  padding-top: 45px;
  background: #fff;
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
}

.overlay-popup .hold-content .format-content p {
  font-size: 13px;
  color: #282828;
  margin-bottom: 20px;
  text-align: justify;
}

.overlay-popup .hold-content .format-content h3 {
  font-size: 20px;
  margin-bottom: 35px;
}

.overlay-popup .hold-content .format-content ul,
.overlay-popup .hold-content .format-content ul li {
  list-style-type: none;
  font-size: 13px;
  color: #b55933;
  padding: 0px;
}

.overlay-popup .hold-content .format-content ul {
  margin-bottom: 20px;
}

.overlay-popup .hold-content .format-content ul li {
  margin-bottom: 5px;
}

.overlay-popup .hold-content .content img.mfp-img {
  padding: 40px 15px 15px;
  max-height: 100% !important;
}

.overlay-popup .hold-content .content .alert-submit {
  padding: 20px;
  border: 3px dashed;
  margin-bottom: 20px;
  text-align: center;
}

.overlay-popup .hold-content .content .alert-submit i {
  font-size: 80px;
  display: inline-block;
  margin-bottom: 20px;
}

.overlay-popup .hold-content .content .alert-submit.success {
  border-color: #cca13b;
}

.overlay-popup .hold-content .content .alert-submit.success i {
  color: #cca13b;
}

.overlay-popup .hold-content .content .alert-submit.error {
  border-color: darkred;
}

.overlay-popup .hold-content .content .alert-submit.error i {
  color: darkred;
}

.overlay-popup .content .block-title h3 {
  color: #cca13b;
  margin-bottom: 10px;
}

.overlay-popup .content .contact {
  padding-top: 50px;
  text-align: center;
}

.box-select-tipo-pagamento {
  display: block;
  position: relative;
}

div.select-tipo-pagamento {
  margin: 30px 40px 30px 0;
  display: inline-block;
}

.select-tipo-pagamento input[type="radio"] {
  display: none;
}

.select-tipo-pagamento input[type="radio"]+label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.select-tipo-pagamento input[type="radio"]+label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}

.select-tipo-pagamento input[type="radio"]+label span {
  background-color: #ccc;
}

.select-tipo-pagamento input[type="radio"]:checked+label span {
  background-color: #ff0000;
}

.select-tipo-pagamento input[type="radio"]+label span,
.select-tipo-pagamento input[type="radio"]:checked+label span {
  -webkit-transition: background-color 0.4s linear;
  -o-transition: background-color 0.4s linear;
  transition: background-color 0.4s linear;
}

.box-tipos-pagamento {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

@media (min-width: 992px) {

  .box-tipos-pagamento {
    padding: 0 250px;

  }
}

.box-tipos-pagamento div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.box-tipos-pagamento div img {
  width: 92px !important;
  height: auto !important;
}

.box-tipos-pagamento div div.box-tipos-pagamento-radio input[type="radio"] {
  display: none;
}

.box-tipos-pagamento div div.box-tipos-pagamento-radio input[type="radio"]+label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.box-tipos-pagamento div div.box-tipos-pagamento-radio input[type="radio"]+label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}

.box-tipos-pagamento div div.box-tipos-pagamento-radio input[type="radio"]+label span {
  background-color: #ccc;
}

.box-tipos-pagamento div div.box-tipos-pagamento-radio input[type="radio"]:checked+label span {
  background-color: #ff0000;
}

.box-tipos-pagamento div div.box-tipos-pagamento-radio input[type="radio"]+label span,
.box-tipos-pagamento div div.box-tipos-pagamento-radio input[type="radio"]:checked+label span {
  -webkit-transition: background-color 0.4s linear;
  -o-transition: background-color 0.4s linear;
  transition: background-color 0.4s linear;
}

.owl-item p strong {
  font-size: 16px !important;
}

.owl-item.active,
.owl-item {
  width: 100 !important;
  margin-right: 0px !important;
}

.mfp-image-holder .mfp-content {
  max-width: 90% !important;
}

div .mfp-title {
  line-height: 1.4em;
  font-size: 13px
}

.mfp-title a {
  text-decoration: underline;
  color: #fff
}

.mfp-title a:hover {
  text-decoration: none;
  opacity: 0.8
}

.mfp-zoom-in .mfp-figure,
.mfp-zoom-in .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95)
}

.mfp-zoom-in.mfp-bg,
.mfp-zoom-in .mfp-preloader {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out
}

.mfp-zoom-in.mfp-image-loaded .mfp-figure,
.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.mfp-zoom-in.mfp-ready.mfp-bg,
.mfp-zoom-in.mfp-ready .mfp-preloader {
  opacity: 0.8
}

.mfp-zoom-in.mfp-removing .mfp-figure,
.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-iframe-scaler {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  opacity: 0
}

.mfp-zoom-in.mfp-removing.mfp-bg,
.mfp-zoom-in.mfp-removing .mfp-preloader {
  opacity: 0
}

div.avia-popup .mfp-iframe-scaler {
  overflow: visible
}

div.avia-popup .mfp-zoom-out-cur {
  cursor: auto
}

div.avia-popup .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer
}

div.avia-popup .mfp-close {
  width: 40px;
  height: 40px;
  right: -13px;
  text-align: center;
  border-radius: 100px;
  border: 2px solid transparent;
  line-height: 38px;
  padding: 0;
  top: -5px;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  font-family: Arial, Baskerville, monospace !important
}

div.avia-popup .mfp-close:hover {
  border: 2px solid #fff;
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform: scale(0.8) rotateZ(90deg)
}

div.avia-popup .mfp-iframe-scaler .mfp-close {
  top: -43px
}

div.avia-popup .mfp-figure:after {
  -webkit-box-shadow: none;
  box-shadow: none;
  display: none
}

.listas-produtos>div {
  margin-bottom: 20px;
}

h2.page-header.page-header-sm {
  font-size: 40px;
}

.page-header {
  font-family: bebasbold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  letter-spacing: 1px;
  font-size: 50px;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  font-weight: 300;
  text-align: center;
  color: #715753;
  border: none;
}

.page-header:before {
  content: '';
  position: absolute;
  top: 38%;
  left: 50%;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 1px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #fff;
}

.page-header span {
  width: auto;
  padding: 0 26px;
  font-weight: 500;
}

.page-header-sm {
  margin: 10px 0;
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
}

.page-header-sm span {
  padding: 0 15px;
}

@media (max-width: 991px) {
  .page-header:before {
    display: none;
  }

  .page-header span {
    padding: 0;
  }
}

.filtros .section-label {
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background-color: #1c1c1c;
  border-radius: 5px;
  padding: 10px 0px;
  cursor: pointer;
}

.filtros .section-label .fa {
  color: #333;
}

.filtros {
  margin-bottom: 30px;
}

.listagem .filtros .form-control {
  margin-bottom: 10px;
}

.form-produto .form-control {
  display: inline-block;
}

.text-muted {
  margin: 0 6px;
}

.form-produto .text-muted {
  color: #999;
}

.form-produto #info-cep {
  display: inline-block;
  width: 140px;
  height: 20px;
  margin: 0;
}

.form-produto #info-valor {
  font-size: 28px;
  font-weight: 700;
}

.form-produto .btn-carrinho {
  padding: 19px 20px;
  font-size: 18px;
  color: #fff;
  border: none;
  border-radius: 0;
  background-color: #5d9e0e;
  -webkit-transition: all .1s;
  -o-transition: all .1s;
  transition: all .1s;
  text-align: left;
  border-radius: 3px;
}

.form-produto .btn-carrinho:hover {
  background-color: #f48137 !important;
}

.form-produto .btn-carrinho i {
  color: #fff;
  display: inline-block;
  font-size: 26px;
  position: absolute;
  right: 30px;
  top: 15px;
  text-align: left !important;
}

.form-produto .btn-carrinho:hover i {
  color: #fff;
}

@media (min-width: 992px) {
  .form-produto>.row>.col-sm-6:first-child {
    padding-right: 5px;
  }

  .form-produto>.row>.col-sm-6:last-child {
    padding-left: 5px;
  }

  .form-produto>.row>.col-sm-4 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .form-produto>.row>.col-sm-4:first-child {
    padding-left: 15px;
    padding-right: 5px;
  }

  .form-produto>.row>.col-sm-4:last-child {
    padding-left: 5px;
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .form-produto .bloco-valores {
    margin-bottom: 15px;
  }

  .box-opcoesTopo-wrap {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: right;
  }

  .box-opcoesTopo-wrap div:first-child {
    margin-bottom: 10px !important;
  }
}

.info-produto {
  margin: 30px 0;
  padding: 0;
  list-style: none;
}

.info-produto .fa {
  color: #f48137;
}

.compartilhe {
  padding-top: 4px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.compartilhe .addthis_inline_share_toolbox {
  clear: none !important;
  display: inline-block !important;
  vertical-align: middle !important;
}

.ng-cloak {
  display: none;
}

#loaderDiv {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  background-color: white;
  opacity: .6;
}


@media (max-width: 480px) {}

@media (max-width: 360px) {
  .box-opcoesTopo-wrap .redes-sociais {
    display: none;
  }
}

.outras-cores h4 {
  color: #666666;
  font-weight: 600
}

.outras-cores .cor {
  display: inline-block;
  margin-right: 10px
}

.outras-cores .cor:hover img {
  border: 1px solid #B8994C;
}

.outras-cores .cor:hover>p {
  text-decoration: none !important;
}

.outras-cores .cor img {
  width: 100px;
  border: 1px solid #DDDDDD;
}

.outras-cores .cor p {
  padding-top: 5px;
}

.listProdutos-interno-relacionado {
  margin-bottom: 40px;
}

.blogShort p {
  font-size: 17px;
}

.remove-padding {
  margin: 0px !important;
  padding: 0px !important
}

.tp-banner-container {
  width: 100%;
  position: relative;
  padding: 0;
  border-radius: 0px !important;
  overflow: hidden !important;
}

.bloco-valores button {
  background: #333;
  border: none;
  padding: 10px 15px;
  border-radius: 3px;
  color: #fff;
  margin-left: -18px;
}

.bloco-valores button:hover {
  background: #EA2330;
}

footer strong {
  font-weight: 400;
}

.bannersimgs {
  display: block;
  /*box-shadow: 2px 4px 13px 2px rgba(0, 0, 0, 0.15);*/
  border-radius: 8px;
  overflow: hidden;
}

.rodapee {
  padding: 30px 0;
}

.box-carrinho-menuFixo .icon-carrinho {
  position: absolute;
  top: -9px;
  left: -45px;
  font-size: 29px;
}

button i.fas.fa-filter {
  font-size: 16px;
}

button.btn.btn-default.btn-block {
  padding: 13px 25px;
  font-size: 23px;
  color: #fff;
  border: 0;
  /* margin-top: 19px; */
  -webkit-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.23);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.23);
  position: relative;
  background: #d31d24;
  -webkit-transition: All 0.18s ease-in-out;
  -o-transition: All 0.18s ease-in-out;
  transition: All 0.18s ease-in-out;
  font-family: bebasbold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  letter-spacing: 5px;
}

button.btn.btn-default.btn-block:hover {
  background: #f48137;
  color: #fff;
}

.owl-prev {
  position: absolute;
  top: 46%;
  left: -41px;
  font-size: 30px;
}

.owl-next {
  position: absolute;
  top: 46%;
  right: -50px;
  font-size: 30px;
}

@media (max-width: 767px) {

  .owl-prev,
  .owl-next {
    position: static;
    display: inline-block;
  }
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  margin-right: -2px;
}

.mm-list a.mm-subopen:hover::after {
  border-color: #fafbfb !important;
}

.mm-list>li>a:hover {
  color: #fff !important;
  background-color: #161115 !important;
}

.mm-menu em.mm-counter {
  color: rgba(255, 255, 255, 0.66) !important;
}

.mm-vertical em.mm-counter {
  z-index: 999 !important;
}

.mm-list>li.mm-spacer {
  padding-top: 20px;
}

.mm-list>li>a {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

#menuAside i,
#menuAside span {
  font-size: 20px;
  padding-right: 6px;
}

/* Alinhamentos -------------------*/

.text-left-not-xs,
.text-left-not-sm,
.text-left-not-md,
.text-left-not-lg {
  text-align: left;
}

.text-center-not-xs,
.text-center-not-sm,
.text-center-not-md,
.text-center-not-lg {
  text-align: center;
}

.text-right-not-xs,
.text-right-not-sm,
.text-right-not-md,
.text-right-not-lg {
  text-align: right;
}

.text-justify-not-xs,
.text-justify-not-sm,
.text-justify-not-md,
.text-justify-not-lg {
  text-align: justify;
}

@media (max-width: 767px) {

  .text-left-not-xs,
  .text-center-not-xs,
  .text-right-not-xs,
  .text-justify-not-xs {
    text-align: inherit;
  }

  .text-left-xs {
    text-align: left;
  }

  .text-center-xs {
    text-align: center;
  }

  .text-right-xs {
    text-align: right;
  }

  .text-justify-xs {
    text-align: justify;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .text-left-not-sm,
  .text-center-not-sm,
  .text-right-not-sm,
  .text-justify-not-sm {
    text-align: inherit;
  }

  .text-left-sm {
    text-align: left;
  }

  .text-center-sm {
    text-align: center;
  }

  .text-right-sm {
    text-align: right;
  }

  .text-justify-sm {
    text-align: justify;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .text-left-not-md,
  .text-center-not-md,
  .text-right-not-md,
  .text-justify-not-md {
    text-align: inherit;
  }

  .text-left-md {
    text-align: left;
  }

  .text-center-md {
    text-align: center;
  }

  .text-right-md {
    text-align: right;
  }

  .text-justify-md {
    text-align: justify;
  }
}

@media (min-width: 1200px) {

  .text-left-not-lg,
  .text-center-not-lg,
  .text-right-not-lg,
  .text-justify-not-lg {
    text-align: inherit;
  }

  .text-left-lg {
    text-align: left;
  }

  .text-center-lg {
    text-align: center;
  }

  .text-right-lg {
    text-align: right;
  }

  .text-justify-lg {
    text-align: justify;
  }
}

.tp-slider-wrapper {
  list-style: none;
  margin: 0px !important;
  padding: 0px !important;
  overflow: hidden !important;
  height: 452px
}

ul.menuPrincipal li ul li {
  background: #e73727 !important;
}

.bandeiras li img {
  width: calc(1vw + 40px);
  margin-bottom: 5px;
}

#share .jssocials-shares .jssocials-share-link {
  border-radius: 50%;
  padding: 6px;
}

#share .jssocials-share-logo {
  font-size: 2rem;
}

.box-detalhes.-pedido {
  background-color: transparent;
  border: none;
}

.page-thank-you {
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
}

.page-thank-you .title {
  color: #24c084;
}

.page-thank-you .desc {
  color: #777;
}

.tp-bullets {
  display: none;
}

.listagem-instagram {
  
}

@media (max-width: 768px) {
  .listagem-instagram .col {
    padding: 15px;
  }
}

.listagem-instagram .col a {
  display: block;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.listagem-instagram .col a::before {
  padding-bottom: 100%;
  content: " ";
  display: block;
  z-index: 10;
  background-color: #222;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.listagem-instagram .col a:hover::before {
  opacity: 0.4;
}

.listagem-instagram .col img {
  opacity: 0;
  position: absolute;
  z-index: -56789;
  pointer-events: none;
}