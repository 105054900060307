@font-face {
    font-family: 'bebasbold';
    src: url('/assets/fonts/bebasneue_bold.woff2') format('woff2'),
    url('/assets/fonts/bebasneue_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bebasbook';
    src: url('/assets/fonts/bebasneue_book.woff2') format('woff2'),
    url('/assets/fonts/bebasneue_book.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bebasregular';
    src: url('/assets/fonts/bebasneue_regular.woff2') format('woff2'),
    url('/assets/fonts/bebasneue_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'clarendon-regular';
    src: url('/assets/fonts/tt0283m_-webfont.woff2') format('woff2'),
    url('/assets/fonts/tt0283m_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'clarendon-light';
    src: url('/assets/fonts/tt0282m_-webfont.woff2') format('woff2'),
    url('/assets/fonts/tt0282m_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'clarendon-bold';
    src: url('/assets/fonts/tt0284m_-webfont.woff2') format('woff2'),
    url('/assets/fonts/tt0284m_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'roboto-thin';
    src: url('/assets/fonts/Roboto-Thin-webfont.eot');
    src: url('/assets/fonts/Roboto-Thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Roboto-Thin-webfont.woff') format('woff'),
    url('/assets/fonts/Roboto-Thin-webfont.ttf') format('truetype'),
    url('/assets/fonts/Roboto-Thin-webfont.svg#Roboto') format('svg');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-light';
    src: url('/assets/fonts/Roboto-Light-webfont.eot');
    src: url('/assets/fonts/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Roboto-Light-webfont.woff') format('woff'),
    url('/assets/fonts/Roboto-Light-webfont.ttf') format('truetype'),
    url('/assets/fonts/Roboto-Light-webfont.svg#Roboto') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'roboto';
    src: url('/assets/fonts/Roboto-Regular-webfont.eot');
    src: url('/assets/fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Roboto-Regular-webfont.woff') format('woff'),
    url('/assets/fonts/Roboto-Regular-webfont.ttf') format('truetype'),
    url('/assets/fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'roboto-medium';
    src: url('/assets/fonts/Roboto-Medium-webfont.eot');
    src: url('/assets/fonts/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Roboto-Medium-webfont.woff') format('woff'),
    url('/assets/fonts/Roboto-Medium-webfont.ttf') format('truetype'),
    url('/assets/fonts/Roboto-Medium-webfont.svg#robotomedium') format('svg');
    font-weight: 500;
    font-style: normal;

}
@font-face {
    font-family: 'roboto-bold';
    src: url('/assets/fonts/Roboto-Bold-webfont.eot');
    src: url('/assets/fonts/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Roboto-Bold-webfont.woff') format('woff'),
    url('/assets/fonts/Roboto-Bold-webfont.ttf') format('truetype'),
    url('/assets/fonts/Roboto-Bold-webfont.svg#robotobold') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'robotoblack';
    src: url('/assets/fonts/Roboto-Black-webfont.eot');
    src: url('/assets/fonts/Roboto-Black-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Roboto-Black-webfont.woff') format('woff'),
    url('/assets/fonts/Roboto-Black-webfont.ttf') format('truetype'),
    url('/assets/fonts/Roboto-Black-webfont.svg#robotoblack') format('svg');
    font-weight: 900;
    font-style: normal;
}
